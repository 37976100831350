import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { PortalProcess } from '@app/shared/business-continuity/portal-process.model';
import { PortalFunctionalArea } from '@app/shared/business-continuity/portal-functional-area.model';
import * as _ from 'lodash';
import { OverlayPanel } from 'primeng/overlaypanel';
import { Vendor } from '@app/shared/business-continuity/vendor.model';
import { Technology } from '@app/shared/business-continuity/technology.model';

@Component({
	selector: 'app-bc-resources-assigned-overlay-panel',
	templateUrl: './bc-resources-assigned-overlay-panel.component.html',
	styleUrls: ['./bc-resources-assigned-overlay-panel.component.scss']
})
export class BcResourcesAssignedOverlayPanelComponent implements OnInit {
	@Input() assignedPortalProcesses: Array<PortalProcess> = new Array<PortalProcess>();
	@Input() assignedTechnologies: Array<Technology> = new Array<Technology>();
	@Input() displayList: boolean = false;
	public departments: Array<PortalFunctionalArea> = []; // technically array of PortalFunctionalArea
	@ViewChild('op') overlayPanel: OverlayPanel;
	@Input() displayCount: number = null;
	abbreviatedList: Array<PortalProcess> = [];
	totalAssignedCount: number = 0;

	constructor() {
	}

	ngOnInit(): void {
		// gather up the departments and organize the assigned portal processes by department
		// the .filter at the end makes sure only pfa exists is added. .map returns null.
		if (this.assignedPortalProcesses) {
			this.departments = _.uniqBy(this.assignedPortalProcesses.map((aPP: PortalProcess) => {
				if (aPP?.portalFunctionalArea) {
					return aPP.portalFunctionalArea;
				}
			}), 'portalFunctionalAreaId').filter((pfa: PortalFunctionalArea) => pfa);

			this.departments.forEach((pfa: PortalFunctionalArea) => {
				pfa.portalProcesses = this.assignedPortalProcesses.filter((aPP: PortalProcess) => aPP.portalFunctionalArea?.portalFunctionalAreaId === pfa.portalFunctionalAreaId);
			});
		}
		this.abbreviatedList = this.getProcesses() ? this.getProcesses().slice(0, this.displayCount) : [];
		if (this.assignedPortalProcesses || this.assignedTechnologies) {
			this.totalAssignedCount = this.assignedPortalProcesses.length + this.assignedTechnologies.length;
		}
	}

	show(event: any) {
		if (this.totalAssignedCount === 0) {
			return;
		}

		if (this.overlayPanel.target == null) {
			this.overlayPanel.show(event);
		}
	}

	hide() {
		if (this.overlayPanel.target) {
			this.overlayPanel.hide();
		}
	}

	getProcesses() {
		return _.flatten(this.departments.map((de) => {
			return de.portalProcesses;
		}));
	}
}
