<button class="btn btn-link"
        *ngIf="!displayList;else list"
        (mouseenter)="show($event)"
        (mouseleave)="hide()">
    {{ totalAssignedCount }}
</button>
<ng-template #list>
    <button  id="showImpactChartButton" class="btn btn-link p-0 m-0 text-start" (click)="op.toggle($event);">
        <div *ngFor="let process of abbreviatedList; last as isLast">
            {{process.portalProcessName}}
            <span *ngIf="isLast && abbreviatedList.length < getProcesses().length">...</span>
        </div>
    </button>
</ng-template>


<p-overlayPanel #op [showCloseIcon]="false">
    <ng-template pTemplate>
        <div class="mb-3" *ngFor="let department of departments">
            <div class="fw-bold">{{department.portalFunctionalAreaName}}</div>
            <ul class="pb-0 m-0">
                <li *ngFor="let pp of department.portalProcesses">
                    {{pp.portalProcessName}}
                </li>
            </ul>
        </div>
        <div class="mb-3" *ngIf="assignedTechnologies?.length > 0">
            <div class="fw-bold">Technologies</div>
            <ul class="pb-0 m-0">
                <li *ngFor="let t of assignedTechnologies">
                    {{t.technologyName}}
                </li>
            </ul>
        </div>
    </ng-template>
</p-overlayPanel>
