import { Component, OnInit } from '@angular/core';
import { BusinessContinuityService } from '@app/providers/business-continuity.service';
import { Utility } from '@app/providers/utility';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { Technology } from '@app/shared/business-continuity/technology.model';
import {
	bcTechnologyStatusTypes,
	dateFormat, dialogueWidths,
	entityTypeIds, permissionFeatures,
	serviceErrorCodes,
	timeDurationUnit
} from '@app/app.constants';
import { BcTechnologyModalComponent } from '@app/components/business-continuity/modals/bc-technology/bc-technology-modal.component';
import { ModalReturnType } from '@app/app.enum';
import { ConfirmationModalComponent } from '@app/components/modals/confirmation-modal/confirmation-modal.component';
import { BcBulkUploadTechnologyModalComponent } from '../../modals/bc-bulk-upload-technology-modal/bc-bulk-upload-technology-modal.component';
import { SpinnerService } from '@app/providers/spinner.service';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { TechnologyTypesModalComponent } from '@app/components/business-continuity/bc-technologies/technology-types-modal/technology-types-modal.component';
import { PermissionService } from '@app/providers/permission.service';

@Component({
	selector: 'app-bc-technologies-list',
	templateUrl: './bc-technologies-list.component.html',
	styleUrls: ['./bc-technologies-list.component.scss']
})
export class BcTechnologiesListComponent implements OnInit {
	technologies: Array<Technology> = new Array<Technology>();
	public loading: boolean = false;
	serviceErrorCodes = serviceErrorCodes;
	entityTypeIds = entityTypeIds;
	public dateformat = dateFormat;
	canManage: boolean = false;

	constructor(private bcService: BusinessContinuityService,
		private utility: Utility,
		private toastrService: ToastrService,
		public translateService: TranslateService,
		private spinnerService: SpinnerService,
		private dialogService: DialogService,
		private permissionService: PermissionService) {
		this.canManage = this.bcService.isBusinessContinuityManager
		|| this.permissionService.hasPermission(permissionFeatures.manage.key, permissionFeatures.manage.actions.manage);
	}

	ngOnInit(): void {
		this.getPortalTechnologies();
	}

	getPortalTechnologies() {
		this.spinnerService.start();
		this.loading = true;

		this.bcService.getTechnologies(true)
			.then((response: any) => {
				this.technologies = response.technologies.map((t) => {
					if (t.createdByName === 'unassigned unassigned') {
						t.createdByName = this.translateService.instant('common.system');
					}
					t.rpoString = this.getTechnologyRPOString(t.rpoTime, t.rpoTimeDurationUnitId);
					return t;
				});
			})
			.finally(() => {
				this.spinnerService.stop();
				this.loading = false;
			});
	}

	getTechnologyRPOString(rpoTime: number, rpoTimeDurationUnitId: string): string {
		let rpoString = '';
		let rpoTypeString = '';
		if (rpoTime) {
			switch (rpoTimeDurationUnitId) {
				case timeDurationUnit.second:
					rpoTypeString = 'businessContinuity.continuityStrategy.technologyModal.second';
					break;
				case timeDurationUnit.minute:
					rpoTypeString = 'businessContinuity.continuityStrategy.technologyModal.minute';
					break;
				case timeDurationUnit.hour:
					rpoTypeString = 'businessContinuity.continuityStrategy.technologyModal.hour';
					break;
				case timeDurationUnit.day:
					rpoTypeString = 'businessContinuity.continuityStrategy.technologyModal.day';
					break;
			}

			if (rpoTime > 1) {
				rpoTypeString += 's';
			}
			rpoString = `${rpoTime} ${this.translateService.instant(rpoTypeString)}`;
		}
		return rpoString;
	}

	addTechnology() {
		this.openPortalTechnologyModal();
	}

	openPortalTechnologyModal(technology?: Technology) {
		const technologyEdit = {
			technologyId: technology?.technologyId,
			technologyName: technology?.technologyName || '',
			technologyNameAsBase64: technology?.technologyNameAsBase64 || '',
			portalId: technology?.portalId || this.utility.getCurrentPortalId(),
			technologyStatusTypeId: technology?.technologyStatusTypeId || bcTechnologyStatusTypes.active,
			rpoTime: technology?.rpoTime,
			rpoTimeDurationUnitId: technology?.rpoTimeDurationUnitId,
			technologyType: technology?.technologyType,
			tier: technology?.tier
		} as Technology;

		const ngbModalRef: DynamicDialogRef = this.dialogService.open(BcTechnologyModalComponent, {
			width: dialogueWidths.md,
			data: {
				technology: technologyEdit
			}
		});

		ngbModalRef.onClose.subscribe((response: ModalReturnType) => {
			if (response === ModalReturnType.save) {
				this.bcService.saveTechnology(technologyEdit).then((result: any) => {
					this.toastrService.success(technologyEdit.technologyName + ' ' + this.translateService.instant('common.saved'));
					this.getPortalTechnologies();
				}).catch((error: any) => {
					switch (error.errorCode) {
						case this.serviceErrorCodes.bcDuplicateTechnologyName:
							this.toastrService.error(this.translateService.instant('businessContinuity.continuityStrategy.technologyModal.duplicateTechnologyNameError'));
							break;
						default:
							this.toastrService.error(this.translateService.instant('errorMessages.genericError'));
					}
				});
			}
		});
	}

	deleteTechnology(technology: Technology) {
		// validation
		if (technology.assignedPlanTypes?.length) {
			this.toastrService.error(this.translateService.instant('businessContinuity.continuityStrategy.technologyModal.technologyInUseError'));

			return;
		}

		const modalRef: DynamicDialogRef = this.dialogService.open(ConfirmationModalComponent, {
			header: this.translateService.instant('businessContinuity.continuityStrategy.technologyModal.deleteTechnologyHeader'),
			data: {
				body: this.translateService.instant('businessContinuity.continuityStrategy.technologyModal.deleteTechnologyMessage'),
				confirmButtonText: this.translateService.instant('common.remove'),
				confirmButtonClass: 'btn-danger'
			}
		});

		modalRef.onClose.subscribe((res) => {
			if (res) {
				technology.technologyStatusTypeId = bcTechnologyStatusTypes.deleted;

				this.bcService.saveTechnology(technology)
					.then((result: any) => {
						this.toastrService.success(technology.technologyName + ' ' + this.translateService.instant('common.removed'));
						this.getPortalTechnologies();
					})
					.catch((error: any) => {
						switch (error.errorCode) {
							case this.serviceErrorCodes.bcTechnologyDeleteInUse:
								this.toastrService.error(this.translateService.instant('businessContinuity.continuityStrategy.technologyModal.technologyInUseError'));
								break;
							default:
								this.toastrService.error(this.translateService.instant('errorMessages.genericError'));
						}
					});
			}
		});
	}

	bulkUpload() {
		this.dialogService.open(BcBulkUploadTechnologyModalComponent, {
			width: dialogueWidths.lg,
			data: {
				entityTypeId: this.entityTypeIds.group,
				name: this.translateService.instant('businessContinuity.continuityStrategy.technologyModal.technology'),
				pluralName: this.translateService.instant('businessContinuity.continuityStrategy.technologyModal.technologies')
			}
		});
	}

	openManageTypes() {
		this.dialogService.open(TechnologyTypesModalComponent, {
			header: this.translateService.instant('businessContinuity.continuityStrategy.technologyModal.technologyTypes'),
			width: dialogueWidths.md
		});
	}
}
