<div class="row" *ngIf="!isEditing;else editing">
    <div class="col-xxl-4 col-lg-6 col-sm-12 customCol mb-4"
         *ngIf="canManageDepartment">
        <div class="bcCard newBcCard customCard newTeamButton p-0 border border-1 border-primary w-100" (click)="create()">
            <button class="btn btn-link newBcButton btn-block" id="createOOSButton"
                    type="button">
                <i class="fa fa-plus"></i>
                <div class="d-block fw-bold">{{'businessContinuity.teams.addTeam' | translate}}</div>
            </button>
        </div>
    </div>
    <ng-container *ngFor="let team of teams">
        <div class="col-xxl-4 col-lg-6 col-sm-12 customCol mb-4">
            <app-base-card [options]="menuOptions.get(team.portalTeamId)"
                       [header]="team.portalTeamName"
                       (headerOnClickEvent)="edit(team.portalTeamId)"
                       [lastUpdated]="team.dateTimeLastModified ? team.dateTimeLastModified : team.dateTimeCreated"
                       [lastUpdateBy]="team.lastModifiedByName ? team.lastModifiedByName : team.createdByName"
                       [height]="250"
                       [headerIsClickable]="canManageDepartment && !team.isLinked"
                       [mark]="team.isLinked?{icon:'fa fa-link', label:'businessContinuity.teams.linked' | translate}:null"
                       class="bcCard">
                <ng-template #content>
                    <div style="height: 70%; margin-bottom: 40px;">
                        <p *ngIf="team.portalTeamDescription; else noDescription" class="description">{{team.portalTeamDescription}}</p>
                        <ng-template #noDescription>
                            <p class="description">{{'businessContinuity.teams.noDescription' | translate}}</p>
                        </ng-template>
                        <div class="justify-content-between my-2">
                            <div class="mb-2 fw-semibold chipContainer">
                                <ng-container *ngIf="!isIndustryDefault">
                                    <h6 class="mb-2">{{'common.members' | translate}}</h6>

                                    <ng-container *ngIf="team.positions.length > 0  ; else noPositions">
                                        <ng-container *ngIf="canManageDepartment">
                                            <button
                                                (click)="edit(team.portalTeamId)"
                                                *ngFor="let position of team.positions | slice:0:2"
                                                class="btn assignTeamLeadButton m-0 me-2"
                                                [ngClass]="{ 'isLinked': team.isLinked}"
                                                type="button">
                                                <div class="primaryCardInfo">
                                                    {{getChipInfo(position)}}
                                                </div>
                                            </button>
                                        </ng-container>
                                        <ng-container *ngIf="!canManageDepartment">
                                            <button
                                                *ngFor="let position of team.positions | slice:0:2"
                                                class="btn assignTeamLeadButton m-0 me-2"
                                                [ngClass]="{ 'isLinked': team.isLinked}"
                                                type="button">
                                                <div class="primaryCardInfo">
                                                    {{getChipInfo(position)}}
                                                </div>
                                            </button>
                                        </ng-container>
                                    </ng-container>

                                    <ng-template #noPositions>
                                        <button (click)="edit(team.portalTeamId)"
                                                class="btn assignTeamLeadButton hasNoDelegation m-0 me-2"
                                                type="button"
                                                [disabled]="!canManageDepartment">
                                            <div class="primaryCardInfo">
                                                <i class="fa-light fa-user-plus me-1" *ngIf="canManageDepartment"></i>{{(canManageDepartment ? 'businessContinuity.teams.assignMembers' : 'common.noAssignee') | translate}}</div>
                                        </button>
                                    </ng-template>
                                    <button (click)="edit(team.portalTeamId)"
                                            *ngIf="team.positions.length > 2"
                                            class="btn assignTeamLeadButton m-0">
                                        <div class="primaryCardInfo">
                                            {{'+' + (team.positions.length - 2)}}
                                        </div>
                                    </button>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </app-base-card>
        </div>
    </ng-container>
</div>

<app-spinner [name]="'list-spinner'"></app-spinner>
<p-confirmDialog [acceptIcon]="null"
                 [rejectIcon]="null"
                 acceptButtonStyleClass="btn btn-primary"
                 rejectButtonStyleClass="btn btn-outline-secondary"></p-confirmDialog>

<ng-template #editing>
    <app-bc-team
        (back)="childResponse($event)"
        [teamId]="teamId"
        [industryId]="industryId"
        [isIndustryDefault]="isIndustryDefault"
        [portalPlanTypeId]="portalPlanTypeId"
        [portalFunctionalAreaId]="portalFunctionalAreaId"
        [isBusinessContinuityManager]="isBusinessContinuityManager"
        [canManageDepartment]="canManageDepartment"
    ></app-bc-team>
</ng-template>
