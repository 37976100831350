export class BCIssueComment {
	issueCommentId: string;
	issueId: string;
	comment: string;
	commentAsBase64: string = '';
	dateTimeCreated: Date;
	createdById: string;
	createdByName: string;
	createdByNameAsBase64: string = '';
	dateTimeLastModified: Date;
	lastModifiedById: string;
	lastModifiedByName: string;
	lastModifiedByNameAsBase64: string = '';
}
