    <div class="row mt-5" *ngIf="!showDetails">
        <div *ngIf="canManageDepartment" class="col-xxl-4 col-lg-6 col-sm-12 customCol mb-4 text-center">
            <div class="bcCard newBcCard customCard p-0 border border-1 border-primary w-100" (click)="navigateToCreateOOS()">
                <button class="btn btn-link newBcButton btn-block" id="createOOSButton"
                        type="button">
                    <i class="fa fa-plus"></i>
                    <div class="d-block fw-bold">{{'businessContinuity.ordersOfSuccession.newSuccession' | translate}}</div>
                </button>
            </div>
        </div>

        <ng-container *ngFor="let order of ordersOfSuccessions">
            <div class="col-xxl-4 col-lg-6 col-sm-12 customCol mb-4">
                <app-base-card [options]="options[order.portalOrderOfSuccessionId]"
                           [header]="order.positionTitle"
                           (headerOnClickEvent)="navigateToEditOOS(order.portalOrderOfSuccessionId)"
                           [lastUpdated]="order.dateTimeLastModified"
                           [height]="250"
                           [headerIsClickable]="canManageDepartment"
                           [mark]="false"
                           class="bcCard">
                    <ng-template #content>
                        <div style="height: 70%">
                            <div class="justify-content-between my-2">
                                <ng-container *ngIf="!isIndustryDefault">
                                    <div class="mb-2 fw-semibold">
                                        <h6 class="mb-2">{{'common.primary' | translate}}</h6>
                                        <ng-container *ngIf="canManageDepartment">
                                            <button (click)="navigateToEditOOS(order.portalOrderOfSuccessionId)"
                                                    [ngClass]="{'hasNoDelegation': !order.primaryPerson}"
                                                    class="btn assignTeamLeadButton m-0 me-2"
                                                    id="{{order.portalOrderOfSuccessionId + '-actingAgents'}}"
                                                    type="button">
                                                <ng-container *ngIf="!order.primaryPerson; else primaryChip">
                                                    <div class="primaryCardInfo">
                                                        <i class="fa-light fa-user-plus me-1"></i> {{'businessContinuity.ordersOfSuccession.AddPrimary' | translate}}
                                                    </div>
                                                </ng-container>
                                                <ng-template #primaryChip>
                                                    <div class="primaryCardInfo">
                                                                <span class="my-2">
                                                                    {{getChipInfo(order.primaryPerson, true)}}
                                                                </span>
                                                    </div>
                                                </ng-template>
                                            </button>
                                        </ng-container>
                                        <ng-container *ngIf="!canManageDepartment">
                                            <div class="assignTeamLeadButton m-0">
                                                <div *ngIf="!order.primaryPerson" class="primaryCardInfo">
                                                    {{'businessContinuity.ordersOfSuccession.NoPrimary' | translate}}
                                                </div>
                                                <div *ngIf="order.primaryPerson" class="primaryCardInfo">
                                                     <span class="my-2">
                                                                    {{getChipInfo(order.primaryPerson, true)}}
                                                                </span>
                                                </div>
                                            </div>
                                        </ng-container>
                                    </div>
                                    <div class="mb-2 fw-semibold">
                                        <h6 class="mb-2">{{"common.alternates" | translate}}</h6>
                                        <ng-container *ngIf="canManageDepartment">
                                            <button (click)="navigateToEditOOS(order.portalOrderOfSuccessionId)"
                                                    [ngClass]="{'hasNoDelegation': order.portalSuccessionPersonList.length === 0}"
                                                    class="btn assignTeamLeadButton m-0 me-2"
                                                    id="{{order.portalOrderOfSuccessionId + '-delegatedAgents'}}"
                                                    type="button">
                                                <ng-container *ngIf="order.portalSuccessionPersonList.length === 0; else alternateChip">
                                                    <div class="primaryCardInfo">
                                                    <span class="my-2">
                                                       <i class="fa-light fa-user-plus me-1"></i> {{'businessContinuity.ordersOfSuccession.AddAlternate' | translate}}
                                                    </span>
                                                    </div>
                                                </ng-container>
                                                <ng-template #alternateChip>
                                                    <div class="primaryCardInfo">
                                                        {{getChipInfo(order.portalSuccessionPersonList[0])}}
                                                    </div>
                                                </ng-template>
                                            </button>
                                            <button (click)="navigateToEditOOS(order.portalOrderOfSuccessionId)"
                                                    *ngIf="order.portalSuccessionPersonList.length > 1"
                                                    class="btn assignTeamLeadButton m-0"
                                                    id="{{order.portalOrderOfSuccessionId + '-moreActingAgents'}}">
                                                <div class="primaryCardInfo">
                                                    <span class="my-2">+{{order.portalSuccessionPersonList.length - 1}}</span>
                                                </div>
                                            </button>
                                        </ng-container>
                                        <ng-container *ngIf="!canManageDepartment">
                                            <div class="assignTeamLeadButton m-0 me-2">
                                                <div *ngIf="order.portalSuccessionPersonList.length === 0" class="primaryCardInfo">
                                                    {{'businessContinuity.ordersOfSuccession.NoAlternate' | translate}}
                                                </div>
                                                <div *ngIf="order.portalSuccessionPersonList.length" class="primaryCardInfo">
                                                    {{getChipInfo(order.portalSuccessionPersonList[0])}}
                                                </div>
                                            </div>
                                            <button *ngIf="order.portalSuccessionPersonList.length > 1"
                                                    class="btn assignTeamLeadButton m-0"
                                                    id="{{order.portalOrderOfSuccessionId + '-moreActingAgents'}}">
                                                <div class="primaryCardInfo">
                                                    <span class="my-2">+{{order.portalSuccessionPersonList.length - 1}}</span>
                                                </div>
                                            </button>
                                        </ng-container>
                                    </div>

                                </ng-container>
                            </div>

                        </div>
                    </ng-template>
                </app-base-card>
            </div>
        </ng-container>
    </div>

<ng-container *ngIf="showDetails">
    <app-bc-orders-of-succession
        [portalFunctionalAreaId]="portalFunctionalAreaId"
        [siteId]="siteId"
        [functionalAreaId]="functionalAreaId"
        [portalPlanTypeId]="portalPlanTypeId"
        [orderId]="currentOOSId"
        (hideDetails)="handleHideDetails()"
        (oosUpdated)="getUpdatedOOSList()"
        [canManageDepartment]="canManageDepartment"
        [isIndustryDefault]="isIndustryDefault"
        [showTitle]="true"
    ></app-bc-orders-of-succession>
</ng-container>
