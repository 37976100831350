import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BreadCrumb } from '@app/shared/breadcrumb.model';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService } from 'primeng/api';
import { ActivatedRoute, Router } from '@angular/router';
import { bcRevisionItemTypes, dialogueWidths } from '@app/app.constants';
import { DialogService } from 'primeng/dynamicdialog';
import { BCAddPositionModalComponent } from '@app/components/business-continuity/bc-teams/modals/bc-add-position-modal/bc-add-position-modal.component';
import { SpinnerService } from '@app/providers/spinner.service';
import { ToastrService } from 'ngx-toastr';
import { PortalTeam, PortalTeamPosition } from '@app/shared/business-continuity/portal-team.model';
import { BusinessContinuityService } from '@app/providers/business-continuity.service';
import { BaseBusinessContinuityComponent } from '@app/components/base-form/base-business-continuity.component';
import * as _ from 'lodash';
import { ModelBCTeam, ModelBCTeamPosition } from '@app/shared/business-continuity/bc-team.model';
import { FormService } from '@app/providers/form/form.service';

@Component({
	selector: 'app-bc-team',
	templateUrl: './bc-team.component.html',
	styleUrls: ['./bc-team.component.scss']
})
export class BcTeamComponent implements OnInit {
	@Input() teamId: string;
	@Input() portalFunctionalAreaId: string;
	@Input() isIndustryDefault: boolean;
	@Input() isBusinessContinuityManager: boolean;
	@Input() canManageDepartment: boolean;
	@Input() portalPlanTypeId: string;
	@Input()industryId: string;
	@Output() back: EventEmitter<boolean> = new EventEmitter<boolean>();

	team: PortalTeam;
	viewOnly: boolean = false;
	backRoute: string;
	breadCrumbs: Array<BreadCrumb>;
	functionalAreaId: string;
	departmentId: string;

	String = String;
	constructor(public route: ActivatedRoute,
		public bcService: BusinessContinuityService,
		private spinnerService: SpinnerService,
		private router: Router,
		public translateService: TranslateService,
		private confirmationService: ConfirmationService,
		public toastrService: ToastrService,
		public dialogService: DialogService,
		public formService: FormService,
		public elementRef: ElementRef) {
		this.teamId = this.route.snapshot.params.teamId;
		// this.isIndustryDefault = route.snapshot.data?.isIndustryDefault === true;
		this.functionalAreaId = this.route.snapshot.params.functionalAreaId;
		// this.industryId = this.route.snapshot.params.industryId;
		this.departmentId = this.route.snapshot.params.functionalAreaId;

		if (!this.route.snapshot.params.teamId) {
			this.team = new PortalTeam();
			this.team.positions = [];
		}

		if (!this.isIndustryDefault) {
			this.breadCrumbs = [
				{
					name: this.translateService.instant('businessContinuity.teams.title'),
					state: this.backRoute
				},
				{
					name: this.translateService.instant('businessContinuity.teams.TeamDetailsTitle'),
					state: ''
				}
			];
		}
	}

	ngOnInit(): void {
		if (this.teamId) {
			this.getTeam();
		}
		else {
			if (!this.isIndustryDefault) {
				this.team.portalFunctionalAreaId = this.portalFunctionalAreaId;
				this.team.portalPlanTypeId = this.portalPlanTypeId;
			}
			else {
				this.team.portalFunctionalAreaId = this.functionalAreaId;
				this.getIndustryInfo();
			}
		}
	}

	getTeam() {
		this.spinnerService.start('team-spinner');
		this.team = new PortalTeam();
		this.team.positions = [];
		let getTeam;
		if (this.isIndustryDefault) {
			getTeam = this.bcService.getDefaultTeam(this.teamId, this.functionalAreaId);
		}
		else {
			getTeam = this.bcService.getPortalTeam(this.teamId, this.portalPlanTypeId, this.portalFunctionalAreaId);
		}
		getTeam.then((response: any) => {
			if (this.isIndustryDefault) {
				this.team = this.mapToPortalTeam(response.bcTeam);
				this.getIndustryInfo();
			}
			else {
				this.team = response.portalTeam;
				if (this.team.isLinked || !this.canManageDepartment) {
					this.viewOnly = true;
				}
			}
		}).finally(() => this.spinnerService.stop('team-spinner'));
	}

	createPosition() {
		const modalRef = this.dialogService.open(BCAddPositionModalComponent, {
			data: {
				baseResourcePath: 'businessContinuity.teams.createPosition',
				hasMultipleSelections: false,
				singleSelectionPreview: true,
				isIndustryDefault: this.isIndustryDefault
			},
			header: this.isIndustryDefault
				? this.translateService.instant('businessContinuity.teams.addPosition')
				: this.translateService.instant('businessContinuity.teams.addMember'),
			width: dialogueWidths.lg
		});
		modalRef.onClose.subscribe((position: any) => {
			if (position) {
				this.team.positions = [...this.team.positions, position];
			}
		});
	}

	editPosition(positionToEdit: any) {
		const modalRef = this.dialogService.open(BCAddPositionModalComponent, {
			data: {
				baseResourcePath: 'businessContinuity.teams.createPosition',
				position: _.cloneDeep(positionToEdit),
				hasMultipleSelections: false,
				singleSelectionPreview: true,
				isIndustryDefault: this.isIndustryDefault
			},
			header: this.isIndustryDefault
				? this.translateService.instant('businessContinuity.teams.addPosition')
				: this.translateService.instant('businessContinuity.teams.addMember'),
			width: dialogueWidths.lg
		});
		modalRef.onClose.subscribe((position: any) => {
			if (position) {
				const index = this.team.positions.findIndex(pos => pos === positionToEdit);
				this.team.positions[index] = position;
				this.team.positions = [...this.team.positions];
			}
		});
	}

	deletePosition(position: PortalTeamPosition) {
		this.confirmationService.confirm({
			message: this.translateService.instant('businessContinuity.teams.createPosition.confirmDeleteVerbiage'),
			header: this.translateService.instant('businessContinuity.teams.confirmDeleteHeader', { name: position.position }),
			accept: () => {
				this.spinnerService.start('team-spinner');
				setTimeout(() => {
					this.team.positions = this.team.positions.filter(pos => pos !== position);
					this.spinnerService.stop('team-spinner');
					this.toastrService.success(this.translateService.instant('businessContinuity.teams.createPosition.successOnDeletePosition'));
				}, 2000);
			},
			acceptButtonStyleClass: 'btn btn-primary',
			rejectButtonStyleClass: 'btn btn-secondary',
			acceptLabel: this.translateService.instant('common.ok'),
			rejectLabel: this.translateService.instant('common.cancel'),
			rejectIcon: 'null',
			acceptIcon: 'null'
		});
	}

	createTeam() {
		if (this.formService.isInvalid(this.elementRef)) return;
		if (String.isNullOrEmpty(this.team.portalTeamName)) {
			return this.toastrService.error(this.translateService.instant('errorMessages.msgValidateRequired'));
		}
		this.spinnerService.start('team-spinner');
		const saveMethod = !this.isIndustryDefault
			? this.bcService.savePortalTeam(this.team)
			: this.bcService.saveDefaultTeam(this.mapToBCTeam(this.team));
		saveMethod.then(() => {
			this.back.emit(true);
			this.toastrService.success(this.translateService.instant('businessContinuity.teams.successOnSave'));
		}).catch(() => {
			this.toastrService.error(this.translateService.instant('businessContinuity.teams.failedToSave'));
		}).finally(() => {
			this.spinnerService.stop('team-spinner');
		});
	}

	mapToBCTeam(team: PortalTeam) {
		const functionalTeam = new ModelBCTeam();
		functionalTeam.positions = [];
		for (const [key, value] of Object.entries(team)) {
			if (key.includes('portal')) {
				// cuts the portal of the property and make the first letter lower case
				functionalTeam[key.slice(6).charAt(0).toLowerCase() + key.slice(7)] = value;
			}
			else if (key === 'positions') {
				for (const [index, positionValue] of Object.entries(value)) {
					const position = new ModelBCTeamPosition();
					for (const [objKey, objValue] of Object.entries(positionValue)) {
						if (objKey.includes('portal')) {
							position[objKey.slice(6).charAt(0).toLowerCase() + objKey.slice(7)] = objValue;
						}
						else {
							position[objKey] = objValue;
						}
					}
					functionalTeam[key][index] = position;
				}
			}
			else {
				functionalTeam[key] = value;
			}
		}
		return functionalTeam;
	}

	mapToPortalTeam(team: ModelBCTeam) {
		const portalTeam = new PortalTeam();
		portalTeam.positions = [];
		for (const [key, value] of Object.entries(team)) {
			if (key.includes('team') && key !== 'teamStatusTypeId') {
				// cuts the portal of the property and make the first letter upper case
				portalTeam['portal' + key.charAt(0).toUpperCase() + key.slice((1))] = value;
			}
			else if (key === 'positions') {
				for (const [index, positionValue] of Object.entries(value)) {
					const position = new PortalTeamPosition();
					for (const [objKey, objValue] of Object.entries(positionValue)) {
						if (objKey.includes('team')) {
							position['portal' + objKey.charAt(0).toUpperCase() + objKey.slice((1))] = objValue;
						}
						else {
							position[objKey] = objValue;
						}
					}
					portalTeam[key][index] = position;
				}
			}
			else {
				portalTeam[key] = value;
			}
		}

		return portalTeam;
	}

	cancel() {
		this.back.emit(false);
	}

	getIndustryInfo() {
		this.spinnerService.start('team-spinner');
		const promises = [this.isIndustryDefault && this.bcService.getIndustry(this.industryId),
			this.isIndustryDefault && this.bcService.getFunctionalArea(this.departmentId)].filter(p => p);
		Promise.all(promises).then(([industry, functionalArea]: any) => {
			this.breadCrumbs = [
				{
					name: this.translateService.instant('layout.admin'),
					state: ''
				},
				{
					name: this.translateService.instant('businessContinuity.admin.template.industries'),
					state: '/business-continuity/admin/industries'
				},
				{
					name: industry.industry.industryName,
					state: `/business-continuity/admin/industry/${industry.industry.industryId}`
				},
				{
					name: functionalArea.functionalArea.functionalAreaName,
					state: ''
				},
				{
					name: this.translateService.instant('businessContinuity.teams.title'),
					state: `/business-continuity/admin/industry/${industry.industry.industryId}/department/${functionalArea.functionalArea.functionalAreaId}/teams`
				},
				{
					name: this.teamId ? this.team.portalTeamName : this.translateService.instant('businessContinuity.teams.TeamDetailsTitle'),
					state: ''
				}
			];
		}).finally(() => this.spinnerService.stop('team-spinner'));
	}
}
