<div [style]="getCustomStyles()" class="users d-flex flex-wrap ps-2">
    <ng-container *ngFor="let a of assignees; let i = index">
        <div class="user-wrapper p-0 mb-3 text-start me-2" id="{{a.userName}}">
            <div class="user d-flex border border-1 me-1 justify-content-center align-items-center rounded">
                <ng-container *ngIf="!viewOnly">
                    <button (click)="menu.toggle($event)" class="dropdownToggle bg-transparent border-0  d-inline-block" icon="fa-solid fa-ellipsis-vertical" pButton type="button"></button>
                    <p-menu #menu [model]="getMenu(a)" [popup]="true"></p-menu>
                </ng-container>
                <div class="d-block">
                    <div class="profileImage mb-3 d-flex  justify-content-center">
                        <img
                            [ngClass]="{'deleted': a.userStateId === userStateTypes.Deleted || a.userStateId === userStateTypes.Inactive}"
                            [src]="a.profileURL || defaultImageAddress.profile | trusted"/>
                        <div *ngIf="a.userStateId === userStateTypes.Deleted" class="watermark text-lowercase">
                            ({{'common.deleted' | translate}})
                        </div>
                        <div *ngIf="a.userStateId === userStateTypes.Inactive" class="watermark text-lowercase">
                            ({{'common.inactive' | translate}})
                        </div>
                    </div>
                    <div class="name text-muted justify-content-center row">
                        <div [ngClass]="{'deleted': a.userStateId === userStateTypes.Deleted || a.userStateId === userStateTypes.Inactive}" class="name text-truncate"
                             id="{{a.firstName + a.lastName}}">
                            {{a.firstName}} {{a.lastName}}
                        </div>
                        <div class="my-1 text-muted text-truncate d-flex  justify-content-center"
                             pTooltip="{{a.title}}">{{a.title || specialCharacters.doubleDash}}</div>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>

    <div (click)="openAssigneeModal()" *ngIf="!viewOnly" class="btn btn-link  p-0 border border-1 d-flex align-items-center justify-content-center"
         id="openAssigneeModalDiv">
        <div class="d-block">
            <i class="fas fa-plus"></i>
            <div class="fw-bold">
                {{addText}}
            </div>
        </div>
    </div>
</div>
