import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { User } from '@app/shared/user.model';
import { UserService } from '@app/providers/user.service';
import { SpinnerService } from '@app/providers/spinner.service';
import { BcSelectUserModalComponent } from '@app/components/business-continuity/modals/bc-select-user/bc-select-user-modal.component';
import { defaultImageAddress, dialogueWidths, specialCharacters, userStateTypes } from '@app/app.constants';
import * as _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { PortalFunctionalArea } from '@app/shared/business-continuity/portal-functional-area.model';
import { BusinessContinuityService } from '@app/providers/business-continuity.service';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
	selector: 'app-authority-delegations-assignees',
	templateUrl: './bc-authority-delegations-assignees.component.html',
	styleUrls: ['./bc-authority-delegations-assignees.component.scss']
})
export class BcAuthorityDelegationsAssigneesComponent {
	@Input() assignees: Array<User>;
	@Input() customStyles: string[];
	@Input() descriptionResource: string = 'businessContinuity.continuityStrategy.responsibleEmployeesDesc';
	@Input() baseResourcePath: string;
	@Input() viewOnly: boolean = false;
	@Input() forMessage: boolean = false;
	@Input() addText: string;
	@Output() addUserEvent: EventEmitter<Array<User>> = new EventEmitter<Array<User>>();
	@Output() removeUserEvent: EventEmitter<Array<User>> = new EventEmitter<Array<User>>();
	@Input() portalFunctionalArea: PortalFunctionalArea;
	@Input() filterOutUsers: string[];
	public defaultImageAddress = defaultImageAddress;
	public userStateTypes = userStateTypes;
	public specialCharacters = specialCharacters;

	constructor(private userService: UserService,
		private spinner: SpinnerService,
		public translateService: TranslateService,
		public bcService: BusinessContinuityService,
		private dialogService: DialogService) {
	}

	openAssigneeModal(user?: User) {
		const ngbModalRef: DynamicDialogRef = this.dialogService.open(BcSelectUserModalComponent, {
			width: dialogueWidths.xlg,
			styleClass: 'bc-select-user-modal',
			data: {
				currentUsers: _.cloneDeep(this.assignees),
				baseResourcePath: 'businessContinuity.authorityDelegation.searchUser',
				includesMessageOnly: false,
				hasMultipleSelections: true,
				forMessage: this.forMessage,
				filterOutIds: this.filterOutUsers,
				filterUsersListById: true
			}
		});

		ngbModalRef.onClose.subscribe((incomingUsers: Array<User>) => {
			if (incomingUsers) {
				this.addUsers(incomingUsers);
			}
		});
	}

	addUsers(users: Array<User>) {
		const promises = [];

		this.spinner.start();
		users.forEach((u) => {
			promises.push(this.userService.getUserProfile(u.userId).then((res: any) => {
				u.profileURL = !String.isNullOrEmpty(res.profileFull.profile.profileURL) ? res.profileFull.profile.profileURL : defaultImageAddress.profile;
			}));
		});

		Promise.all(promises).then((_) => {
			this.addUserEvent.emit(users);
		}).catch((_) => {
		}).then((_) => {
			this.spinner.stop();
		});
	}

	getCustomStyles() {
		return this.customStyles?.join(';');
	}

	getMenu(user) {
		return [
			!this.viewOnly
				? {
					label: this.translateService.instant('common.delete'),
					command: () => {
						this.deleteUser(user);
					}
				}
				: null].filter(option => option !== null);
	}

	deleteUser(user) {
		this.assignees = this.assignees.filter(assign => assign.userId !== user.userId);
		this.addUserEvent.emit(this.assignees);
	}
}
