import { ModelBaseModel } from '@app/shared/base-model.model';

export class DelegationOfAuthority extends ModelBaseModel {
	delegationOfAuthorityId: string = null;
	delegationOfAuthorityName: string = null;
	delegationOfAuthorityNameAsBase64: string = null;
	functionalAreaId: string = null;
	triggerCondition: string = null;
	triggerConditionAsBase64: string = null;
	limitation: string = null;
	limitationAsBase64: string = null;
	delegationOfAuthorityStatusTypeId: string = null;
	delegationOfAuthorityStatusTypeName: string = null;
	delegationOfAuthorityStatusTypeNameAsBase64: string = null;
}
