<div class="container businessContinuity-planner">
    <div class="row"
         *ngIf="isIndustryDefault">
        <div class="col">
            <app-breadcrumbs [breadcrumbs]="breadCrumbs"></app-breadcrumbs>
        </div>
    </div>

    <h2 class="fw-bold my-4">{{'businessContinuity.teams.TeamDetailsTitle' | translate}}</h2>
    <div class="row mb-4">
        <label class="form-label">{{'businessContinuity.teams.teamName' | translate}}</label>
        <div class="input-group">
            <input *ngIf="!viewOnly;else viewTitle" class="form-control" [(ngModel)]="team.portalTeamName" placeholder="{{'businessContinuity.teams.enterTeamName' | translate}}" required/>
            <ng-template #viewTitle>
                <p>{{team.portalTeamName}}</p>
            </ng-template>
        </div>
    </div>
    <div class="row">
        <label class="form-label">{{'common.description' | translate}}</label>
        <div class="input-group">
            <textarea *ngIf="!viewOnly;else viewDescription" class="form-control" [(ngModel)]="team.portalTeamDescription"
                      rows="7" [maxLength]="4000" placeholder="{{'common.enterDescription' | translate}}"></textarea>
            <ng-template #viewDescription>
                <p>{{team.portalTeamDescription}}</p>
            </ng-template>
        </div>
    </div>
    <div class="row mt-5">
        <div class="d-flex justify-content-between">
            <h2 class="m-0 fw-bold">{{(this.isIndustryDefault ? 'businessContinuity.teams.positions' : 'businessContinuity.teams.members') | translate}}</h2>
            <button *ngIf="!viewOnly" (click)="createPosition()" type="button" class="btn btn-primary businessContinuityNextButton px-2 minWidth">
                {{ (this.isIndustryDefault ? 'businessContinuity.teams.addPositions' : 'businessContinuity.teams.addMembers')  | translate}}</button>
        </div>
    </div>

    <p-table
        [value]="team.positions"
        sortField="scopeName"
        [resizableColumns]="true">
        <ng-template pTemplate="header" let-columns>
            <tr>
                <th class="w-20" *ngIf="!isIndustryDefault">{{'common.name' | translate}}</th>
                <th class="w-20">{{'businessContinuity.teams.position' | translate}}</th>
                <th class="w-50">{{'businessContinuity.teams.responsibilities' | translate}}</th>
                <th class="w-10"></th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData>
            <tr>
                <ng-container *ngIf="!isIndustryDefault">
                    <td *ngIf="rowData.userId; else noUser">
                        {{rowData.userFirstName}} {{rowData.userLastName}}
                    </td>
                    <ng-template #noUser>
                        <td class="bcCard w-20">
                            <button (click)="editPosition(rowData)"
                                    *ngIf="!viewOnly"
                                    class="btn assignTeamLeadButton hasNoDelegation m-0 me-2"
                                    type="button">
                                <div class="primaryCardInfo">{{'businessContinuity.teams.assignMember' | translate}}</div>

                            </button>
                        </td>
                    </ng-template>
                </ng-container>
                <td class="w-20">
                    {{rowData.position}}

                </td>
                <td class="w-50">
                    {{rowData.responsibilities}}

                </td>
                <td class="w-10 text-center">
                    <div *ngIf="!viewOnly">
                        <button class="btn btn-primary"
                                (click)="editPosition(rowData)"
                                pTooltip="{{'common.edit' | translate}}">
                            <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
                        </button>
                        <button class="btn btn-danger"
                                pTooltip="{{'common.remove' | translate}}"
                                (click)="deletePosition(rowData)">
                            <i class="fa fa-trash" aria-hidden="true"></i>
                        </button>
                    </div>
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage" let-columns>
            <tr>
                <td colspan="4" class="text-center">
                    {{(this.isIndustryDefault ? 'businessContinuity.teams.noPositionsAdded' : 'businessContinuity.teams.noMembersAdded') | translate}}
                </td>
            </tr>
        </ng-template>
    </p-table>

    <div class="text-end my-2 my-5">
        <button (click)="cancel()" id="closeButton" type="button" class="me-2 btn btn-outline-secondary px-4 py-2">{{ (team.isLinked ? 'common.back' : 'common.cancel')  | translate}}</button>
        <button *ngIf="!viewOnly" id="saveButton" (click)="createTeam()" type="button" class="btn btn-primary px-4 py-2">{{ 'common.save'  | translate}}</button>
    </div>
</div>
<app-spinner [name]="'team-spinner'"></app-spinner>

<app-sidebar-widget [handlerText]="'businessContinuity.teams.title' | translate"
                    [headerText]="'businessContinuity.teams.title' | translate">
    <ng-template #sidebarTemplate>
        <div class="border-bottom mb-3 py-3">
            <h3 class="mt-0">{{'businessContinuity.teams.slideOut.team' | translate}}</h3>
            <label class="form-label">{{'businessContinuity.teams.teamName' | translate}}</label>
            <p>{{'businessContinuity.teams.slideOut.teamName' | translate}}</p>
            <label class="form-label">{{'common.description' | translate}}</label>
            <p>{{'businessContinuity.teams.slideOut.teamDescription' | translate}}</p>
        </div>
        <div class="border-bottom mb-3 py-3">
            <h3 class="mt-0">{{(this.isIndustryDefault ? 'businessContinuity.teams.positions' : 'businessContinuity.teams.slideOut.members') | translate}}</h3>
            <ng-container *ngIf="!isIndustryDefault">
                <label class="form-label">{{'common.name' | translate}}</label>
                <p>{{'businessContinuity.teams.slideOut.memberName' | translate}}</p>
            </ng-container>
            <label class="form-label">{{'businessContinuity.teams.position' | translate}}</label>
            <p>{{'businessContinuity.teams.slideOut.position' | translate}}</p>
            <label class="form-label">{{'businessContinuity.teams.responsibilities' | translate}}</label>
            <p>{{'businessContinuity.teams.slideOut.responsibilities' | translate}}</p>
        </div>
    </ng-template>
</app-sidebar-widget>
