 <div class="bcVendorsList  container-fluid">
    <div class="row">
        <main role="main" class="col py-3">
            <h2 class="d-flex align-items-center listPageHeader">
                {{'businessContinuity.continuityStrategy.technologyModal.typeNamePlaceholder' | translate}}
            </h2>
            <div class="row align-items-center">
                <div class="col-md-6">
                    <div class="input-group">
                        <div class="input-group-text">
                            <i class="fa fa-search" aria-hidden="true"></i>
                        </div>
                        <input id="searchInput"
                               pInputText
                               type="text"
                               aria-describedby="inputGroup-search"
                               size="100"
                               class="form-control"
                               placeholder="{{ 'issue.issuesFilterDesc' | translate }}"
                               (input)="technologiesListTable.filterGlobal($event.target.value, 'contains')"/>
                    </div>
                </div>
                <div class="col-md-6 text-end">
                    <button  type="button"
                             class="btn btn-secondary"
                             *ngIf="this.canManage"
                             (click)="openManageTypes()">
                        {{'businessContinuity.continuityStrategy.technologyModal.manageTechnologyTypes' | translate}}
                    </button>
                    <button id="bulkUploadButton"
                            type="button"
                            class="btn btn-secondary"
                            (click)="bulkUpload()">
                        {{'businessContinuity.continuityStrategy.technologyModal.bulkUpload' | translate}}
                    </button>
                    <button type="button"
                            id="newVendorButton"
                            class="btn btn-primary addVendorButton"
                            (click)="addTechnology()">
                        {{'businessContinuity.continuityStrategy.technologyModal.addTechnology' | translate}}
                    </button>
                </div>
            </div>
            <p-table #technologiesListTable
                     [value]="technologies"
                     dataKey="technologyId"
                     [globalFilterFields]="['technologyName','createdByName','dateTimeCreated', 'rpoTimeInSeconds']"
                     sortField="technologyName"
                     [sortOrder]="1"
                     [loading]="loading">
                <ng-template pTemplate="header">
                    <tr>
                        <th pSortableColumn="technologyName">
                            {{'businessContinuity.continuityStrategy.technologyModal.technology' | translate}}
                            <p-sortIcon field="technologyName"></p-sortIcon>
                        </th>
                        <th pSortableColumn="tier">
                            {{'businessContinuity.continuityStrategy.technologyModal.tier' | translate}}
                            <p-sortIcon field="tier"></p-sortIcon>
                        </th>
                        <th pSortableColumn="technologyType">
                            {{'common.type' | translate}}
                            <p-sortIcon field="technologyType"></p-sortIcon>
                        </th>
                        <th>
                            {{'common.assigned' | translate}}
                        </th>
                        <th>
                            {{'common.usedInPlans' | translate}}
                        </th>
                        <th pSortableColumn="rpoTimeInSeconds">
                            {{'common.rpo' | translate}}
                            <p-sortIcon field="rpoTimeInSeconds"></p-sortIcon>
                        </th>
                        <th pSortableColumn="createdByName">
                            {{'common.createdBy' | translate}}
                            <p-sortIcon field="createdByName"></p-sortIcon>
                        </th>
                        <th pSortableColumn="dateTimeCreated">
                            {{'common.createdOn' | translate}}
                            <p-sortIcon field="dateTimeCreated"></p-sortIcon>
                        </th>
                        <th class="downloadColumn"></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-technology>
                    <tr>
                        <td>
                            <a href="javascript:void(0)"
                               (click)="openPortalTechnologyModal(technology)">{{technology.technologyName}}</a>
                        </td>
                        <td>
                            {{ technology.tier }}
                        </td>
                        <td>
                            {{ technology.technologyType?.technologyTypeName }}
                        </td>
                        <td>
                            <app-bc-resources-assigned-overlay-panel
                                [assignedPortalProcesses]="technology.assignedPortalProcesses"></app-bc-resources-assigned-overlay-panel>
                        </td>
                        <td>
                            <app-bc-plans-assigned-overlay-panel
                                [assignedPortalPlans]="technology.assignedPlanTypes"></app-bc-plans-assigned-overlay-panel>
                        </td>
                        <td>
                            {{ technology.rpoString }}
                        </td>
                        <td class="text-truncate">
                            {{technology.createdByName}}
                        </td>
                        <td>
                            {{technology.dateTimeCreated | date:dateformat.shortDate}}
                        </td>
                        <td class="text-center">
                            <button id="{{technology.technologyId + '-deleteTechnology'}}"
                                    type="button"
                                    class="btn btn-danger"
                                    (click)="deleteTechnology(technology)"
                                    pTooltip="{{'common.remove' | translate}}"
                                    tooltipPosition="left">
                                <i class="fas fa-trash"></i>
                            </button>
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td class="text-center" colspan="9">{{'common.noRecords' | translate}}</td>
                    </tr>
                </ng-template>
            </p-table>
        </main>
    </div>
</div>
