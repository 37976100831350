<div class="modal-body position-relative">
    <div class="row mb-4">
        <div [hidden]="availableTargets.length < 1" class="d-flex align-items-start" *ngIf="loaded">
            <p-inputSwitch class="me-2" [(ngModel)]="setDefault" [disabled]="readonlyDefault"></p-inputSwitch>
            <div class="pt-1 ps-2">
                <p class="fw-bold d-inline">{{'businessContinuity.planTemplates.assignDefaultTemplatesDescription.0' |
                    translate : {entityType : getEntityName(planTemplate.planTemplateTargetTypeId)}  }}</p>
                <p class="d-inline" *ngIf=!isCompany>{{getTranslation()}}</p>
            </div>
        </div>
    </div>
    <div *ngIf="selectedTargets.length > 0 && !setDefault">
        <div class="row">
            <div class="row">
                <div class="col">
                    <p class="fw-bold">
                        {{getTitle(true)}}
                    </p>
                </div>
            </div>
            <div class="container-fluid" *ngIf="loaded">
                <div class="row users scroll">
                    <div class="col-3" *ngFor="let target of selectedTargets">
                        <button class="btn btn-link user selected" (click)="handleTargetSelect(target)">
                            <div class="name text-truncate">
                                {{target.targetName}}
                            </div>
                            <div class="work-title my-1 text-truncate">
                                {{target.templateTargetFirstName}}
                                {{target.templateTargetLastName || specialCharacters.doubleDash}}
                            </div>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row" *ngIf="loaded && availableTargets.length > 0">
        <div class="row">
            <div class="col">
                <p class="fw-bold" *ngIf="!setDefault">
                    {{getTitle()}}
                </p>
                <p class="fw-bold" *ngIf="setDefault">
                    {{getTitle(true)}}
                </p>
            </div>
        </div>
        <div class="container-fluid">
            <div class="row users scroll">
                <div class="col-3" *ngFor="let target of availableTargets">
                    <button [disabled]="setDefault" class="btn btn-link user" (click)="handleTargetSelect(target)">
                        <div class="name text-truncate">
                            {{target.targetName}}
                        </div>
                        <div class="work-title my-1 text-truncate">
                            {{target.templateTargetFirstName}}
                            {{target.templateTargetLastName || specialCharacters.doubleDash}}
                        </div>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal-footer d-flex gap-2 text-end">
    <button type="button" class="btn btn-outline-secondary" (click)="cancel()">
        {{'common.cancel' | translate}}
    </button>
    <button type="button" class="btn btn-primary" (click)="saveAssignedTemplateDepartments()">
        {{'common.assign' | translate}}
    </button>
</div>
<app-spinner name="templateTargetsModal"></app-spinner>
