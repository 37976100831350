import { BaseResponse } from '@app/shared/base-response.model';

export class TechnologyType extends BaseResponse {
	portalId: string;
	technologyTypeId: string;
	technologyTypeName: string;
	technologyTypeNameAsBase64: string = '';
	technologyTypeStatusId: string;
	technologiesCount: number;
	sourceTechnologyTypeId: string;
	// for ui use only
	isNew: boolean = false;
	selected: boolean = false;

	constructor() {
		super();
	}
}

export class ModelGetTechnologyTypeResponse {
	technologyTypes: TechnologyType[] = [];

	constructor() {
	}
}
