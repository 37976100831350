import { Component, OnInit } from '@angular/core';
import { Utility } from '@app/providers/utility';
import { Vendor, VendorAddress, VendorContact } from '@app/shared/business-continuity/vendor.model';
import { PhoneNumberService } from '@app/providers/phoneNumber.service';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Country } from '@app/shared/country.model';
import { SecurityService } from '@app/providers/security.service';
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { Document } from '@app/shared/document.model';
import { ConfirmationModalMultiOptionComponent } from '@app/components/modals/confirmation-modal-multioption/confirmation-modal-multioption.component';
import { SpinnerService } from '@app/providers/spinner.service';
import { DocumentService } from '@app/providers/document.service';
import { FileUploader } from 'ng2-file-upload';
import { BusinessContinuityService } from '@app/providers/business-continuity.service';
import { serviceErrorCodes } from '@app/app.constants';
import { validatePhoneNumber } from '@app/validators/phoneNumber.validator';
import { ValidateUrl } from '@app/validators/url.validator';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
	selector: 'app-bc-vendor-modal',
	templateUrl: './bc-vendor-modal.component.html',
	styleUrls: ['./bc-vendor-modal.component.scss']
})
export class BcVendorModalComponent implements OnInit {
	vendor: Vendor = new Vendor();
	vendorForm: UntypedFormGroup;
	public vendorAddress: VendorAddress = new VendorAddress();
	public countries: Array<Country> = [];
	public documents: Array<Document> = [];
	public documentsToBeRemoved: Array<Document> = [];
	fileReader: FileReader = new FileReader();
	uploader: FileUploader = new FileUploader({ url: null });
	public serviceErrorCodes = serviceErrorCodes;
	public saving: boolean = false;
	startDateMax: Date;
	endDateMin: Date;
	submitted: boolean = false;
	isCreating = true;

	constructor(public dynamicDialogRef: DynamicDialogRef,
		public dynamicDialogConfig: DynamicDialogConfig,
		private utility: Utility,
		private phoneNumberService: PhoneNumberService,
		private securityService: SecurityService,
		private toastrService: ToastrService,
		public translateService: TranslateService,
		private documentService: DocumentService,
		private bcService: BusinessContinuityService,
		private fb: UntypedFormBuilder,
		private dialogService: DialogService) {
		this.vendor = this.dynamicDialogConfig?.data?.vendor;
	}

	get vendorContactArray(): UntypedFormArray {
		return this.vendorForm.get('vendorContacts') as UntypedFormArray;
	}

	get addressUrlValid(): boolean {
		return this.vendorForm.get('vendorAddress').get('url').valid;
	}

	get addressPhoneValid(): boolean {
		return this.vendorForm.get('vendorAddress').get('phone').valid;
	}

	ngOnInit(): void {
		// init values
		this.vendor.vendorContacts = this.vendor.vendorContacts || [];
		this.vendor.vendorAddress = this.vendor.vendorAddress || new VendorAddress();

		this.getCountries();

		this.loadVendorForm();

		if (this.vendor.vendorId) {
			this.loadVendorDocuments();
		}
	}

	loadVendorForm() {
		this.vendorForm = this.fb.group({
			vendorName: ['', [Validators.required, Validators.maxLength(100), this.utility.noWhitespaceValidator]],
			vendorAddress: this.fb.group({
				address1: ['', Validators.maxLength(500)],
				address2: ['', Validators.maxLength(500)],
				city: ['', Validators.maxLength(100)],
				state: ['', Validators.maxLength(100)],
				zip: ['', Validators.maxLength(100)],
				countryTypeId: ['2d76605b-86b5-40ff-9a71-c4893e5916fb'],
				phone: ['', validatePhoneNumber],
				url: ['', ValidateUrl],
				vendorAddressId: ['']
			}),
			contractStartDate: [''],
			contractEndDate: [''],
			vendorContacts: this.fb.array([]),
			comments: ['']
		});

		if (this.vendor.vendorId) {
			this.isCreating = false;
			const { vendorName, comments, vendorAddress, contractStartDate, contractEndDate } = this.vendor;
			this.vendorForm.patchValue({
				vendorName: vendorName,
				vendorAddress: {
					address1: vendorAddress.address1,
					address2: vendorAddress.address2,
					city: vendorAddress.city,
					state: vendorAddress.state,
					zip: vendorAddress.zip,
					countryTypeId: vendorAddress.countryTypeId,
					phone: this.phoneNumberService.toDisplayFormat(vendorAddress.phone),
					url: vendorAddress.url,
					vendorAddressId: vendorAddress.vendorAddressId
				},
				contractStartDate: contractStartDate,
				contractEndDate: contractEndDate,
				comments: comments
			});

			this.setExpDateMin(contractStartDate);
			this.setStartDateMin(contractEndDate);

			this.vendorForm.setControl('vendorContacts', this.fb.array(this.fillContactArray()));
		}
	}

	fillContactArray() {
		const contacts = [];
		if (this.vendor?.vendorContacts.length > 0) {
			for (const contact of this.vendor.vendorContacts) {
				contacts.push(
					this.createContactFormGroup(contact)
				);
			}
		}
		return contacts;
	}

	loadVendorDocuments() {
		this.bcService.getPortalVendorDocuments(this.vendor.vendorId)
			.then((res: any) => {
				if (res.documents) {
					this.documents = res.documents;
				}
			})
			.catch(() => {
				// this.toastrService.error('Error loading documents');
			})
			.then(() => {
			});
	}

	dismiss() {
		this.dynamicDialogRef.close(null);
	}

	getFormValues() {
		const vendorForm = this.vendorForm.value;
		this.vendor.vendorName = vendorForm.vendorName;
		this.vendor.comments = vendorForm.comments;
		this.vendor.contractStartDate = vendorForm.contractStartDate;
		this.vendor.contractEndDate = vendorForm.contractEndDate;

		const { address1, address2, city, state, zip, countryTypeId, phone, url } = vendorForm.vendorAddress;
		this.vendor.vendorAddress.address1 = address1 || '';
		this.vendor.vendorAddress.address2 = address2 || '';
		this.vendor.vendorAddress.city = city || '';
		this.vendor.vendorAddress.state = state || '';
		this.vendor.vendorAddress.zip = zip || '';
		this.vendor.vendorAddress.countryTypeId = countryTypeId || this.vendor.vendorAddress.countryTypeId;
		this.vendor.vendorAddress.phone = this.phoneNumberService.toE164Format(phone) || '';
		this.vendor.vendorAddress.url = url || '';

		this.vendor.vendorContacts = this.getVendorContactsValue();
	}

	getVendorContactsValue(): VendorContact[] {
		const vendorFormContacts = this.vendorForm.value.vendorContacts as VendorContact[];
		const newContacts: VendorContact[] = [];

		if (vendorFormContacts.length > 0) {
			vendorFormContacts.forEach((elm) => {
				const contact = new VendorContact();
				contact.isPrimary = elm.isPrimary;
				contact.name = elm.name;
				contact.title = elm.title;
				contact.email = elm.email;
				contact.phone = this.phoneNumberService.toE164Format(elm.phone);
				contact.vendorContactId = elm.vendorContactId || '';
				contact.vendorId = elm.vendorId || '';

				newContacts.push(contact);
			});
		}
		if (newContacts) {
			this.vendor.vendorContacts = newContacts;
		}
		return newContacts;
	}

	save() {
		this.submitted = true;
		if (this.vendorForm.invalid || this.vendorContactArray.invalid) {
			return;
		}
		this.getFormValues();

		// generate new guid for new vendor b4 saving
		this.vendor.vendorId = this.vendor.vendorId || this.utility.getGuid();

		// actually upsert here
		this.bcService.savePortalVendor(this.vendor).then((result: any) => {
			this.toastrService.success(this.vendor.vendorName + ' ' + this.translateService.instant('common.saved'));

			// only do this if save vendor success
			const promises: Array<Promise<any>> = [];

			// remove the documents here 1st!!!
			// each documentId should be unique
			this.documentsToBeRemoved.forEach((document: Document) => promises.push(this.onRemoveDocument(document)));

			// and then only upload doc HERE
			this.documents.forEach((document: Document) => promises.push(this.onUploadDocument(document)));

			Promise.all(promises)
				.then(() => {
					this.closeModal();
				});
		})
			.catch((error: any) => {
				switch (error.errorCode) {
					case this.serviceErrorCodes.bcDuplicateVendorName:
						this.toastrService.error(this.translateService.instant('businessContinuity.continuityStrategy.vendorModal.duplicateVendorNameError'));
						break;
					default:
						this.toastrService.error(this.translateService.instant('errorMessages.genericError'));
				}
			});
	}

	getCountries() {
		this.securityService.getCountries()
			.then((res: any) => {
				this.countries = _.sortBy(res, ['countryTypeName']);
			});
	}

	toggleContactIsPrimary(vendorContact: VendorContact, index: number) {
		let i = 0;
		const contacts = this.vendorForm.get('vendorContacts') as UntypedFormArray;
		for (const c of contacts.controls) {
			if (i !== index) {
				c.get('isPrimary').setValue(false);
			}
			i++;
		}
	}

	addNewVendorContact() {
		this.vendorContactArray.push(this.createContactFormGroup());
	}

	deleteContact(rowIndex: number) {
		this.vendorContactArray.removeAt(rowIndex);
	}

	setStartDateMin(value?) {
		if (value) {
			this.startDateMax = new Date(value).addDays(-1);
		}
	}

	setExpDateMin(value?) {
		if (value) {
			this.endDateMin = new Date(value).addDays(1);
		}
	}

	setStartDateOnInput(event?) {
		if (event) {
			const value = event.target.value;
			this.setStartDateMin(value);
		}
	}

	setEndDateOnInput(event?) {
		if (event) {
			const value = event.target.value;
			this.setExpDateMin(value);
		}
	}

	clearDateConstraints() {
		this.startDateMax = null;
		this.endDateMin = null;
	}

	removeFromVendorContact(index: number) {
		this.vendor.vendorContacts.splice(index, 1);
	}

	getDocumentIcon(document: Document) {
		return this.utility.getFileIcon({
			documentFileName: document.documentFileName,
			isIQDocument: false
		});
	}

	getDocument(document: Document) {
		if (this.vendor.vendorId) {
			// GET VENDOR DOCUMENTS AS STREAM GOES HERE
			this.bcService.getPortalVendorDocumentAsStream(document.documentId)
				.then((res: any) => {
					this.documentService.openBlob(document.documentFileName, res, true);
				})
				.catch((error) => {
				});
		}
		else {
			this.documentService.openBlob(document.documentFileName, this.documentService.base64ToBlob(document.documentContentsAsBase64), true);
		}
	}

	onDeleteDocument(document: Document) {
		const modalRef = this.dialogService.open(ConfirmationModalMultiOptionComponent, {
			styleClass: '.confirmation-container',
			data: {
				baseResourcePath: 'incident.deleteDocumentConfirmation',
				interpolateParams: { docName: document.documentFileName },
				confirmButtonText: ['btnText']
			}
		});

		// handle modal result
		modalRef.onClose.subscribe((res: any) => {
			switch (res) {
				case 1:
					this.deleteDocument(document);
			}
		});
	}

	deleteDocument(document: Document) {
		this.documents = this.documents.filter(d => d.documentId !== document.documentId);
		this.documentsToBeRemoved.push(document);

		// keep only unique documentId
		this.documentsToBeRemoved = _.uniqBy(this.documentsToBeRemoved, 'documentId');
	}

	onRemoveDocument(document: Document) {
		if (this.vendor.vendorId) {
			return this.bcService.deletePortalVendorDocuments(document.documentId)
				.then((_) => {
					this.documents = this.documents.filter(d => d.documentId !== document.documentId);
				})
				.catch((_) => {
				});
		}
		else {
			this.documents = this.documents.filter(d => d.documentId !== document.documentId);
		}
	}

	fileSelected(e) {
		const file = e.target.files[0];

		// file name duplicate check
		if (this.documents.findIndex(d => d.documentFileName.toLowerCase() === file.name.toLowerCase()) > -1) {
			this.toastrService.error(this.translateService.instant('errorMessages.duplicateFilename'));
			this.uploader.clearQueue();
		}
		else {
			this.processFile(file);
		}

		// reset value so can upload again
		e.target.value = '';
	}

	processFile(file) {
		this.documentService.checkFileSizeAndMaliciousTypes(file).then((_) => {
			this.fileReader.readAsDataURL(file);
		}).catch((err) => {
			this.uploader.clearQueue();
			this.toastrService.error(err.message);
		});
		this.fileReader.onloadend = (e: any) => {
			const dataUrl = this.fileReader.result as string;
			const document: Document = new Document();
			document.documentId = this.utility.getGuid();
			document.documentContentsAsBase64 = dataUrl.substring(dataUrl.indexOf(',') + 1);
			document.documentMimeType = dataUrl.split(',')[0].split(':')[1].split(';')[0];
			document.documentFileName = file.name;
			document.sizeInBytes = file.size;
			this.documents.push(document);
		};
	}

	onUploadDocument(document: Document) {
		if (!this.vendor.vendorId) {
			this.vendor.vendorId = this.utility.getGuid();
		}

		return this.bcService.uploadPortalVendorDocument(this.vendor.vendorId, document)
			.then((_) => {
				this.documents.push(document);
			})
			.catch((_) => {
			})
			.then((_) => {
			});
	}

	closeModal() {
		this.dynamicDialogRef.close(this.vendor);
	}

	private createContactFormGroup(contact?): UntypedFormGroup {
		return this.fb.group({
			vendorContactId: new UntypedFormControl(contact?.vendorContactId || ''),
			vendorId: new UntypedFormControl(contact?.vendorContactId || ''),
			isPrimary: new UntypedFormControl(contact?.isPrimary || false),
			name: new UntypedFormControl(contact?.name || '', [Validators.required, this.utility.noWhitespaceValidator]),
			title: new UntypedFormControl(contact?.title || ''),
			email: new UntypedFormControl(contact?.email || '', Validators.email),
			phone: new UntypedFormControl(contact?.phone ? this.phoneNumberService.toDisplayFormat(contact?.phone) : '', validatePhoneNumber)
		});
	}
}
