<div class="mb-3">
    <label class="form-label" [ngClass]="{'invalid': invalid}">{{descriptionResource | translate}}</label>
    <div class="users row">
        <div class="user col-auto" *ngFor="let a of assignees;">
            <div class="profileImage">
                <img [ngClass]="{'deleted': a.userStateId === userStateTypes.Deleted || a.userStateId === userStateTypes.Inactive}"
                     [src]="a.profileURL | trusted"/>
                <div class="watermark text-lowercase" *ngIf="a.userStateId === userStateTypes.Deleted">
                    ({{'common.deleted' | translate}})
                </div>
                <div class="watermark text-lowercase" *ngIf="a.userStateId === userStateTypes.Inactive">
                    ({{'common.inactive' | translate}})
                </div>
            </div>
            <div class="name" [ngClass]="{'deleted': a.userStateId === userStateTypes.Deleted || a.userStateId === userStateTypes.Inactive}">
                {{a.firstName}} {{a.lastName}}
            </div>
            <div class="my-1 text-muted text-truncate">{{a.title || specialCharacters.doubleDash}}</div>
            <div class="location text-muted text-truncate">{{a.locationEntityName}}</div>
            <button *ngIf="canManage" type="button" class="btn btn-link removeButton"
                    [id]="'buttonUserUnassign' + a.userId" (click)="confirmRemoveAssignee(a)">
                <i class="fas fa-minus-circle"></i>
            </button>
        </div>
        <div id="openAssigneeModalDiv" *ngIf="canManage" class="btn btn-link user p-0" (click)="openAssigneeModal()">
            <div class="circle m-auto">
                <i class="fas fa-plus"></i>
            </div>
            <div class="name">
                {{'businessContinuity.continuityStrategy.addEmployee' | translate}}
            </div>
        </div>
    </div>
</div>
