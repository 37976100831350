<div class="modal-body">
    <div class="container-fluid p-1">
        <div class="row">
            <div id="bcIssues" class="col-12 scrollY">
                <app-bc-issue
                    [planTypeId]="planTypeId"
                    [requireFunctionalAreaId]="requireFunctionalAreaId"
                    (issueSaved)="issueSaved($event)"
                    (issueCancelled)="dismiss()"></app-bc-issue>
            </div>
        </div>
    </div>
</div>
