import { Component, OnInit } from '@angular/core';
import { PortalFunctionalArea } from '@app/shared/business-continuity/portal-functional-area.model';
import { featureTypes, planTemplateTargetTypeIds, specialCharacters } from '@app/app.constants';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Utility } from '@app/providers/utility';
import { BusinessContinuityService } from '@app/providers/business-continuity.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { EntityService } from '@app/providers/entity.service';
import { SpinnerService } from '@app/providers/spinner.service';
import { ConfirmationService } from 'primeng/api';
import { PortalTeam } from '@app/shared/business-continuity/portal-team.model';

@Component({
	selector: 'app-assign-team-modal',
	templateUrl: './assign-team-modal.component.html',
	styleUrls: ['./assign-team-modal.component.scss']
})
export class AssignTeamModalComponent implements OnInit {
	teamId: string;
	siteId: string;
	portalPlanTypeId: string;
	team: PortalTeam;
	teamName: string;
	loaded: boolean = false;
	selectedDepartments: PortalFunctionalArea[] = [];
	availableDepartments: PortalFunctionalArea[] = [];
	baseDepartments: PortalFunctionalArea[] = [];
	readonlyDefault: boolean = false;
	targetTypeIds = planTemplateTargetTypeIds;
	featureTypes = featureTypes;
	public specialCharacters = specialCharacters;

	constructor(public dynamicDialogRef: DynamicDialogRef,
		public dynamicDialogConfig: DynamicDialogConfig,
		public utility: Utility,
		public bcService: BusinessContinuityService,
		public toastService: ToastrService,
		public translateService: TranslateService,
		public entityService: EntityService,
		public spinner: SpinnerService,
		private confirmationService: ConfirmationService
	) {
		this.teamId = this.dynamicDialogConfig?.data?.teamId;
		this.siteId = this.dynamicDialogConfig?.data?.siteId;
		this.portalPlanTypeId = this.dynamicDialogConfig?.data?.portalPlanTypeId;
		this.readonlyDefault = this.dynamicDialogConfig?.data?.isDefault;
	}

	ngOnInit(): void {
		this.spinner.start('templateTargetsModal');
		this.getDepartments();
	}

	getDepartments() {
		const promises = [this.bcService.getPortalTeam(this.teamId, this.portalPlanTypeId), this.bcService.getPortalFunctionalAreas(this.siteId, this.portalPlanTypeId, true)];
		Promise.all(promises).then(([team, res]: any) => {
			this.baseDepartments = res.portalFunctionalAreas.map((pfa: PortalFunctionalArea) => {
				pfa.alias = pfa.portalFunctionalAreaName || pfa.functionalArea.functionalAreaName;
				if (pfa.leader) {
					pfa.leader.locationEntityId = pfa.leader.entities[0].entityId;
					pfa.leader.locationEntityName = pfa.leader.entities[0].entityName;
				}
				return pfa;
			});
			this.team = team.portalTeam;
			this.teamName = this.team.portalTeamName;
			this.selectAll(this.team.assignToAllDepartments);
			this.selectedDepartments = this.team?.linkedDepartments?.map((id: string) => {
				return this.baseDepartments.find((department: PortalFunctionalArea) => department.portalFunctionalAreaId == id);
			});
		}).finally(() => {
			this.loaded = true;
			this.spinner.stop('templateTargetsModal');
		});
	}

	handleTargetSelect(target: PortalFunctionalArea) {
		const index = this.selectedDepartments.findIndex(item => target.portalFunctionalAreaId === item.portalFunctionalAreaId);
		if (index === -1) {
			this.selectedDepartments.push(target);
			const availableItemIndex = this.availableDepartments.findIndex(item => target.portalFunctionalAreaId === item.portalFunctionalAreaId);
			this.availableDepartments.splice(availableItemIndex, 1);
		}
		else {
			this.selectedDepartments.splice(index, 1);
			this.availableDepartments.push(target);
		}
	}

	cancel() {
		this.dynamicDialogRef.close(false);
	}

	getEntityName() {
		return this.translateService.instant('common.department');
	}

	saveAssignedDepartments() {
		const targetsToAssign: any = {};
		targetsToAssign.portalTeamId = this.team.portalTeamId;
		let targetIds: string[];

		if (this.team.assignToAllDepartments) {
			targetIds = this.availableDepartments.map(d => d.portalFunctionalAreaId);
			targetsToAssign.portalFunctionalAreaIds = targetIds;
			targetsToAssign.makeDefault = true;

			this.confirmationService.confirm({
				header: this.translateService.instant('businessContinuity.teams.associateModal.setTeamAsDefault'),
				message: this.translateService.instant('businessContinuity.teams.associateModal.setTeamDefaultConfirmation'),
				accept: () => {
					this.assignAllDepartments();
				},
				rejectIcon: 'null',
				acceptIcon: 'null'
			});
		}
		else {
			targetIds = this.selectedDepartments.map(d => d.portalFunctionalAreaId);
			targetsToAssign.portalFunctionalAreaIds = targetIds;
			targetsToAssign.makeDefault = false;
			this.linkPortalTeams(targetIds, this.team.portalTeamId);
		}
	}

	linkPortalTeams(portalFunctionalAreaIds: string[], portalTeamId) {
		Promise.all([this.bcService.savePortalTeam(this.team), this.bcService.linkPortalTeams(portalFunctionalAreaIds, portalTeamId, this.portalPlanTypeId)])
			.then(() => {
				this.toastService.success(this.translateService.instant('businessContinuity.teams.teamLinkedSuccessfully'));
				this.dynamicDialogRef.close(true);
			})
			.catch(() => this.toastService.error(this.translateService.instant('bien')));
	}

	assignAllDepartments() {
		this.spinner.start('templateTargetsModal');
		this.bcService.savePortalTeam(this.team).then(() => {
			this.toastService.success(this.translateService.instant('businessContinuity.teams.associateModal.assignToAllSuccessfully'));
			this.dynamicDialogRef.close(true);
		}).catch(() => {
			this.toastService.error(this.translateService.instant('businessContinuity.teams.associateModal.assignToAllUnsuccessfully'));
		}).finally(() => {
			this.spinner.stop('templateTargetsModal');
		});
	}

	selectAll(all: boolean) {
		if (!all) {
			this.availableDepartments = this.baseDepartments.filter(d =>
				!this.team?.linkedDepartments?.some(e =>
					(e === d.portalFunctionalAreaId)));
		}
		else {
			this.availableDepartments = this.baseDepartments;
		}
	}
}
