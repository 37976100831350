<div class="container">

	<div class="d-flex justify-content-between">
		<h5 class="m-2">{{ 'businessContinuity.continuityStrategy.technologyModal.technologyTypesTitle' | translate }}</h5>
		<button class="btn btn-secondary float-end px-3"
				(click)="new()">{{ 'Add Type' | translate }}
		</button>
	</div>
	<p-table #table [value]="technologyTypes" dataKey="technologyTypeId" editMode="row" [loading]="loading">
		<ng-template pTemplate="header">
			<tr>
				<th style="width:45%">
					{{ 'businessContinuity.continuityStrategy.technologyModal.technologyType' | translate }}
				</th>
				<th style="width:45%">
					{{ 'businessContinuity.continuityStrategy.technologyModal.technologyUsed' | translate }}
				</th>
				<th class="editColumn" *ngIf="canEdit"></th>
			</tr>
		</ng-template>
		<ng-template pTemplate="body" let-technologyType let-editing="editing">
			<tr [pEditableRow]="technologyType">
				<td>
					<p-cellEditor>
						<ng-template pTemplate="input">
							<input pInputText type="text" [(ngModel)]="technologyType.technologyTypeName" required>
						</ng-template>
						<ng-template pTemplate="output">
							{{ technologyType.technologyTypeName }}
						</ng-template>
					</p-cellEditor>
				</td>
				<td>
					{{ technologyType.technologiesCount }}
				</td>
				<td *ngIf="canEdit">
					<div class="d-flex float-end">
						<button *ngIf="!editing  && technologyType.portalId !== empty" type="button" pInitEditableRow
								type="button" class="btn btn-primary "
								pTooltip="{{'common.edit' | translate}}"
								tooltipPosition="left" [disabled]="loading">
							<i class="fas fa-pencil-alt"></i>
						</button>
						<button *ngIf="editing"
								(click)="save(technologyType)"
								type="button" pSaveEditableRow class="btn btn-success me-2">
							<i class="fas fa-check"></i>
						</button>
						<button *ngIf="editing" (click)="cancelEdit(technologyType)" type="button" pCancelEditableRow class="btn btn-danger">
							<i class="fas fa-times"></i>
						</button>
						<button [disabled]="technologyType.technologiesCount > 0" id="delete-id01" type="button" class="btn btn-danger ms-2" *ngIf="!technologyType.isNew"
								(click)="delete(technologyType)" pTooltip="{{'common.delete' | translate}}"
								tooltipPosition="left">
							<i class="fas fa-trash"></i>
						</button>
					</div>
				</td>
			</tr>
		</ng-template>
		<ng-template pTemplate="emptymessage">
			<tr>
				<td class="text-center" colspan="7">{{ 'common.noRecords' | translate }}</td>
			</tr>
		</ng-template>
	</p-table>
	<div class="my-3 text-end">
		<button class="btn btn-secondary px-3"
				(click)="close()">{{ 'common.close' | translate }}
		</button>
	</div>
</div>

<p-confirmDialog [acceptIcon]="null"
				 [rejectIcon]="null"
				 acceptButtonStyleClass="btn btn-primary"
				 rejectButtonStyleClass="btn btn-outline-secondary"></p-confirmDialog>
