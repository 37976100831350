import { ModelBaseModel } from '@app/shared/base-model.model';

export class OrderOfSuccession extends ModelBaseModel {
	functionalAreaId: string;
	orderOfSuccessionId: string;
	positionTitleAsBase64: string = '';
	positionTitle: string;
	orderOfSuccessionStatusTypeNameAsBase64: string = '';
	orderOfSuccessionStatusTypeId: string;
	orderOfSuccessionStatusTypeName: string;
}
