<div class="container">
    <div class="row" *ngIf="showTitle">
        <div class="col">
            <h3 class="fw-semibold">{{ 'businessContinuity.ordersOfSuccession.title' | translate }}</h3>
        </div>
    </div>
    <div class="row" *ngIf="orderOfSuccession">
        <div class="col">
            <label class="mt-2 form-label fw-bold">{{ 'common.title' | translate }}</label>
            <input type="text" class="form-control" *ngIf="canManageDepartment;else viewOnly" name="title" required [(ngModel)]="orderOfSuccession.positionTitle" />
            <ng-template #viewOnly>
                <p [innerHTML]="orderOfSuccession && orderOfSuccession.positionTitle"></p>
            </ng-template>
        </div>
    </div>
    <ng-container *ngIf="!isIndustryDefault">
        <div class="row pt-5">
            <div class="col">
                <label class="form-label fw-bold mt-2">{{ 'common.primary' | translate }}</label>
            </div>
        </div>
        <div class="d-flex mb-4">
            <div class="col-md-3">
                <div class="d-flex align-items-center justify-content-center user border border-1" *ngIf="primary; else addPrimary">
                    <ng-container *ngIf="canManageDepartment">
                        <p-menu
                            #menu
                            [model]="primaryOptions"
                            [popup]="true"
                            [appendTo]="'body'"
                        ></p-menu>
                        <button (click)="menu.toggle($event)" class="dropdownToggle bg-transparent border-0  d-inline-block" icon="fa-solid fa-ellipsis-vertical" pButton type="button"></button>

                    </ng-container>
                    <div class="d-block">
                        <div class="profileImage mb-3 d-flex justify-content-center gap-1">
                            <img *ngIf="primary?.userProfileImage" class="img-fluid" [src]="primary?.userProfileImage | trusted">
                            <img *ngIf="!primary?.userProfileImage" class="img-fluid" [src]="defaultImage"/>
                        </div>
                        <div class="name text-muted justify-content-center row">
                            <div pTooltip="{{primary?.firstName + '' + primary?.lastName}}" class="col-10 text-truncate">{{ primary?.firstName }} {{ primary?.lastName }}</div>
                            <div class="col-10 text-center text-truncate">
                                {{ primary?.title || specialCharacters.doubleDash }}
                            </div>
                        </div>
                    </div>
                </div>
                <ng-template #addPrimary>
                    <div class="col-3 p-0 mb-3" *ngIf="canManageDepartment">
                        <div (click)="openUsersSelectModal(true)"
                             class="btn btn-link p-0 border border-1 d-flex align-items-center justify-content-center"
                             id="openUserSelectModal">
                            <div class="d-block">
                                <i class="fas fa-light fa-plus"></i>
                                <div class="fw-bold">
                                    <span>{{ 'businessContinuity.ordersOfSuccession.AddPrimary' | translate }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </div>
        </div>

        <div class="d-flex flex-wrap">
            <div
                class="m-2 minWidth"
                *ngFor="let alternate of mappedAlternates; let i = index">
                <label class="form-label fw-bold mt-2">
                    #{{ i + 1 }}
                    {{ 'businessContinuity.ordersOfSuccession.alternate' | translate }}
                </label>
                <div class="d-flex align-items-center justify-content-center user border border-1">
                    <ng-container *ngIf="canManageDepartment">
                        <p-menu
                            #menu
                            [model]="alternateOptions[alternate.userId]"
                            [popup]="true"
                            [appendTo]="'body'"
                        ></p-menu>
                        <button (click)="menu.toggle($event)" class="dropdownToggle bg-transparent border-0  d-inline-block" icon="fa-solid fa-ellipsis-vertical" pButton type="button"></button>
                    </ng-container>
                    <div class="d-block">
                        <div class="profileImage mb-3 d-flex justify-content-center gap-1">
                            <img *ngIf="alternate?.userProfileImage" class="img-fluid" [src]="alternate?.userProfileImage | trusted"/>
                            <img *ngIf="!alternate?.userProfileImage" class="img-fluid" [src]="defaultImage"/>
                        </div>
                        <div class="name text-muted justify-content-center row">
                            <div pTooltip="{{alternate?.firstName + '' + alternate?.lastName}}" class="col-10 text-truncate">{{ alternate?.firstName }} {{ alternate?.lastName }}</div>
                            <div class="col-10 text-center text-truncate">
                                {{ alternate?.title || specialCharacters.doubleDash }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ng-container *ngIf="canManageDepartment">
                <ng-container *ngFor="let createButton of [].constructor(3 - mappedAlternates.length);let i = index">
                    <div class="p-0 mb-2 m-2 minWidth" *ngIf="!(mappedAlternates.length > 2)">
                        <label class="form-label fw-bold mt-2">
                            #{{ i + 1 + mappedAlternates.length }}
                            {{ 'businessContinuity.ordersOfSuccession.alternate' | translate }}
                        </label>
                        <div (click)="openUsersSelectModal()"
                             class="btn btn-link p-0 border border-1 d-flex align-items-center justify-content-center"
                             id="openAlternateSelectModal">
                            <div class="d-block">
                                <i class="fas fa-light fa-plus"></i>
                                <div class="fw-bold">
                                    <span>{{ 'businessContinuity.ordersOfSuccession.AddAlternates' | translate }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </ng-container>
        </div>
    </ng-container>
    <div class="row my-5">
        <div class="d-inline-flex justify-content-end gap-3">
            <button
                class="btn btn-outline-primary"
                (click)="navigateToOOSList()">
                {{ 'common.cancel' | translate }}</button>
            <button
                *ngIf="canManageDepartment"
                type="button"
                class="btn btn-primary"
                (click)="onSaveButtonClick()">
                {{ 'common.save' | translate }}
            </button>
        </div>
    </div>
</div>

<app-sidebar-widget
    [handlerText]="'businessContinuity.ordersOfSuccession.title' | translate"
    [headerText]="'businessContinuity.ordersOfSuccession.title' | translate">
    <ng-template #sidebarTemplate>
        <div class="border-bottom mb-3 py-3">
            <label class="form-label">{{ 'common.title' | translate }}</label>
            <p>
                {{ 'businessContinuity.ordersOfSuccession.description' | translate }}
            </p>
        </div>
        <ng-container *ngIf="!isIndustryDefault">
            <div class="border-bottom mb-3 py-3">
                <label class="form-label">{{ 'common.primary' | translate }}</label>
                <p>
                    {{ 'businessContinuity.ordersOfSuccession.primaryDescription' | translate }}
                </p>
            </div>
            <div class="border-bottom mb-3 py-3">
                <label class="form-label">{{ 'common.alternates' | translate }}</label>
                <p>{{ 'businessContinuity.ordersOfSuccession.alternatesDescription' | translate }}</p>
            </div>
        </ng-container>
    </ng-template>
</app-sidebar-widget>
