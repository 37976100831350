import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { PortalProcessImpact } from '@app/shared/business-continuity/portal-process-impact.model';
import { PortalProcess } from '@app/shared/business-continuity/portal-process.model';
import { TranslateService } from '@ngx-translate/core';
import { guid } from '@app/app.constants';
import { BusinessContinuityService } from '@app/providers/business-continuity.service';
import { ImpactTimespan } from '@app/shared/business-continuity/impact-timespan.model';
import { ImpactSeverity } from '@app/shared/business-continuity/impact-severity.model';
import { ImpactCategory } from '@app/shared/business-continuity/impact-category.model';
import * as _ from 'lodash';
import annotationPlugin from 'chartjs-plugin-annotation';
import { Chart } from 'chart.js';

@Component({
	selector: 'app-bc-impact-analysis-chart',
	templateUrl: './bc-impact-analysis-chart.component.html',
	styleUrls: ['./bc-impact-analysis-chart.component.scss']
})
export class BcImpactAnalysisChartComponent implements OnInit, OnChanges {
	@Input() portalProcess: PortalProcess;
	@Input() activeImpactCategoryId: string;
	@Input() displayAllLines: boolean = false;
	@Input() displayLegend: boolean = true;
	@Input() legendPosition: string = 'right';
	@Input() originalRtoId: string = '';
	@Input() activeRtoId: string = '';
	@Input() showRtoLines: boolean = false;
	@Input() customerButtonText: string = '';
	@Input() bcImpactTimeSpans: Array<ImpactTimespan> = [];
	@Input() bcImpactSeverity: Array<ImpactSeverity> = [];
	@Input() bcImpactCategories: Array<ImpactCategory> = [];
	public activeImpactCategories: Array<ImpactCategory> = [];
	public rtoChartData: any = null;
	public rtoChartOptions: any;

	constructor(public translateService: TranslateService,
		private bcService: BusinessContinuityService) {
		Chart.register(annotationPlugin);
	}

	ngOnInit() {
		if (this.bcImpactSeverity == null || this.bcImpactSeverity.length === 0) {
			this.bcImpactSeverity = this.bcService.portalImpactSeverities;
		}
		if (this.bcImpactCategories == null || this.bcImpactCategories.length === 0) {
			this.bcImpactCategories = this.bcService.portalImpactCategories;
		}
		if (this.bcImpactTimeSpans == null || this.bcImpactTimeSpans.length === 0) {
			this.bcImpactTimeSpans = this.bcService.portalImpactTimeSpans;
		}

		if (this.portalProcess.portalProcessId) {
			this.initialize();
		}

		if (this.displayAllLines) {
			this.activeImpactCategories = _.cloneDeep(this.bcImpactCategories);
		}
	}

	// wipe out old lines
	// populate all the lines for the chart
	initialize() {
		const datasets: any = [];
		const annotations: Array<any> = [];

		this.bcImpactCategories.forEach((ic: ImpactCategory) => {
			if (this.isActiveImpactCategory(ic) || this.displayAllLines) {
				const data: Array<number> = [];

				this.bcImpactTimeSpans.forEach((its: ImpactTimespan) => {
					const processImpact: PortalProcessImpact = this.portalProcess.processImpacts.find((pi: PortalProcessImpact) => {
						return pi.impactCategoryId === ic.impactCategoryId && pi.impactTimespanId === its.impactTimespanId;
					});
					const value: number = this.bcImpactSeverity.findIndex(isv => isv.impactSeverityId === processImpact?.impactSeverityId);

					data.push(value);
				});

				const line = {
					label: this.getImpactCategoryName(ic.impactCategoryId),
					data: data,
					fill: false,
					borderColor: ic.categoryColor,
					tension: 0,
					borderWidth: 2
				};

				datasets.push(line);
			}
		});

		// inject into the chart to plot the lines
		this.rtoChartData = {
			labels: this.bcImpactTimeSpans.map((its: ImpactTimespan) => {
				return this.getRtoNameById(its.impactTimespanId);
			}),
			datasets: datasets
		};

		// options
		this.rtoChartOptions = {
			plugins: {
				legend: {
					display: false
				}
			},
			scales: {
				y: {
					min: 0,
					max: this.bcImpactSeverity.length - 1,
					beginAtZero: true,
					ticks: {
						stepSize: 1,
						callback: (value, index, values) => {
							return this.bcService.getImpactSeverityName(this.bcImpactSeverity[value].impactSeverityId);
						}
					},
					gridLines: {
						borderDash: [5, 5]
					}
				},
				x: {
					gridLines: {
						borderDash: [5, 5]
					}
				}
			},
			tooltips: false
		};

		// display the rto line
		// NOTE: uses annotationPlugin installed
		if (this.showRtoLines) {
			// display the active rto
			annotations.push(
				{
					drawTime: 'beforeDatasetsDraw',
					type: 'line',
					mode: 'vertical',
					scaleID: 'x-axis-0',
					xMin: this.bcImpactTimeSpans.findIndex(i => i.impactTimespanId === this.activeRtoId),
					xMax: this.bcImpactTimeSpans.findIndex(i => i.impactTimespanId === this.activeRtoId),
					value: this.getRtoNameById(this.activeRtoId),
					borderWidth: 2,
					borderColor: '#00205C', // primary
					label: {
						content: this.translateService.instant('businessContinuity.rto.rto'),
						display: true,
						position: 'end',
						yAdjust: -6
					},
					borderDash: [5, 5]
				}
			);

			// display the original rto, if they are not the same
			if (this.activeRtoId !== this.originalRtoId) {
				annotations.push(
					{
						drawTime: 'beforeDatasetsDraw',
						type: 'line',
						mode: 'vertical',
						scaleID: 'x-axis-0',
						xMin: this.bcImpactTimeSpans.findIndex(i => i.impactTimespanId === this.originalRtoId),
						xMax: this.bcImpactTimeSpans.findIndex(i => i.impactTimespanId === this.originalRtoId),
						value: this.getRtoNameById(this.originalRtoId),
						borderWidth: 2,
						borderColor: '#9E9E9E', // gray, 500
						borderDash: [5, 5]
					}
				);
			}

			this.rtoChartOptions.plugins.annotation = {
				annotations: annotations
			};
		}
	}

	ngOnChanges(changes: SimpleChanges) {
		// update the 'main' line
		if (changes.activeImpactCategoryId && !this.activeImpactCategories.includes(changes.activeImpactCategoryId.currentValue)) {
			this.activeImpactCategories = [changes.activeImpactCategoryId.currentValue];
		}
	}

	getRtoNameById(impactTimespanId: string): string {
		return this.bcService.getRTOTimespanName(impactTimespanId);
	}

	getImpactCategoryName(impactCategoryId: string) {
		return this.bcService.getImpactCategoryName(impactCategoryId);
	}

	getImpactCategoryDescription(impactCategoryId: string) {
		return this.bcService.getImpactCategoryDescription(impactCategoryId);
	}

	// check whether a severity selected
	severityIsSelected(impactCategoryId: string, impactTimespanId: string, impactSeverityId: string): boolean {
		const processImpact: PortalProcessImpact = this.portalProcess?.processImpacts?.find((x: PortalProcessImpact) => x.impactCategoryId === impactCategoryId && x.impactTimespanId === impactTimespanId);

		return processImpact?.impactSeverityId !== guid.empty ? processImpact?.impactSeverityId === impactSeverityId : processImpact?.templateImpactSeverityId === impactSeverityId;
	}

	isActiveImpactCategory(impactCategory: ImpactCategory): boolean {
		if (!this.activeImpactCategories?.length
		  || impactCategory.impactCategoryId === this.activeImpactCategoryId) {
			return true;
		}

		return this.activeImpactCategories.findIndex(ic => ic.impactCategoryId === impactCategory.impactCategoryId) > -1;
	}

	toggleImpactCategory(impactCategoryId: string) {
		const index: number = this.activeImpactCategories.findIndex(ic => ic.impactCategoryId === impactCategoryId);

		// DON'T turn off if it's the last one
		// OR if it's the main one that's selected
		if ((this.activeImpactCategories.length === 1 && index > -1) || impactCategoryId === this.activeImpactCategoryId) {
			return;
		}

		index > -1 ? this.activeImpactCategories.splice(index, 1) : this.activeImpactCategories.push(this.bcImpactCategories.find(ic => ic.impactCategoryId === impactCategoryId));

		this.initialize();
	}
}
