import { ModelBaseModel } from '@app/shared/base-model.model';

export class PlanTemplateAssignToTargetsModel extends ModelBaseModel {
	planTemplateId: string = '';
	planTemplateTargetTypeId: string = '';
	targetIds: string[] = [];
	makeDefault: boolean;

	constructor() {
		super();
	}
}
