<div class="modal-body">
    <form [formGroup]="technologyForm" (ngSubmit)="save()">
        <div class="mb-3">
            <div>
                <label class="form-label">{{'common.name' | translate}}</label>
                <input class="form-control"
                       type="text"
                       appAutofocus
                       id="technologyNameInput"
                       name="technologyName"
                       formControlName="technologyName"
                       placeholder="{{'businessContinuity.continuityStrategy.technologyModal.typeNamePlaceholder' | translate}}"
                       required/>
            </div>
        </div>

        <div class="pt-2">
            <label class="rpo-label me-2 form-label" >{{'common.rpo' | translate}}</label>
        </div>
        <div class="btn-toolbar justify-content-left mb-3">
            <div class="btn-group btn-toolbar" role="toolbar">
                <div class="input-group">
                    <input class="form-control rpo-input me-1"
                           type="number"
                           id="technologyRPOInput"
                           name="technologyRPOInput"
                           formControlName="rpoTime"
                           placeholder="0"
                           min="1"
                           [ngClass]="{'ng-touched ng-invalid': !rpoTimeValid}"
                    />
                </div>
                <p-dropdown formControlName="rpoTimeDurationUnitId"
                            [options]="rpoTypes"
                            appendTo="body"
                            id="technologyRPOTimeUnit"
                            appendTo="body"
                            dropdownIcon="fas fa-caret-down"></p-dropdown>
            </div>
        </div>
        <div class="mb-3">
            <label class="form-label">{{'common.type' | translate}}</label>
            <p-dropdown formControlName="technologyTypeId"
                        [options]="technologyTypes"
                        appendTo="body"
                        optionLabel="technologyTypeName"
                        optionValue="technologyTypeId"
                        id="technologyTypes"
                        styleClass="w-100"
                        dropdownIcon="fas fa-caret-down">
            </p-dropdown>


        </div>
        <div>
            <label class="form-label">{{'businessContinuity.continuityStrategy.technologyModal.tier' | translate}}</label>
            <input class="form-control rpo-input me-1"
                   type="number"
                   id="technologyTier"
                   formControlName="tier"
                   placeholder="0"
                   min="0"
            />
        </div>
    </form>
</div>
<div class="modal-footer text-end">
    <button id="saveButton" type="button" class="btn btn-primary businessContinuityNextButton"
            (click)="save()">{{(!technology.technologyId?.length ? 'common.create' : 'common.save') | translate}}</button>
</div>
<app-spinner name="technologyModal"></app-spinner>
