<div class="mb-3 bc-custom-field-values" *ngIf="customFieldValues.length > 0">
	<form #customFieldsForm="ngForm" novalidate>
		<div class="row">
			<ng-container *ngFor="let customFieldValue of customFieldValues;">
				<div class="col-4"
					 *ngIf="customFieldValue.portalCustomField.customFieldDataTypeId===customFieldDataTypes.ShortText">
					<div>
						<div class="mb-3">
							<label class="form-label"
								   for="{{customFieldValue.portalCustomField.fieldName}}">{{ customFieldValue.portalCustomField.fieldName }}</label>
							<input type="text" *ngIf="canEdit"
								   class="form-control"
								   id="{{customFieldValue.portalCustomField.fieldName}}"
								   [attr.aria-label]="customFieldValue.portalCustomField.fieldName"
								   name="{{customFieldValue.portalCustomField.fieldName}}"
								   maxlength="50"
								   [(ngModel)]="customFieldValue.fieldValue"
								   (ngModelChange)="onChanged(customFieldValue)"
								   [required]="customFieldValue.portalCustomField.isRequired && bypassRequiredField !== true">
							<p *ngIf="!canEdit">{{ customFieldValue.fieldValue }}</p>
						</div>
					</div>
				</div>
				<div class="col-12"
					 *ngIf="customFieldValue.portalCustomField.customFieldDataTypeId===customFieldDataTypes.LongText">
					<div>
						<div class="mb-3">
							<label class="form-label"
								   for="{{customFieldValue.portalCustomField.fieldName}}">{{ customFieldValue.portalCustomField.fieldName }}</label>
							<textarea rows="3" *ngIf="canEdit"
									  class="form-control"
									  id="{{customFieldValue.portalCustomField.fieldName}}"
									  [attr.aria-label]="customFieldValue.portalCustomField.fieldName"
									  name="{{customFieldValue.portalCustomField.fieldName}}"
									  maxlength="2000"
									  [(ngModel)]="customFieldValue.fieldValue"
									  (ngModelChange)="onChanged(customFieldValue)"
									  [required]="customFieldValue.portalCustomField.isRequired && bypassRequiredField !== true"></textarea>
							<p *ngIf="!canEdit">{{ customFieldValue.fieldValue }}</p>
						</div>
					</div>
				</div>
				<div class="col-4"
					 *ngIf="customFieldValue.portalCustomField.customFieldDataTypeId===customFieldDataTypes.TrueFalse">
					<div>
						<div class="mb-3">
							<label class="form-label"
								   for="{{customFieldValue.portalCustomField.fieldName}}">{{ customFieldValue.portalCustomField.fieldName }}</label>
							<p-inputSwitch class="d-block mt-1" *ngIf="canEdit"
										   id="{{customFieldValue.portalCustomField.fieldName}}"
										   [(ngModel)]="checkboxes[customFieldValue.customFieldValueId]"
										   (ngModelChange)="onChanged(customFieldValue)"
										   name="{{customFieldValue.portalCustomField.fieldName}}"
										   [required]="customFieldValue.portalCustomField.isRequired && bypassRequiredField !== true"
										   [attr.aria-label]="customFieldValue.portalCustomField.fieldName"></p-inputSwitch>
							<p *ngIf="!canEdit">{{ customFieldValue.fieldValue }}</p>
						</div>
					</div>
				</div>
				<div class="col-4"
					 *ngIf="customFieldValue.portalCustomField.customFieldDataTypeId===customFieldDataTypes.SelectOne">
					<div>
						<div class="mb-3">
							<label class="form-label"
								   for="{{customFieldValue.portalCustomField.fieldName}}">{{ customFieldValue.portalCustomField.fieldName }}</label>
							<select id="{{customFieldValue.portalCustomField.fieldName}}" *ngIf="canEdit"
									name="{{customFieldValue.portalCustomField.fieldName}}"
									class="form-select"
									[(ngModel)]="customFieldValue.fieldValue"
									(ngModelChange)="onChanged(customFieldValue)"
									[required]="customFieldValue.portalCustomField.isRequired && bypassRequiredField !== true"
									[attr.aria-label]="customFieldValue.portalCustomField.fieldName">
								<option value="">{{ 'common.select' | translate }}</option>
								<option *ngFor="let option of customFieldValue.portalCustomField.customFieldOptions"
										value="{{option.optionName}}">
									{{ option.optionDisplayName }}
								</option>
							</select>
							<p *ngIf="!canEdit">{{ customFieldValue.fieldValue }}</p>
						</div>
					</div>
				</div>
				<div class="col-4"
					 *ngIf="customFieldValue.portalCustomField.customFieldDataTypeId===customFieldDataTypes.SelectMany">
					<div>
						<div class="mb-3">
							<label class="form-label"
								   for="{{customFieldValue.portalCustomField.fieldName}}">{{ customFieldValue.portalCustomField.fieldName }}</label>
							<p-multiSelect *ngIf="canEdit" optionValue="optionName" optionLabel="optionDisplayName"
										   id="{{customFieldValue.portalCustomField.fieldName}}"
										   name="{{customFieldValue.portalCustomField.fieldName}}"
										   appendTo="body"
										   class="d-block"
										   [placeholder]="'common.select' | translate"
										   [filter]="false"
										   [options]="customFieldValue.portalCustomField.customFieldOptions"
										   [(ngModel)]="customFieldValue.fieldValues"
										   (ngModelChange)="onChanged(customFieldValue)"
										   [required]="customFieldValue.portalCustomField.isRequired && bypassRequiredField !== true"></p-multiSelect>
							<p *ngIf="!canEdit">{{ customFieldValue.fieldValue }}</p>
						</div>
					</div>
				</div>
				<div class="col-4"
					 *ngIf="customFieldValue.portalCustomField.customFieldDataTypeId===customFieldDataTypes.DateTime">
					<div>
						<div class="mb-3">
							<label class="form-label"
								   for="{{customFieldValue.portalCustomField.fieldName}}">{{ customFieldValue.portalCustomField.fieldName }}</label>
							<div *ngIf="canEdit">
								<p-calendar [(ngModel)]="customDatePickers[customFieldValue.customFieldValueId]"
											[showIcon]="true"
											inputId="{{customFieldValue.portalCustomField.fieldName}}"
											icon="fa fa-calendar"
											(ngModelChange)="onChanged(customFieldValue)"
											[required]="customFieldValue.portalCustomField.isRequired && bypassRequiredField !== true"
											[attr.aria-label]="customFieldValue.portalCustomField.fieldName"
											name="{{customFieldValue.portalCustomField.fieldName}}"/>
							</div>
							<p *ngIf="!canEdit">{{ customFieldValue.fieldValue }}</p>
						</div>
					</div>
				</div>
				<div class="col-4"
					 *ngIf="customFieldValue.portalCustomField.customFieldDataTypeId===customFieldDataTypes.Numeric">
					<div>
						<div class="mb-3">
							<label class="form-label"
								   for="{{customFieldValue.portalCustomField.fieldName}}">{{ customFieldValue.portalCustomField.fieldName }}</label>
							<input type="number" *ngIf="canEdit"
								   class="form-control"
								   id="{{customFieldValue.portalCustomField.fieldName}}"
								   [attr.aria-label]="customFieldValue.portalCustomField.fieldName"
								   name="{{customFieldValue.portalCustomField.fieldName}}"
								   maxlength="50"
								   [(ngModel)]="customFieldValue.fieldValue"
								   (ngModelChange)="onChanged(customFieldValue)"
								   [required]="customFieldValue.portalCustomField.isRequired && bypassRequiredField !== true">
							<p *ngIf="!canEdit">{{ customFieldValue.fieldValue }}</p>
						</div>
					</div>
				</div>
			</ng-container>
		</div>
	</form>
</div>
