<div class="modal-body position-relative">
<div class="px-3 businessContinuity-planner">
    <div class="row mb-4">
        <label class="form-label">{{'businessContinuity.teams.position' | translate}}</label>
        <div class="input-group">
            <input class="form-control" [(ngModel)]="position.position" placeholder="{{'businessContinuity.teams.positionPlaceholder' | translate}}"/>
        </div>
    </div>
    <div class="row mb-4">
        <label class="form-label">{{'businessContinuity.teams.responsibilities' | translate}}</label>
        <div class="input-group">
            <textarea class="form-control" [(ngModel)]="position.responsibilities"
                      rows="7" [maxLength]="4000" placeholder="{{'businessContinuity.teams.responsibilitiesPlaceholder' | translate}}"></textarea>
        </div>
    </div>

    <ng-container *ngIf="!isIndustryDefault">
    <div class="mb-0">
        <label class="form-label">{{searchUserLabel | translate}}</label>
        <div class="input-group">
            <div class="input-group-text">
                <i class="fa fa-search" aria-hidden="true"></i>
            </div>
            <input id="searchPhraseInput"
                   class="form-control"
                   type="text"
                   appSelectOnFocus
                   [(ngModel)]="searchPhrase"
                   (keyup)="onFilterChange()"
                   placeholder="{{'businessContinuity.searchUsers' | translate}}"/>
        </div>
        <div class="text-end">
            <button class="btn btn-link"
                    (click)="isAdvancedFilter = !isAdvancedFilter">
                {{(isAdvancedFilter ? 'common.showSimpleFilter' : 'common.showAdvancedFilter') | translate}}</button>
        </div>
    </div>
    <div class="row mb-3" *ngIf="isAdvancedFilter">
        <div class="col-md-3">
            <div class="mb-3">
                <label class="form-label" for="locationIds">{{'common.location' | translate}}</label>
                <p-multiSelect id="locationIds"
                               class="d-block"
                               [options]="locationOptions"
                               [maxSelectedLabels]="1"
                               [(ngModel)]="locationIds"
                               (ngModelChange)="onFilterChange()"
                               selectedItemsLabel="{{ 'iq.columnsSelected' | translate }}"
                               optionLabel="label"
                               styleClass=" ms-1 align-middle w-100 multiHeight"
                               placeholder="{{ 'choose' | translate }}"></p-multiSelect>
            </div>
        </div>
        <div class="col-md-3">
            <div class="mb-3">
                <label class="form-label" for="locationIds">{{'common.group' | translate}}</label>
                <p-multiSelect id="groupIds"
                               class="d-block"
                               [maxSelectedLabels]="0"
                               [options]="groupOptions"
                               [(ngModel)]="groupIds"
                               (ngModelChange)="onFilterChange()"
                               selectedItemsLabel="{{ 'iq.columnsSelected' | translate }}"
                               optionLabel="label"
                               styleClass=" ms-1 align-middle w-100 multiHeight"
                               placeholder="{{ 'choose' | translate }}"></p-multiSelect>
            </div>
        </div>
        <div class="col-md-3">
            <div class="mb-3">
                <label class="form-label" for="locationIds">{{'common.department' | translate}}</label>
                <p-multiSelect id="deptIds"
                               class="d-block"
                               [options]="deptOptions"
                               [maxSelectedLabels]="1"
                               [(ngModel)]="departmentIds"
                               (ngModelChange)="onFilterChange()"
                               selectedItemsLabel="{{ 'iq.columnsSelected' | translate }}"
                               optionLabel="label"
                               styleClass=" ms-1 align-middle w-100 multiHeight"
                               placeholder="{{ 'choose' | translate }}"></p-multiSelect>
            </div>
        </div>
        <div class="col-md-3">
            <div class="mb-3">
                <label class="form-label" for="roleIds">{{'common.role' | translate}}</label>
                <p-multiSelect id="roleIds"
                               class="d-block"
                               [options]="roleBundleOptions"
                               [maxSelectedLabels]="1"
                               [(ngModel)]="roleBundleIds"
                               (ngModelChange)="onFilterChange()"
                               selectedItemsLabel="{{ 'iq.columnsSelected' | translate }}"
                               optionLabel="label"
                               styleClass=" ms-1 align-middle w-100 multiHeight"
                               placeholder="{{ 'choose' | translate }}"></p-multiSelect>
            </div>
        </div>
    </div>
    <ng-container *ngIf="selectedUsers?.length > 0">
        <div class="container-fluid p-1 users">
            <div class="row mb-3">
                <div class="col usersLabel">
                    {{currentUserLabel | translate}}
                </div>
            </div>
            <div class="row" *ngIf="!loading">
                <div class="col-md-2" *ngFor="let currentUser of selectedUsers;">
                    <button class="btn btn-link user selected readOnly position-relative">
                        <button
                                class="btn btn-link text-light removeIcon pe-auto" (click)="onToggleUser(currentUser)">
                            <i class="fa fa-times"
                               aria-hidden="true"></i>

                        </button>
                        <div class="name text-truncate">{{currentUser?.firstName}} {{currentUser?.lastName}}</div>
                        <div class="work-title my-1 text-truncate"
                             pTooltip="{{currentUser?.title}}">{{currentUser?.title || specialCharacters.doubleDash}}</div>
                        <div
                            class="location d-block text-truncate">{{currentUser?.locationEntityName || currentUser?.primaryLocation}}</div>
                    </button>
                </div>
            </div>
        </div>
    </ng-container>
    <div class="mb-3 d-flex align-items-center usersLabel">
        {{resultTitle | translate}}
        <p class="mx-3 my-0"
           *ngIf="limitedResult">{{'businessContinuity.buildYourTeam.msgLimitedResult' | translate}}</p>
    </div>
    <div class="container-fluid" *ngIf="!loading">
        <div class="row users scroll">
            <div class="col-3" *ngFor="let user of userList"
                 [pTooltip]="user.isMessageOnly && !includesMessageOnly ? ('businessContinuity.buildYourTeam.noPermission' | translate) : null"
                 triggers="hover focusin:focusout"
                 [ngClass]="{'messageOnly': user.isMessageOnly && !includesMessageOnly}">
                <button class="btn btn-link user"
                        [disabled]="user.isMessageOnly && !includesMessageOnly || (maxSelectCount && (selectedUsers.length >= maxSelectCount))"
                        [ngClass]="{'selected': isSelected(user), 'messageOnly': user.isMessageOnly  && !includesMessageOnly}"
                        (click)="onToggleUser(user)">
                    <div class="name text-truncate">{{user.firstName}} {{user.lastName}}</div>
                    <div
                        class="work-title my-1 text-truncate">{{user.title || specialCharacters.doubleDash}}</div>
                    <div class="location d-block text-truncate">{{user.locationEntityName}}</div>
                </button>
            </div>
        </div>
    </div>
    <app-spinner></app-spinner>
    </ng-container>
</div>
<div class="modal-footer text-end">
    <button id="selectButton" type="button" class="btn btn-primary businessContinuityNextButton"
            [disabled]="!position.position"
            (click)="select()">{{assignButton}}</button>
</div>
</div>
