<ng-container *ngIf="rtoChartData">
    <p-chart type="line" [data]="rtoChartData" [options]="rtoChartOptions"></p-chart>
</ng-container>

<div [ngClass]="{'toggles': legendPosition === 'right', 'togglesBottom': legendPosition === 'bottom'}"
     *ngIf="displayLegend">
    <button *ngFor="let category of bcImpactCategories" [id]="'toggleImpactCategoryButton' + category.impactCategoryId" class="btn btn-link"
            (click)="toggleImpactCategory(category.impactCategoryId)"
            pTooltip="{{getImpactCategoryName(category.impactCategoryId)}}"
            tooltipPosition="left">
        <i class="fas" [style]="{'color': category.categoryColor}"
           [ngClass]="{'fa-check-square': isActiveImpactCategory(category), 'fa-square': !isActiveImpactCategory(category)}"></i>
    </button>
</div>
