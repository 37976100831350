<div class="mb-3">
    <label class="form-label">{{ 'businessContinuity.bcIssue.lblTitle' | translate }}</label>
    <input id="titleInput" type="text"
           class="form-control"
           maxlength="100"
           required
           [(ngModel)]="issue.title"/>
</div>
<div class="mb-3">
    <label class="form-label">{{ 'businessContinuity.bcIssue.lblDescription' | translate }}</label>
    <input id="descriptionInput" type="text"
           class="form-control"
           maxlength="4000"
           [(ngModel)]="issue.description"/>
</div>

<hr/>

<div class="row" *ngIf="enableRevisionItemsParametersSelection">
    <ng-container [ngSwitch]="planTypeId">
        <ng-container *ngSwitchCase="planTemplateTypeIds.disasterRecovery">
            <div class="col-4 mb-3">
                <label class="form-label">{{'common.technology' | translate}}</label>
                <select id="issueTechnologySelect"
                        name="issueTechnology"
                        class="form-select"
                        [(ngModel)]="issueTechnologyId">
                    <option disabled selected value="">{{'select' | translate}}</option>
                    <option *ngFor="let technology of technologies" value="{{technology.id}}">{{technology.name}}</option>
                </select>
            </div>
        </ng-container>
        <ng-container *ngSwitchCase="planTemplateTypeIds.emergencyOperations">
            <div class="col-4 mb-3">
                <label class="form-label">{{'common.annex' | translate}}</label>
                <select id="issueAnnexSelect"
                        name="issueAnnex"
                        class="form-select"
                        [(ngModel)]="issueAnnexId"
                        required>
                    <option disabled selected value="">{{'select' | translate}}</option>
                    <option *ngFor="let annex of annexes" value="{{annex.id}}">{{annex.name}}</option>
                </select>
            </div>
        </ng-container>
        <ng-container *ngSwitchDefault>
            <div class="col-4 mb-3">
                <label class="form-label">{{'businessContinuity.bcIssue.lblFunctionalArea' | translate}}</label>
                <select id="issueFunctionalAreaSelect"
                        name="issueFunctionalArea"
                        class="form-select"
                        [(ngModel)]="issueFunctionalAreaId"
                        (ngModelChange)="onFunctionalAreaSelectionChanged()">
                    <option disabled selected value="">{{'select' | translate}}</option>
                    <option *ngFor="let fa of bcFunctionalAreas" value="{{fa.id}}">{{fa.name}}</option>
                </select>
            </div>
            <div class="col-4 mb-3">
                <label class="form-label">{{'businessContinuity.bcIssue.lblProcess' | translate}}</label>
                <select id="issueProcessSelect"
                        name="issueProcess"
                        class="form-select"
                        [(ngModel)]="issueProcessId">
                    <option value="">{{''}}</option>
                    <option *ngFor="let process of bcProcesses" value="{{process.id}}">{{process.name}}</option>
                </select>
            </div>
        </ng-container>
    </ng-container>
</div>
<div class="row" *ngIf="!enableRevisionItemsParametersSelection">
    <ng-container [ngSwitch]="planTypeId">
        <ng-container *ngSwitchCase="planTemplateTypeIds.disasterRecovery">
            <div class="col-4 mb-3">
                <label class="form-label">{{'common.technology' | translate}}</label>
                <div>{{issue.portalPlanTechnologyName || ('common.lblNA' | translate)}}</div>
            </div>
        </ng-container>
        <ng-container *ngSwitchCase="planTemplateTypeIds.emergencyOperations">
            <div class="col-4 mb-3">
                <label class="form-label">{{'common.annex' | translate}}</label>
                <div>{{issue.portalAnnexName || ('common.lblNA' | translate)}}</div>
            </div>
        </ng-container>
        <ng-container  *ngSwitchDefault>
            <div class="col-4 mb-3">
                <label class="form-label">{{'businessContinuity.bcIssue.lblFunctionalArea' | translate}}</label>
                <div>{{issue.portalFunctionalAreaName || ('common.lblNA' | translate)}}</div>
            </div>
            <div class="col-4 mb-3">
                <label class="form-label">{{'businessContinuity.bcIssue.lblProcess' | translate}}</label>
                <div>{{issue.portalProcessName || ('common.lblNA' | translate)}}</div>
            </div>
        </ng-container>

    </ng-container>
    <div class="col-4 mb-3">
        <label class="form-label">{{'businessContinuity.bcIssue.lblRevisionItemType' | translate}}</label>
        <div>{{ (issue.revisionItemTypeId) ? (('businessContinuity.bcRevisionItemTypes.' + issue.revisionItemTypeId) | translate) : ('common.lblNA' | translate)}}</div>
    </div>
</div>

<hr/>

<div class="row">
    <div class="col-4">
        <div class="mb-3">
            <label class="form-label">{{ 'businessContinuity.bcIssue.lblIssueOwner' | translate }}</label>
            <div>
                <label class="form-label">{{issue.ownerUserName}}</label>
            </div>
        </div>
    </div>
    <div class="col-4">
        <div class="mb-3">
            <label class="form-label">{{ 'businessContinuity.bcIssue.lblIssueStatus' | translate }}</label>
            <select id="issueStatus"
                    class="form-select"
                    name="issueStatus"
                    [disabled]="String.isNullOrEmpty(issue.issueId)"
                    [(ngModel)]="issue.issueStateTypeId">
                <option *ngFor="let state of bcIssueStateTypesList" value="{{state.id}}">{{state.name}}</option>
            </select>
        </div>
    </div>
    <div class="col-4">
        <div class="mb-3">
            <label class="form-label">{{'businessContinuity.bcIssue.lblManagementAttention' | translate}}</label>
            <p-inputSwitch inputId="needsManagementAttention"
                           class="d-block"
                           [(ngModel)]="issue.needsManagementAttention"></p-inputSwitch>
        </div>
    </div>
</div>

<ng-container *ngIf="issue && issue.issueId">
    <hr/>
    <div class="mb-3">
        <label class="form-label">{{ 'businessContinuity.bcIssue.lblComments' | translate }}</label>
        <div *ngFor="let comment of issueComments">
            <dt>
                {{comment.createdByName}} {{'businessContinuity.bcIssue.commentOn' | translate}} <span
                *ngIf="comment">{{comment.dateTimeCreated | date: dateFormat.short}}</span>
                <a class="mx-3"
                   (click)="onDeleteIssueComment(comment)"
                   *ngIf="canDelete(comment)">
                    <i class="fa fa-trash colorRed500"></i>
                </a>
            </dt>
            <p [innerHTML]="comment.comment | sanitized"></p>
        </div>
        <div *ngIf="!addingNewComment">
            <button id="newCommentButton"
                    type="button"
                    class="btn btn-primary mt-3"
                    [disabled]="isSaving"
                    (click)="onNewComment()">{{'businessContinuity.bcIssue.newComment' | translate}}
            </button>
        </div>
        <div *ngIf="addingNewComment">
            <div class="mb-3">
                <label class="form-label">{{'businessContinuity.bcIssue.newComment' | translate}}</label>

                <editor #tinymce
                        id="editorContent"
                        [apiKey]="tinyApiKey"
                        [init]="tinymceConfig"
                        [(ngModel)]="editorContent"></editor>

                <button id="addCommentButton"
                        type="button"
                        class="btn btn-primary mt-3"
                        [disabled]="isSaving"
                        (click)="addComment()">{{'businessContinuity.bcIssue.addComment' | translate}}
                </button>
                <button id="cancelCommentButton"
                        type="button"
                        class="btn btn-secondary mt-3"
                        [disabled]="isSaving"
                        (click)="onCancelNewComment()">{{'common.cancel' | translate}}
                </button>
            </div>
        </div>
    </div>
</ng-container>

<div class="modal-footer">
    <button id="saveIssueButton" class="btn btn-primary mt-3"
            [disabled]="!canSaveIssue"
            (click)="saveIssue()">{{'common.save' | translate}}</button>
    <button id="onCancelIssueButton" class="btn btn-secondary mt-3"
            [disabled]="isSaving"
            (click)="onCancelIssue()">{{'common.cancel' | translate}}</button>
</div>

