import { Component, Input, OnInit } from '@angular/core';
import { EquipmentType } from '@app/shared/business-continuity/equipment-type.model';
import { Utility } from '@app/providers/utility';
import { ModalReturnType } from '@app/app.enum';
import { TranslateService } from '@ngx-translate/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
	selector: 'app-bc-equipment-type-modal',
	templateUrl: './bc-equipment-type-modal.component.html',
	styleUrls: ['./bc-equipment-type-modal.component.scss']
})
export class BcEquipmentTypeModalComponent {
	@Input() equipmentType: EquipmentType;
	String = String;
	submitted: boolean = false;

	constructor(private utility: Utility,
		public dynamicDialogRef: DynamicDialogRef,
		public dynamicDialogConfig: DynamicDialogConfig,
		private translateService: TranslateService) {
		this.equipmentType = this.dynamicDialogConfig.data.equipmentType;
		this.dynamicDialogConfig.header = this.translateService.instant('businessContinuity.continuityStrategy.equipmentTypeModal.title');
	}

	dismiss() {
		this.dynamicDialogRef.close(ModalReturnType.cancel);
	}

	save() {
		this.submitted = true;
		if (String.isNullOrEmpty(this.equipmentType.equipmentTypeName)) {
			return;
		}
		this.equipmentType.equipmentTypeId = this.equipmentType.equipmentTypeId || this.utility.getGuid();
		this.dynamicDialogRef.close(ModalReturnType.save);
	}
}
