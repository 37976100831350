export class ModelPlanTemplateTarget {
	targetId: string;
	targetNameAsBase64: string = '';
	targetName: string = '';
	templateTargetUserNameAsBase64: string = '';
	templateTargetUserName: string = '';
	templateTargetFirstNameAsBase64: string = '';
	templateTargetFirstName: string = '';
	templateTargetLastNameAsBase64: string = '';
	templateTargetLastName: string = '';

	constructor() {
	}
}
