import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { User } from '@app/shared/user.model';
import { defaultImageAddress, dialogueWidths, specialCharacters, userStateTypes } from '@app/app.constants';
import { BcSelectUserModalComponent } from '@app/components/business-continuity/modals/bc-select-user/bc-select-user-modal.component';
import { Utility } from '@app/providers/utility';
import { UserService } from '@app/providers/user.service';
import { BusinessContinuityService } from '@app/providers/business-continuity.service';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
	selector: 'app-bc-process-assignees',
	templateUrl: './bc-process-assignees.component.html',
	styleUrls: ['./bc-process-assignees.component.scss']
})
export class BcProcessAssigneesComponent implements OnInit {
	@Input() functionalAreaName: string;
	@Input() assignees: Array<User>;
	@Input() descriptionResource: string = 'businessContinuity.continuityStrategy.responsibleEmployeesDesc';
	@Input() invalid: boolean = false;
	@Input() canManage: boolean = false;
	@Output() addUserEvent: EventEmitter<Array<User>> = new EventEmitter<Array<User>>();
	@Output() removeUserEvent: EventEmitter<Array<User>> = new EventEmitter<Array<User>>();
	defaultImageAddress = defaultImageAddress;
	userStateTypes = userStateTypes;
	public specialCharacters = specialCharacters;

	constructor(private utility: Utility,
		private userService: UserService,
		private dialogService: DialogService) {
	}

	ngOnInit(): void {
		this.assignees.map((a: User) => {
			if (String.isNullOrEmpty(a.profileURL)) {
				a.profileURL = defaultImageAddress.profile;
			}
			if (a.entities) {
				a.locationEntityId = a.entities[0].entityId;
				a.locationEntityName = a.entities[0].entityName;
			}
		});
	}

	openAssigneeModal() {
		const ngbModalRef: DynamicDialogRef = this.dialogService.open(BcSelectUserModalComponent, {
			width: dialogueWidths.xlg,
			styleClass: 'businessContinuity-planner',
			data: {
				currentUsers: this.assignees,
				baseResourcePath: 'businessContinuity.continuityStrategy.assigneeModal',
				searchPhrase: this.functionalAreaName,
				includesMessageOnly: true,
				hasMultipleSelections: true
			}
		});

		ngbModalRef.onClose.subscribe((incomingUsers: Array<User>) => {
			const removedUsers: Array<User> = this.assignees.filter(u => incomingUsers.findIndex((iu: User) => iu.userId === u.userId) === -1);
			if (removedUsers && removedUsers.length > 0) {
				this.removeUsers(this.assignees.filter(u => incomingUsers.findIndex((iu: User) => iu.userId === u.userId) === -1));
			}
			const addUsers: Array<User> = incomingUsers.filter(u => this.assignees.findIndex((a: User) => a.userId === u.userId) === -1);
			if (addUsers && addUsers.length > 0) {
				this.addUsers(addUsers);
			}
		});
	}

	removeUsers(users: Array<User>) {
		this.removeUserEvent.emit(users);
	}

	addUsers(users: Array<User>) {
		const promises = [];
		users.forEach((u) => {
			promises.push(this.userService.getUserProfile(u.userId).then((res: any) => {
				u.profileURL = !String.isNullOrEmpty(res.profileFull.profile.profileURL) ? res.profileFull.profile.profileURL : defaultImageAddress.profile;
			}));
		});
		Promise.all(promises).then((_) => {
			this.addUserEvent.emit(users);
		});
	}

	confirmRemoveAssignee(user: User) {
		this.utility.confirmAndExecute('businessContinuity.continuityStrategy.confirmRemoveAssignee', 'btn-danger', this, this.removeUsers, {}, [user]);
	}
}
