<div class="row mt-5" *ngIf="!isEditing;else editing">
    <div *ngIf="canManageDepartment" class="col-xxl-4 col-lg-6 col-sm-12 customCol mb-4 text-center">
        <div class="customCard bcCard newBcCard" (click)="addNewDelegation()">
            <button class="btn btn-link btn-block" id="createOOSButton"
                    type="button">
                <i class="fa fa-plus"></i>
                <div class="d-block fw-bold">{{'businessContinuity.authorityDelegation.addDelegated' | translate}}</div>
            </button>
        </div>
    </div>
    <ng-container *ngFor="let authority of authorities">
        <div class="col-xxl-4 col-lg-6 col-sm-12 customCol mb-4">
                    <app-base-card [options]="getMenu(authority)"
                               [header]="authority.portalDelegationOfAuthorityName"
                               (headerOnClickEvent)="editDelegation(authority.portalDelegationOfAuthorityId)"
                               [lastUpdated]="authority.dateTimeLastModified ? authority.dateTimeLastModified : authority.dateTimeCreated"
                               [lastUpdateBy]="authority.lastModifiedByName ? authority.lastModifiedByName : authority.createdByName"
                               [height]="250"
                               [headerIsClickable]="true"
                               [mark]="false"
                               class="bcCard">
                        <ng-template #content>
                            <div class="justify-content-between  my-2">
                                <div *ngIf="isIndustryDefault" class="minHeight mb-2"></div>
                                <ng-container *ngIf="!isIndustryDefault">
                                    <div class="mb-2 fw-semibold chipContainer">
                                        <h6 class="mb-2">{{'businessContinuity.authorityDelegation.actingAgents' | translate}}</h6>
                                        <ng-container *ngIf="canManageDepartment">
                                            <button (click)="editDelegation(authority.portalDelegationOfAuthorityId)"
                                                    [ngClass]="{'hasNoDelegation': getAgents(authority,true).length === 0}"
                                                    class="btn assignTeamLeadButton m-0"
                                                    id="{{authority.portalDelegationOfAuthorityId + '-actingAgents'}}"
                                                    type="button">
                                                <div *ngIf="getAgents(authority,true).length === 0" class="primaryCardInfo">
                                                    <i class="fa-light fa-user-plus me-1"></i>{{'businessContinuity.authorityDelegation.assignActingAgent' | translate}}
                                                </div>
                                                <div *ngIf="getAgents(authority,true).length" class="primaryCardInfo">
                                                    {{getChipInfo(getAgents(authority, true)[0])}}
                                                </div>
                                            </button>
                                            <button (click)="editDelegation(authority.portalDelegationOfAuthorityId)"
                                                    *ngIf="getAgents(authority,true).length > 1"
                                                    class="btn assignTeamLeadButton m-0"
                                                    id="{{authority.portalDelegationOfAuthorityId + '-moreActingAgents'}}">
                                                <div class="primaryCardInfo">
                                                    {{'+' + (getAgents(authority, true).length - 1)}}
                                                </div>
                                            </button>
                                        </ng-container>
                                        <ng-container *ngIf="!canManageDepartment">
                                            <div class="assignTeamLeadButton m-0">
                                                <div  class="primaryCardInfo" *ngIf="getAgents(authority,true)?.length === 0">
                                                    {{'businessContinuity.authorityDelegation.noActingAgent' | translate}}
                                                </div>
                                                <div *ngIf="getAgents(authority,true).length" class="primaryCardInfo">
                                                    {{getChipInfo(getAgents(authority, true)[0])}}
                                                </div>
                                            </div>
                                            <div class="assignTeamLeadButton m-0"
                                                 *ngIf="getAgents(authority,true).length > 1"
                                                 class="btn assignTeamLeadButton m-0"
                                                 id="{{authority.portalDelegationOfAuthorityId + '-moreActingAgents'}}">
                                                <div class="primaryCardInfo">
                                                    {{'+' + (getAgents(authority, true).length - 1)}}
                                                </div>
                                            </div>
                                        </ng-container>
                                    </div>
                                    <div class="mb-2 fw-semibold chipContainer">
                                        <h6 class="mb-2">{{'businessContinuity.authorityDelegation.delegatedAgents' | translate}}</h6>
                                        <ng-container *ngIf="canManageDepartment">
                                            <button (click)="editDelegation(authority.portalDelegationOfAuthorityId)"
                                                    [ngClass]="{'hasNoDelegation': getAgents(authority).length === 0}"
                                                    class="btn assignTeamLeadButton m-0"
                                                    id="{{authority.portalDelegationOfAuthorityId + '-delegatedAgents'}}"
                                                    type="button">
                                                <div *ngIf="getAgents(authority).length === 0"
                                                     class="primaryCardInfo"><i class="fa-light fa-user-plus me-1"></i>{{'businessContinuity.authorityDelegation.assignDelegatedAgent' | translate}}</div>
                                                <div *ngIf="getAgents(authority).length" class="primaryCardInfo">
                                                    {{getChipInfo(getAgents(authority)[0])}}
                                                </div>
                                            </button>
                                            <button (click)="editDelegation(authority.portalDelegationOfAuthorityId)"
                                                    *ngIf="getAgents(authority).length > 1"
                                                    class="btn assignTeamLeadButton m-0"
                                                    id="{{authority.portalDelegationOfAuthorityId + '-moreActingAgents'}}">
                                                <div class="primaryCardInfo">
                                                    {{'+' + (getAgents(authority).length - 1)}}
                                                </div>
                                            </button>
                                        </ng-container>
                                        <ng-container *ngIf="!canManageDepartment">
                                            <div class="assignTeamLeadButton m-0">
                                                <div *ngIf="getAgents(authority).length === 0" class="primaryCardInfo">
                                                    {{'businessContinuity.authorityDelegation.noDelegatedAgent' | translate}}
                                                </div>
                                                <div *ngIf="getAgents(authority).length" class="primaryCardInfo">
                                                    {{getChipInfo(getAgents(authority)[0])}}
                                                </div>
                                            </div>
                                            <div class="assignTeamLeadButton m-0" *ngIf="getAgents(authority).length > 1">
                                                <div class="primaryCardInfo">
                                                    {{'+' + (getAgents(authority).length - 1)}}
                                                </div>
                                            </div>
                                        </ng-container>
                                    </div>
                                </ng-container>
                            </div>
                        </ng-template>
                    </app-base-card>
        </div>
    </ng-container>
</div>
<ng-template #editing>
    <app-bc-authority-delegation
        [canManage]="canManageDepartment"
        [portalDelegationId]="selectedDelegationId"
        [isIndustryDefault]="isIndustryDefault"
        [industryId]="this.industryId"
        (back)="childResponse($event)"
    ></app-bc-authority-delegation>
</ng-template>
<app-spinner [name]="'list-spinner'"></app-spinner>

