<div class="modal-body position-relative">
    <div class="row mb-4" appApplyPermission
    [portal-features]="featureTypes.assignTeamToDepartments"
    [portal-planTypeId]="portalPlanTypeId">
        <div [hidden]="availableDepartments.length < 1" class="d-flex align-items-start" *ngIf="loaded">
            <p-inputSwitch class="me-2" [(ngModel)]="team.assignToAllDepartments" (onChange)="selectAll($event.checked)" [disabled]="readonlyDefault"></p-inputSwitch>
            <div class="pt-1 ps-2">
                <p class="d-inline">{{'businessContinuity.teams.associateModal.switchDes' |
                    translate: {entityType : getEntityName()} }}</p>
            </div>
        </div>
    </div>
    <div *ngIf="selectedDepartments.length > 0 && !team.assignToAllDepartments">
        <div class="row">
            <div class="row">
                <div class="col">
                    <p class="fw-bold">
                        {{'businessContinuity.planTemplates.selectedDepartments' | translate}}
                    </p>
                </div>
            </div>
            <div class="container-fluid" *ngIf="loaded">
                <div class="row users scroll">
                    <div class="col-3" *ngFor="let target of selectedDepartments">
                        <button class="btn btn-link user selected" (click)="handleTargetSelect(target)">
                            <div class="name text-truncate">
                                {{target.portalFunctionalAreaName}}
                            </div>
                            <div class="work-title my-1 text-truncate">
                                {{target.leader.firstName}}
                                {{target.leader.lastName || specialCharacters.doubleDash}}
                            </div>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row" *ngIf="loaded && availableDepartments.length > 0">
        <div class="row">
            <div class="col">
                <p class="fw-bold" *ngIf="!team.assignToAllDepartments">
                    {{'businessContinuity.planTemplates.availableDepartments' | translate}}
                </p>
                <p class="fw-bold" *ngIf="team.assignToAllDepartments">
                    {{'businessContinuity.planTemplates.selectedDepartments' | translate}}
                </p>
            </div>
        </div>
        <div class="container-fluid">
            <div class="row users scroll">
                <div class="col-3" *ngFor="let target of availableDepartments">
                    <button [disabled]="team.assignToAllDepartments" class="btn btn-link user" (click)="handleTargetSelect(target)">
                        <div class="name text-truncate">
                            {{target.portalFunctionalAreaName}}
                        </div>
                        <div class="work-title my-1 text-truncate">
                            {{target.leader.firstName}}
                            {{target.leader.lastName || specialCharacters.doubleDash}}
                        </div>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal-footer d-flex gap-2 text-end">
    <button type="button" class="btn btn-outline-secondary" (click)="cancel()">
        {{'common.cancel' | translate}}
    </button>
    <button type="button" class="btn btn-primary" (click)="saveAssignedDepartments()">
        {{'common.assign' | translate}}
    </button>
</div>
<app-spinner name="templateTargetsModal"></app-spinner>
