import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { bcIssueStateTypes, bcRevisionItemTypes, dateFormat, planTemplateTypeIds } from '@app/app.constants';
import { Utility } from '@app/providers/utility';
import { TranslateService } from '@ngx-translate/core';
import { BusinessContinuityService } from '@app/providers/business-continuity.service';
import { PortalFunctionalArea } from '@app/shared/business-continuity/portal-functional-area.model';
import { BCIssue } from '@app/shared/business-continuity/bc-issue.model';
import { BCIssueComment } from '@app/shared/business-continuity/bc-issue-comment.model';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import moment from 'moment';
import { environment } from '@env/environment';
import { TinymceConfig } from '@app/shared/tinymce/tinymce-config.model';
import { PortalAnnex } from '@app/shared/business-continuity/annexes/bc-annex.model';
import { TechnologyRequestFilter } from '@app/shared/business-continuity/technology.model';
import { PlanTechnology } from '@app/shared/business-continuity/technologies/bc-plan-technology';

@Component({
	selector: 'app-bc-issue',
	templateUrl: './bc-issue.component.html',
	styleUrls: ['./bc-issue.component.scss']
})
export class BcIssueComponent implements OnInit, OnDestroy {
	@Input() requireFunctionalAreaId: boolean = true;
	@Input() planTypeId: string;
	@Output() issueSaved: EventEmitter<BCIssue> = new EventEmitter<BCIssue>();
	@Output() issueCancelled: EventEmitter<any> = new EventEmitter<any>();
	issue: BCIssue = new BCIssue();
	issueComments: Array<BCIssueComment> = [];
	currentUserId: string;
	portalFunctionalAreaId: string = null;
	portalProcessId: string = null;
	portalAnnexId: string = null;
	portalFunctionalAreas: Array<PortalFunctionalArea>;
	annexes: any[];
	technologies: any[];
	bcIssueStateTypesList: Array<{ name: string, id: string }>;

	editorContent: string = '';
	addingNewComment: boolean = false;

	onIssueSelectedSubscription: Subscription;
	String = String;
	public planTemplateTypeIds = planTemplateTypeIds;

	isSaving: boolean;

	// #region new issue from list
	enableRevisionItemsParametersSelection: boolean;
	issueFunctionalAreaId: string = '';
	issueAnnexId: string = '';
	issueTechnologyId: string = '';
	issueProcessId: string = null;
	bcRevisionItemTypes: Array<any>;
	bcFunctionalAreas: Array<any>;
	bcProcesses: Array<any>;

	// #endregion
	public tinyApiKey: string = environment.tinyApiKey;
	public tinymceConfig: any = new TinymceConfig();

	constructor(public bcService: BusinessContinuityService,
		private utility: Utility,
		private translate: TranslateService,
		private toastr: ToastrService,
		private changeDetectorRef: ChangeDetectorRef) {
		this.onIssueSelectedSubscription = bcService.onIssueSelected().subscribe((issue: BCIssue) => {
			this.issue = Object.assign(new BCIssue(), issue);
			// if new issue from manage issues page - allow user to select page, department, process
			if (!this.issue.issueId
			  && !this.issue.revisionItemTypeId) {
				this.enableRevisionItemsParametersSelection = true;
			}
			else {
				this.issueFunctionalAreaId = this.issue.portalFunctionalAreaId;
				this.issueProcessId = this.issue.portalProcessId;
				this.issueAnnexId = this.issue.portalAnnexId;
				this.issueTechnologyId = this.issue.portalPlanTechnologyId;
			}
		});

		this.bcIssueStateTypesList = [
			{
				name: translate.instant(`businessContinuity.bcIssue.bcIssueStateType_${bcIssueStateTypes.open}`),
				id: bcIssueStateTypes.open
			},
			{
				name: translate.instant(`businessContinuity.bcIssue.bcIssueStateType_${bcIssueStateTypes.closed}`),
				id: bcIssueStateTypes.closed
			}];
	}

	get isBusinessContinuityManager(): boolean {
		return this.bcService.isBusinessContinuityManager;
	}

	get canSaveIssue(): boolean {
		let canSave: boolean = !String.isNullOrEmpty(this.issue.title) && !this.isSaving;
		if (canSave && this.requireFunctionalAreaId) {
			canSave = (!String.isNullOrEmpty(this.issueFunctionalAreaId) || !String.isNullOrEmpty(this.issue.portalFunctionalAreaId));
		}
		return canSave;
	}

	ngOnInit() {
		this.currentUserId = this.utility.getUserId();
		this.getIssueComments()
			.then((res: any) => {
				this.issueComments = res;
			})
			.catch((_) => {
				this.toastr.error(this.translate.instant('businessContinuity.bcIssue.getIssueCommentsErrorMsg'));
			})
			.then((_) => {
			});
		if (this.enableRevisionItemsParametersSelection) {
			this.populateRevisionItems();
		}

		if (this.enableRevisionItemsParametersSelection
		  || !this.issue.portalFunctionalAreaName
		  || !this.issue.portalAnnexName
		  || (this.issue.portalProcessId
		    && !this.issue.portalProcessName)) {
			this.planTypeId == this.planTemplateTypeIds.emergencyOperations
				? this.populateAnnexes()
				: this.planTypeId == this.planTemplateTypeIds.disasterRecovery ? this.populateTechnologies() : this.populateFunctionalAreas();
			// this.planTypeId == this.planTemplateTypeIds.emergencyOperations ? this.populateAnnexes() : this.populateFunctionalAreas();
		}
	}

	ngOnDestroy() {
		if (this.onIssueSelectedSubscription) {
			this.onIssueSelectedSubscription.unsubscribe();
		}
	}

	getIssueComments() {
		return this.bcService.getBCIssueComments(this.issue.issueId);
	}

	populateRevisionItems() {
		this.bcRevisionItemTypes = new Array<any>();
		Object.keys(bcRevisionItemTypes).forEach((key) => {
			if (key === 'none'
			  || bcRevisionItemTypes[key] === ''
			  || bcRevisionItemTypes[key] === bcRevisionItemTypes.impactBrand
			  || bcRevisionItemTypes[key] === bcRevisionItemTypes.impactClient
			  || bcRevisionItemTypes[key] === bcRevisionItemTypes.impactCompliance
			  || bcRevisionItemTypes[key] === bcRevisionItemTypes.impactFinancial
			  || bcRevisionItemTypes[key] === bcRevisionItemTypes.impactEmployee
			  || bcRevisionItemTypes[key] === bcRevisionItemTypes.impactServices) {
				return;
			}
			this.bcRevisionItemTypes.push({ name: key, id: bcRevisionItemTypes[key] });
		});
	}

	populateFunctionalAreas() {
		const filterFAsForUser: boolean = !this.isBusinessContinuityManager;

		this.bcService.getPortalFunctionalAreas(this.issue.siteId, null, true, false)
			.then((res: any) => {
				this.bcFunctionalAreas = new Array<any>();
				const currentUserId = this.utility.getUserId();
				for (const pfa of res.portalFunctionalAreas) {
					const isTL: boolean = this.bcService.isDepartmentLead(pfa);
					if (filterFAsForUser
					  && !isTL
					  && !pfa.portalProcesses.some(p => this.bcService.isProcessSME((p)))) {
						continue;
					}

					const prcs = new Array<any>();
					for (const p of pfa.portalProcesses) {
						if (filterFAsForUser
						  && !isTL
						  && this.bcService.isProcessSME((p))) {
							continue;
						}
						prcs.push({ id: p.portalProcessId, name: p.portalProcessName });
					}
					this.bcFunctionalAreas.push({
						name: pfa.portalFunctionalAreaName,
						id: pfa.portalFunctionalAreaId,
						processes: prcs
					});

					this.setIssueFunctionalAreaAndProcessName();
				}
			})
			.catch((err) => {
				this.toastr.error(this.translate.instant('businessContinuity.bcIssue.msgErrorLoadingFunctionalAreas'));
			})
			.then();
	}

	populateAnnexes() {
		this.bcService.getPortalAnnexes().then((res: any) => {
			this.annexes = res.map((annex: PortalAnnex) => {
				return {
					name: annex.title,
					id: annex.portalAnnexId
				};
			});
			if (this.issue.portalAnnexId) {
				this.issue.portalAnnexName = this.annexes.find(a => a.id == this.issue.portalAnnexId).name;
			}
		});
	}

	populateTechnologies() {
		const filter = new TechnologyRequestFilter();
		filter.pageSize = 1000;
		filter.pageNumber = 1;
		filter.sortColumn = '';
		this.bcService.getPortalPlanTechnologies(this.issue.portalPlanTypeId, true, filter).then((res) => {
			this.technologies = res.technologies.map((technology: PlanTechnology) => {
				return {
					name: technology.technologyName,
					id: technology.portalPlanTechnologyId
				};
			});
			if (this.issue.portalPlanTechnologyId) {
				this.issue.portalPlanTechnologyName = this.technologies.find(a => a.id == this.issue.portalPlanTechnologyId).name;
			}
		});
	}

	onFunctionalAreaSelectionChanged() {
		const pfa = this.bcFunctionalAreas.find(fa => fa.id === this.issueFunctionalAreaId);
		this.bcProcesses = pfa?.processes;
		this.issueProcessId = null;
	};

	saveIssue() {
		if (this.enableRevisionItemsParametersSelection) {
			this.issue.revisionItemTypeId = null;
			this.issue.portalFunctionalAreaId = this.issueFunctionalAreaId;
			this.issue.portalProcessId = this.issueProcessId;
			this.issue.portalAnnexId = this.issueAnnexId;
			this.issue.portalPlanTechnologyId = this.issueTechnologyId;
		}

		if (!this.canSaveIssue) {
			this.toastr.error(this.translate.instant('businessContinuity.bcIssue.saveIssue.errorPopulateRequiredFieldsMsg'));
			return;
		}
		const toastrConfig = this.utility.createTranslatedToastrMessageConfig(
			'businessContinuity.bcIssue.saveIssue.successMsg',
			'businessContinuity.bcIssue.saveIssue.errorMsg',
			{});

		this.isSaving = true;
		this.bcService.saveBCIssue(this.issue)
			.then((res: any) => {
				this.issue = Object.assign(new BCIssue(), res.issue);
				this.bcService.issueSaved(this.issue);
				this.toastr.success(toastrConfig.successMsg);
				this.issueSaved.emit(this.issue);
			})
			.catch((err) => {
				this.toastr.error(toastrConfig.errorMsg);
			})
			.then((_) => {
				this.isSaving = false;
			});
	}

	onCancelIssue() {
		this.issueCancelled.emit();
	}

	setIssueFunctionalAreaAndProcessName() {
		if (this.issue.portalProcessName
		  && this.issue.portalFunctionalAreaName) {
			return;
		}
		// find the functional area for the process
		if (this.issue.portalProcessId) {
			let found: boolean = false;
			for (const fa of this.bcFunctionalAreas) {
				for (const p of fa.processes) {
					if (p.id === this.issue.portalProcessId) {
						this.issue.portalFunctionalAreaId = fa.id;
						this.issue.portalFunctionalAreaName = fa.name;
						this.issue.portalProcessName = p.name;
						found = true;
						break;
					}
				}
				if (found) {
					break;
				}
			}
		}
		else if (this.issue.portalFunctionalAreaId) {
			for (const fa of this.bcFunctionalAreas) {
				if (fa.id === this.issue.portalFunctionalAreaId) {
					this.issue.portalFunctionalAreaName = fa.name;
					break;
				}
			}
		}
	}

	addComment() {
		if (!this.editorContent) {
			this.onCancelNewComment();
			return;
		}

		const newComment: string = this.editorContent;
		const comment = new BCIssueComment();
		comment.comment = newComment;
		comment.issueId = this.issue.issueId;

		const toastrConfig = this.utility.createTranslatedToastrMessageConfig(
			'businessContinuity.bcIssue.saveIssueComment.successMsg',
			'businessContinuity.bcIssue.saveIssueComment.errorMsg',
			{});

		this.isSaving = true;
		this.bcService.saveBCIssueComment(comment)
			.then((res: any) => {
				this.issueComments = res;
				this.editorContent = '';
				this.toastr.success(toastrConfig.successMsg);
			})
			.catch((_) => {
				this.toastr.error(toastrConfig.errorMsg);
			})
			.then((_) => {
				this.addingNewComment = false;
				this.isSaving = false;
			});
	}

	onDeleteIssueComment(comment: BCIssueComment) {
		if (comment
		  && !this.isSaving) {
			this.utility.confirmAndExecute('businessContinuity.bcIssue.confirmDeleteIssueCommentModal',
				'btn-danger',
				this,
				this.deleteIssueComment,
				{ createdOn: moment(comment.dateTimeCreated).format('MMMM Do YYYY, h:mm a') },
				comment.issueCommentId);
		}
	}

	deleteIssueComment(issueCommentId: string) {
		const toastrConfig = this.utility.createTranslatedToastrMessageConfig(
			'businessContinuity.bcIssue.confirmDeleteIssueCommentModal.successMsg',
			'businessContinuity.bcIssue.confirmDeleteIssueCommentModal.errorMsg',
			{});
		this.bcService.deleteBCIssueComment(issueCommentId)
			.then((_) => {
				const indx = this.issueComments.findIndex(i =>
					i.issueCommentId === issueCommentId
				);
				if (indx >= 0) {
					this.issueComments.splice(indx, 1);
				}
				this.toastr.success(toastrConfig.successMsg);
			})
			.catch((_) => {
				this.toastr.error(toastrConfig.errorMsg);
			})
			.then();
	}

	canDelete(comment: BCIssueComment) {
		return (comment.createdById === this.utility.getUserId());
	}

	onNewComment() {
		this.addingNewComment = true;
		const bcIssuesEle: HTMLElement = document.querySelector('#bcIssues') as HTMLElement;

		// give the page a fraction of a millisecond to update the height as the element pops so it knows the new height to scroll to
		const timeout = setTimeout(() => {
			this.changeDetectorRef.detectChanges();
			bcIssuesEle.scrollTo(0, bcIssuesEle.scrollHeight);
			clearTimeout(timeout);
		}, 100);
	}

	onCancelNewComment() {
		this.editorContent = '';
		this.addingNewComment = false;
	}

	protected readonly dateFormat = dateFormat;
}
