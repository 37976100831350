import { BaseResponse } from '@app/shared/base-response.model';

export class PortalDelegationOfAuthority extends BaseResponse {
	portalDelegationOfAuthorityId: string = null;
	portalDelegationOfAuthorityName: string = null;
	portalDelegationOfAuthorityNameAsBase64: string = null;
	portalFunctionalAreaId: string = null;
	portalId: string = null;
	portalPlanTypeId: string = null;
	triggerCondition: string = null;
	triggerConditionAsBase64: string = null;
	limitation: string = null;
	limitationAsBase64: string = null;
	delegationOfAuthorityStatusTypeId: string = null;
	delegationOfAuthorityStatusTypeName: string = null;
	portalDelegatedAgents: PortalDelegatedAgent[] = [];

	constructor() {
		super();
	}
}

export class PortalDelegatedAgent {
	portalDelegatedAgentId: string = null;
	portalDelegationOfAuthorityId: string = null;
	isActingAgent: boolean = false;
	userId: string = null;
	firstName: string = null;
	firstNameAsBase64: string = null;
	lastName: string = null;
	lastNameAsBase64: string = null;
	title: string = null;
	titleAsBase64: string = null;
	primaryLocationId: string = null;
	primaryLocationName: string = null;
	primaryLocationNameAsBase64: string = null;

	constructor() {
	}
}
