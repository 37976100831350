export class PortalSuccessionPerson {
	portalSuccessionPersonId: string;
	userId: string;
	firstNameAsBase64: string = '';
	firstName: string = '';
	lastNameAsBase64: string = '';
	lastName: string = '';
	title: string = '';
	titleAsBase64: string = '';
	userProfileImageAsBase64: string = '';
	userProfileImage: string = '';
	primaryLocationNameAsBase64: string = '';
	primaryLocationName: string = '';
	successionOrder: number;
}
