import { Component, Input } from '@angular/core';
import { ModalReturnType } from '@app/app.enum';
import { BCIssue } from '@app/shared/business-continuity/bc-issue.model';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-bc-manage-issues-modal',
	templateUrl: './bc-manage-issues-modal.component.html',
	styleUrls: ['./bc-manage-issues-modal.component.scss']
})

export class BcManageIssuesModalComponent {
	@Input() requireFunctionalAreaId: boolean = true;
	@Input() planTypeId: string;

	constructor(public dynamicDialogRef: DynamicDialogRef,
		public dynamicDialogConfig: DynamicDialogConfig,
		private translateService: TranslateService) {
		this.requireFunctionalAreaId = this.dynamicDialogConfig.data.requireFunctionalAreaId;
		this.planTypeId = this.dynamicDialogConfig.data.planTypeId;
		this.dynamicDialogConfig.header = this.translateService.instant('businessContinuity.bcIssue.issue');
	}

	dismiss() {
		this.dynamicDialogRef.close(ModalReturnType.cancel);
	}

	issueSaved(issue: BCIssue) {
		this.dynamicDialogRef.close(issue);
	}
}
