import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { BusinessContinuityService } from '@app/providers/business-continuity.service';
import { industries, userStateTypes } from '@app/app.constants';
import { ActivatedRoute } from '@angular/router';
import { PortalDelegatedAgent, PortalDelegationOfAuthority } from '@app/shared/business-continuity/portal-delegation-of-authority';
import { User } from '@app/shared/user.model';
import { PortalFunctionalArea } from '@app/shared/business-continuity/portal-functional-area.model';
import { BreadCrumb } from '@app/shared/breadcrumb.model';
import { MenuItem } from 'primeng/api';

@Component({
	selector: 'app-bc-authority-delegations',
	templateUrl: './bc-authority-delegations.component.html',
	styleUrls: ['./bc-authority-delegations.component.scss']
})
export class BcAuthorityDelegationsComponent {
	@Input() authorities: PortalDelegationOfAuthority[] = [];
	users: User[];
	portalFunctionalArea: PortalFunctionalArea;
	@Input() isIndustryDefault: boolean;
	@Input() canManageDepartment: boolean;
	@Input() functionalAreaId: string;
	@Input() portalPlanTypeId: string;
	@Input() PlanTypeId: string;
	@Input() portalFunctionalAreaId: string;
	@Input() industryId: string;
	@Input() siteId: string;
	isComplete: boolean = false;
	@Input() departmentId: string;
	@Output() deleteDOA: EventEmitter<PortalDelegationOfAuthority> = new EventEmitter<PortalDelegationOfAuthority>();
	@Output() reload: EventEmitter<boolean> = new EventEmitter<boolean>();
	breadCrumbs: Array<BreadCrumb>;
	industries = industries;
	userStateTypes = userStateTypes;
	selectedDelegationId: string;
	isEditing: boolean = false;

	options: MenuItem[][] = [];

	constructor(public route: ActivatedRoute,
		public bcService: BusinessContinuityService,
		public translateService: TranslateService,
		public toastrService: ToastrService) {
	}

	deleteDelegation(delegation: any) {
		this.deleteDOA.emit(delegation);
	}

	getChipInfo(person: PortalDelegatedAgent) {
		let chip = `${person.firstName} ${person.lastName}`;
		if (chip.length > 41) {
			chip = chip.substring(0, 37) + '...';
		}
		return chip;
	}

	addNewDelegation() {
		this.isEditing = true;
	}

	editDelegation(id) {
		this.selectedDelegationId = id;
		this.isEditing = true;
	}

	getAgents(authority: PortalDelegationOfAuthority, isActing = false) {
		return authority.portalDelegatedAgents.filter(agent => agent.isActingAgent == isActing);
	}

	childResponse(reload: boolean) {
		this.isEditing = false;
		this.selectedDelegationId = null;
		return reload ? this.reload.emit(true) : '';
	}

	getMenu(authority: PortalDelegationOfAuthority) {
		return this.canManageDepartment
			? [
				{
					label: this.translateService.instant('common.edit'),
					command: () => this.editDelegation(authority.portalDelegationOfAuthorityId)
				},
				{
					label: this.translateService.instant('common.delete'),
					command: () => this.deleteDelegation(authority)
				}
			]
			: [
				{
					label: this.translateService.instant('common.view'),
					command: () => this.editDelegation(authority.portalDelegationOfAuthorityId)
				}
			];
	};
}
