import { Component, ContentChild, ElementRef, HostListener, Input, OnDestroy, OnInit, Renderer2, TemplateRef } from '@angular/core';
import { BusinessContinuityService } from '@app/providers/business-continuity.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SubscriptionLike as ISubscription } from 'rxjs/internal/types';
import { SpinnerService } from '@app/providers/spinner.service';
import { PortalFunctionalArea } from '@app/shared/business-continuity/portal-functional-area.model';
import { LocalStorageService } from '@app/providers/local-storage.service';
import { UserService } from '@app/providers/user.service';
import { PortalProcess } from '@app/shared/business-continuity/portal-process.model';
import { BcPlannerGroupByPlanType, BcPlannerStepsByPlanType, RevisionItemType } from '@app/shared/business-continuity/bc-planner-steps-by-plan-type.model';
import { PermissionService } from '@app/providers/permission.service';
import { PortalAnnex } from '@app/shared/business-continuity/annexes/bc-annex.model';
import { bcRevisionItemTypes, bcRevisionStateType, storageKeys } from '@app/app.constants';
import { RevisionPeriod } from '@app/shared/business-continuity/revision-period.model';
import { RevisionStatusStyle } from '@app/shared/business-continuity/revistion-status-style.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-bc-planner-footer',
	templateUrl: './bc-planner-footer.component.html',
	styleUrls: ['./bc-planner-footer.component.scss']
})
export class BcPlannerFooterComponent implements OnInit, OnDestroy {
	@ContentChild('template') template: TemplateRef<any>;

	subscription: ISubscription;
	@Input() nextEvent: any;
	@Input() skipEvent: any;
	@Input() revisionItemTypeId: string;
	@Input() nextLabel: string = 'common.markCompleteContinue';
	@Input() skipLabel: string = 'common.skip';
	@Input() portalFunctionalAreaId: string = null;
	@Input() portalAnnexId: string = null;
	@Input() portalPlanTechnologyId: string = null;
	@Input() siteId: string = null;
	@Input() portalPlanTypeId: string = null;
	@Input() isIntroductionStep: boolean = false;
	@Input() isTrackStep: boolean = false;
	@Input() nextRoute: string[] = [];
	@Input() backRoute: string[] = [];
	@Input() planTypeId: string = null;
	@Input() showSkipButton: boolean = true;
	@Input() showBackButton: boolean = true;
	@Input() showSaveButton: boolean = true;
	@Input() isCancelSave: { cancel: () => void, save: () => void };
	public portalFunctionalArea: PortalFunctionalArea;
	public portalAnnex: PortalAnnex;
	public portalTechnology: any;
	public currentUserId: string = '';
	public isTeamLead: boolean = false;
	public isTeamAlternate: boolean = false;
	public isSme: boolean = false;
	public isAnnexLead: boolean = false;
	public isTechnologyOwner: boolean = false;
	public stepsByPlanType: BcPlannerStepsByPlanType;
	public isBusinessContinuityAuditor: boolean = false;
	public isBusinessContinuityManager: boolean = false;
	public isAnnexLeadFromStorage: boolean;
	public appSupportLink: HTMLElement;
	public appScrollToTopButton: HTMLElement;
	public revisionPeriod: RevisionPeriod;
	public bcRevisionStateType = bcRevisionStateType;
	public isThinking: boolean = false;
	constructor(private businessContinuityService: BusinessContinuityService,
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private renderer2: Renderer2,
		private elementRef: ElementRef,
		private spinnerService: SpinnerService,
		private localStorageService: LocalStorageService,
		private userService: UserService,
		private translateService: TranslateService,
		private permissionService: PermissionService) {
		this.currentUserId = userService.getCurrentUserId();
		this.isBusinessContinuityManager = this.businessContinuityService.isBusinessContinuityManager;
		this.isBusinessContinuityAuditor = this.businessContinuityService.isBusinessContinuityAuditor;
	}

	ngOnInit(): void {
		// get status
		this.getCurrentPortalRevisions();

		// reposition the help and scrool to top buttonm
		this.reposition();

		// NEED this to compare with isAnnexLead - can be annexLead of any annexes vs is the annexLead for active annex
		this.isAnnexLeadFromStorage = this.localStorageService.get(storageKeys.isBusinessContinuityAnnexLead);

		if (!String.isNullOrEmpty(this.portalFunctionalAreaId)) {
			this.businessContinuityService.getPortalFunctionalArea(this.portalFunctionalAreaId).then((res: any) => {
				this.portalFunctionalArea = res.portalFunctionalArea;
				this.isTeamLead = this.currentUserId === this.portalFunctionalArea.leader.userId;
				this.isTeamAlternate = this.portalFunctionalArea.alternateLeaders.map(user => user.userId).includes(this.currentUserId);
				this.isSme = !!this.portalFunctionalArea?.portalProcesses?.find((pp: PortalProcess) => pp.responsibleUser.userId === this.currentUserId);
			}).catch((_) => {
			}).finally(() => {
				if (this.showBackButton) {
					this.showBackButton = !!this.getRoute(-1) || this.backRoute?.length > 0;
				}
				if (this.showSkipButton) {
					this.showSkipButton = !!this.getRoute(1) || this.nextRoute?.length > 0;
				}
			});
		}

		if (!String.isNullOrEmpty(this.portalAnnexId)) {
			this.businessContinuityService.getPortalAnnex(this.portalAnnexId).then((res: any) => {
				this.portalAnnex = res.portalAnnex;
				this.isAnnexLead = this.portalAnnex.assignees.findIndex(a => a.assignedUserId === this.currentUserId) > -1;
			}).catch((_) => {
			}).finally(() => {
				if (this.showBackButton) {
					this.showBackButton = !!this.getRoute(-1) || this.backRoute?.length > 0;
				}
				if (this.showSkipButton) {
					this.showSkipButton = !!this.getRoute(1) || this.nextRoute?.length > 0;
				}
			});
		}

		if (!String.isNullOrEmpty(this.portalPlanTechnologyId)) {
			this.businessContinuityService.getPortalPlanTechnology(this.portalPlanTechnologyId).then((res: any) => {
				this.portalTechnology = res.portalTechnology;
				this.isTechnologyOwner = this.portalAnnex.assignees.findIndex(a => a.assignedUserId === this.currentUserId) > -1;
			}).catch((_) => {
			}).finally(() => {
				if (this.showBackButton) {
					this.showBackButton = !!this.getRoute(-1) || this.backRoute?.length > 0;
				}
				if (this.showSkipButton) {
					this.showSkipButton = !!this.getRoute(1) || this.nextRoute?.length > 0;
				}
			});
		}
	}

	getCurrentPortalRevisions() {
		if (this.siteId) {
			if (this.portalFunctionalAreaId) {
				this.businessContinuityService.getCurrentPortalRevisionsByPortalFunctionalArea(this.portalFunctionalAreaId, this.portalPlanTypeId)
					.then((res: any) => {
						this.revisionPeriod = res.revisionPeriod;
					})
					.catch((err: any) => {
					})
					.finally(() => {
						this.updateContinueButtonText();
					});
			}
			else {
				this.businessContinuityService.getCurrentPortalRevisions(this.siteId, this.portalPlanTypeId)
					.then((res: any) => {
						this.revisionPeriod = res.revisionPeriod;
					})
					.catch((err: any) => {
					})
					.finally(() => {
						this.updateContinueButtonText();
					});
			}
		}
	}

	updateContinueButtonText(): void {
		if (this.revisionPeriod) {
			// REQUIRES getCurrentPortalRevisions() to be completed 1st!
			const revisionStatusStyle: RevisionStatusStyle = this.businessContinuityService.calcRevisionItemStyle(this.revisionPeriod, this.revisionItemTypeId, this.portalFunctionalAreaId, null, this.portalAnnexId, this.portalPlanTechnologyId);
			// auditor || annexLead in general but not the annexLead for active annex
			if (this.isBusinessContinuityAuditor || ((this.isAnnexLeadFromStorage && !this.isAnnexLead) && !this.isBusinessContinuityManager) || revisionStatusStyle?.status === this.bcRevisionStateType.complete) {
				this.nextLabel = 'common.continue';
			}
			const revisionScope: string = this.activatedRoute?.snapshot?.data?.revisionScope;
			const revisionCategory: string = this.activatedRoute?.snapshot?.data?.revisionCategory;
			this.stepsByPlanType = new BcPlannerStepsByPlanType(this.permissionService, this.portalPlanTypeId, this.siteId, this.planTypeId, this.portalFunctionalAreaId, this.portalAnnexId, this.portalPlanTechnologyId, revisionScope, revisionCategory, this.isTeamLead, this.isSme, this.isAnnexLeadFromStorage || this.isAnnexLead, this.isTeamAlternate, this.isTechnologyOwner);
			let revisionItemTypes: RevisionItemType[] = [];
			this.stepsByPlanType.groups.forEach((group: BcPlannerGroupByPlanType) => {
				revisionItemTypes = [...revisionItemTypes, ...group.steps];
			});
			const currentRouteIndex: number = revisionItemTypes.findIndex((rit: RevisionItemType) => rit.revisionItemTypeId === this.revisionItemTypeId);

			if (revisionItemTypes[currentRouteIndex]?.revisionItemTypeId === bcRevisionItemTypes.technologyRecoveryTime || revisionItemTypes[currentRouteIndex]?.revisionItemTypeId === bcRevisionItemTypes.annexActionList) {
				if (revisionStatusStyle?.status === this.bcRevisionStateType.complete) {
					this.nextLabel = this.translateService.instant('common.done');
				}
				else {
					this.nextLabel = this.translateService.instant('common.markComplete');
				}
			}
		}
	}

	ngOnDestroy() {
		// set the help and scroll to top button back to default
		this.renderer2?.setStyle(this.appSupportLink, 'bottom', '1em');
		this.renderer2?.setStyle(this.appScrollToTopButton, 'bottom', '5em');
	}

	reposition() {
		this.appSupportLink = document.getElementById('appSupportLink') as HTMLElement;
		this.appScrollToTopButton = document.getElementById('appScrollToTopButton') as HTMLElement;

		this.renderer2?.setStyle(this.appSupportLink, 'bottom', '6rem');
		this.renderer2?.setStyle(this.appScrollToTopButton, 'bottom', '10rem');
	}

	back() {
		if (this.backRoute?.length) {
			this.router.navigate(this.backRoute);
			return;
		}

		const route: string[] = this.getRoute(-1);

		if (route?.length) {
			this.router.navigate(route);
		}
	}

	next() {
		this.isThinking = true;

		if (this.nextEvent) {
			this.nextEvent()
				.then(() => {
					this.skip();
				})
				.catch((err: any) => {
					this.isThinking = false;
				});
		}
		else {
			this.skip();
		}
	}

	skip() {
		if (this.nextRoute?.length) {
			this.router.navigate(this.nextRoute);
			this.isThinking = false;
			return;
		}

		const route: string[] = this.getRoute(1);

		if (route?.length) {
			this.router.navigate(route);
		}

		this.isThinking = false;
	}

	getRoute(increment: number): string[] {
		const revisionScope: string = this.activatedRoute?.snapshot?.data?.revisionScope;
		const revisionCategory: string = this.activatedRoute?.snapshot?.data?.revisionCategory;
		this.stepsByPlanType = new BcPlannerStepsByPlanType(this.permissionService, this.portalPlanTypeId, this.siteId, this.planTypeId, this.portalFunctionalAreaId, this.portalAnnexId, this.portalPlanTechnologyId, revisionScope, revisionCategory, this.isTeamLead, this.isSme, this.isAnnexLeadFromStorage || this.isAnnexLead, this.isTeamAlternate, this.isTechnologyOwner);
		let revisionItemTypes: RevisionItemType[] = [];
		this.stepsByPlanType.groups.forEach((group: BcPlannerGroupByPlanType) => {
			revisionItemTypes = [...revisionItemTypes, ...group.steps];
		});
		const currentRouteIndex: number = revisionItemTypes.findIndex((rit: RevisionItemType) => rit.revisionItemTypeId === this.revisionItemTypeId);
		return revisionItemTypes[currentRouteIndex + increment]?.route;
	}
}
