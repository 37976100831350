<button class="btn btn-link"
        (mouseenter)="show($event)"
        (mouseleave)="hide()">
    {{ assignedPortalPlans?.length || 0 }}
</button>

<p-overlayPanel #op [showCloseIcon]="false">
    <ng-template pTemplate>
        <div class="mb-3">
            <div class="fw-bold">Plans</div>
            <ul class="pb-0 m-0">
                <li *ngFor="let pp of assignedPortalPlans">
                    {{pp.planTypeName}}
                </li>
            </ul>
        </div>
    </ng-template>
</p-overlayPanel>
