<div class="container ">
    <div class="row" *ngIf="isIndustryDefault">
        <div class="col">
            <app-breadcrumbs [breadcrumbs]="breadCrumbs"></app-breadcrumbs>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <h3 class="fw-semibold">{{'businessContinuity.authorityDelegation.header' | translate}}</h3>
        </div>
    </div>

    <div class="row mt-2">
        <div class="col-12">
            <label class="form-label" for="name">{{'businessContinuity.authorityDelegation.title' | translate}}</label>
            <input [(ngModel)]="delegation.portalDelegationOfAuthorityName"
                   class="form-control"
                   id="name"
                   name="name"
                   *ngIf="canManage;else nameReadOnly"
                   required/>
            <ng-template #nameReadOnly>
                <p [innerHTML]="delegation.portalDelegationOfAuthorityName"></p>
            </ng-template>
        </div>

    </div>
    <div class="row mt-5">
        <div class="col-12">
            <label class="form-label fw-bold" for="triggers">
                {{'businessContinuity.authorityDelegation.triggerCondition' | translate}}
                <i #summary
                   (click)="openEditor('triggerCondition')"
                   *ngIf="canManage"
                   class="fa fa-pencil ms-2 text-primary cursor"
                   id="triggers">

                </i>
            </label>
            <div>
                <app-read-more
                    *ngIf="delegation?.triggerCondition else noTriggerCondition"
                    [maxLength]="250"
                    [isSanitized]="true"
                    [text]="delegation.triggerCondition">
                </app-read-more>
                <ng-template #noTriggerCondition>{{'businessContinuity.authorityDelegation.triggersPlaceholder' | translate}}
                </ng-template>
            </div>
        </div>
    </div>
    <div class="row mt-5">
        <div class="col-12">
            <label class="form-label fw-bold" for="goal">
                {{'businessContinuity.authorityDelegation.limitation' | translate}}
                <i #summary
                   (click)="openEditor('limitation')"
                   *ngIf="canManage"
                   class="fa fa-pencil ms-2 text-primary cursor"
                   id="goal">
                </i>
            </label>
            <div>
                <app-read-more
                    *ngIf="delegation?.limitation else noLimitation"
                    [maxLength]="250"
                    [isSanitized]="true"
                    [text]="delegation.limitation">
                </app-read-more>
                <ng-template #noLimitation>
                    {{'businessContinuity.authorityDelegation.limitationsPlaceholder' | translate}}
                </ng-template>
            </div>
        </div>
    </div>
    <ng-container *ngIf="!isIndustryDefault">
    <div class="row mt-5">
        <div class="col-12">
            <label class="form-label fw-bold" for="active">
                {{'businessContinuity.authorityDelegation.actingAgents' | translate}}
            </label>
            <app-authority-delegations-assignees (addUserEvent)="addActive($event)"
                                                 [assignees]="actingUsers"
                                                 [customStyles]="['height:100%','overflow-x: hidden']"
                                                 [filterOutUsers]="filterOutUsers()"
                                                 [viewOnly]="!canManage"
                                                 addText="{{'businessContinuity.authorityDelegation.addActing' | translate}}"
                                                 id="active"></app-authority-delegations-assignees>
        </div>

    </div>
    <div class="row mt-4 mb-2">
        <div class="col-12">
            <label class="form-label fw-bold" for="active">
                {{'businessContinuity.authorityDelegation.delegatedAgents' | translate}}
            </label>
            <app-authority-delegations-assignees (addUserEvent)="addDelegated($event)"
                                                 [assignees]="delegatedUsers"
                                                 [customStyles]="['height:100%','overflow-x: hidden']"
                                                 [filterOutUsers]="filterOutUsers()"
                                                 [viewOnly]="!canManage"
                                                 addText="{{'businessContinuity.authorityDelegation.addDelegated' | translate}}"
                                                 id="delegated"></app-authority-delegations-assignees>
        </div>

    </div>
    </ng-container>
    <div class="row my-5">
        <div class="float-end text-end">
            <button (click)="goBack()" class="btn btn-outline-primary px-3 me-1" type="button">{{ 'common.cancel' | translate }}</button>
            <button *ngIf="canManage" (click)="saveDelegation()" class="btn btn-primary px-3" type="button">{{ 'common.save' | translate }}</button>
        </div>
    </div>
</div>
<app-spinner [name]="'delegation-spinner'"></app-spinner>

<app-sidebar-widget [handlerText]="'businessContinuity.authorityDelegation.header' | translate"
                    [headerText]="'businessContinuity.authorityDelegation.header' | translate">
    <ng-template #sidebarTemplate>
        <div class="border-bottom mb-3 py-3">
            <label class="form-label">{{'common.title' | translate}}</label>
            <p>{{'businessContinuity.authorityDelegation.authorityDescription' | translate}}</p>
        </div>
        <div class="border-bottom mb-3 py-3">
            <label class="form-label">{{'common.triggers' | translate}}</label>
            <p>{{'businessContinuity.authorityDelegation.triggersDescription' | translate}}</p>
        </div>
        <div class="border-bottom mb-3 py-3">
            <label class="form-label">{{'common.limitations' | translate}}</label>
            <p>{{'businessContinuity.authorityDelegation.limitationsDescription' | translate}}</p>
        </div>
        <ng-container *ngIf="!isIndustryDefault">
        <div class="border-bottom mb-3 py-3">
            <label class="form-label">{{'businessContinuity.authorityDelegation.actingAgent' | translate}}</label>
            <p>{{'businessContinuity.authorityDelegation.actingAgentDesc' | translate}}</p>
        </div>
        <div class="border-bottom mb-3 py-3">
            <label class="form-label">{{'businessContinuity.authorityDelegation.delegatingAgent' | translate}}</label>
            <p>{{'businessContinuity.authorityDelegation.delegatesDescription' | translate}}</p>
        </div>
        </ng-container>
    </ng-template>
</app-sidebar-widget>
