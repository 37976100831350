export class RevisionItem {
	revisionItemId: string = null;
	revisionId: string = null;
	siteId: string = null;
	portalFunctionalAreaId: string = null;
	portalProcessId: string = null;
	portalAnnexId: string = null;
	portalPlanTechnologyId: string = null;
	revisionItemTypeId: string = null;
	revisionStateTypeId: string = null;
	revisionItemStartDate: Date = null;
	revisionItemDueDate: Date = null;
	portalPlanTypeId: string;

	constructor(siteId: string, portalFunctionalAreaId: string, portalProcessId: string, portalAnnexId: string, portalPlanTechnologyId: string, revisionItemTypeId: string, revisionStateTypeId: string, revisionItemDueDate?: Date) {
		this.siteId = siteId;
		this.portalFunctionalAreaId = portalFunctionalAreaId;
		this.portalProcessId = portalProcessId;
		this.portalAnnexId = portalAnnexId;
		this.portalPlanTechnologyId = portalPlanTechnologyId;
		this.revisionItemTypeId = revisionItemTypeId;
		this.revisionStateTypeId = revisionStateTypeId;
		this.revisionItemDueDate = revisionItemDueDate;
	}
}
