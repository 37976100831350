<div class="modal-body">
    <form [formGroup]="vendorForm" (ngSubmit)="save()">
        <div class="mb-3">
            <label class="form-label">{{'common.name' | translate}}</label>
            <div class="input-group">
                <input class="form-control"
                       type="text"
                       appAutofocus
                       id="vendorNameInput"
                       name="vendorName"
                       formControlName="vendorName"
                       [ngClass]="{'is-invalid': vendorForm.get('vendorName').invalid && submitted}"
                       placeholder="{{'businessContinuity.continuityStrategy.vendorModal.typeNamePlaceholder' | translate}}"
                       required/>
            </div>
        </div>

        <div  formGroupName="vendorAddress">
            <div class="mb-3">
                <label class="form-label">{{'businessContinuity.continuityStrategy.vendorModal.vendorAddress' | translate}}</label>
                <input class="form-control mb-3"
                       type="text"
                       id="vendorAddress1Input"
                       name="vendorAddress1"
                       formControlName="address1"/>
                <input class="form-control"
                       type="text"
                       id="vendorAddress2Input"
                       name="vendorAddress2"
                       formControlName="address2"/>
            </div>

            <div class="row">
                <div class="col">
                    <div class="mb-3">
                        <label class="form-label">{{'common.city' | translate}}</label>
                        <input class="form-control mb-3"
                               type="text"
                               id="vendorCityInput"
                               name="vendorCity"
                               formControlName="city"/>
                    </div>
                </div>
                <div class="col">
                    <div class="mb-3">
                        <label class="form-label">{{'common.state' | translate}}</label>
                        <input class="form-control mb-3"
                               type="text"
                               id="vendorStateInput"
                               name="vendorState"
                               formControlName="state"/>
                    </div>
                </div>
                <div class="col">
                    <div class="mb-3">
                        <label class="form-label">{{'common.zip' | translate}}</label>
                        <input class="form-control mb-3"
                               type="text"
                               id="vendorZipInput"
                               name="vendorZip"
                               formControlName="zip"/>
                    </div>
                </div>
                <div class="col">
                    <div class="mb-3">
                        <label class="form-label">{{'common.country' | translate}}</label>
                        <select id="vendorCountrySelect"
                                class="form-select"
                                name="countryTypeId"
                                formControlName="countryTypeId">
                            <option *ngFor="let country of countries"
                                    [value]="country.countryTypeId">
                                {{country.countryTypeName}}
                            </option>
                        </select>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col">
                    <div class="mb-3">
                        <label class="form-label">{{'common.phone' | translate}}</label>
                        <input class="form-control mb-3"
                               type="tel"
                               id="vendorPhoneInput"
                               name="phone"
                               formControlName="phone"
                               [ngClass]="{'ng-touched ng-invalid': !addressPhoneValid}"/>
                    </div>
                </div>
                <div class="col">
                    <div class="mb-3">
                        <label class="form-label">{{'common.website' | translate}}</label>
                        <input class="form-control mb-3"
                               type="url"
                               id="vendorUrlInput"
                               name="url"
                               formControlName="url"
                               [ngClass]="{'ng-touched ng-invalid': !addressUrlValid}"/>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col">
                <div class="mb-3">
                    <label class="form-label">{{'businessContinuity.continuityStrategy.vendorModal.contractStartDate' | translate}}</label>
                    <p-calendar class="d-block"
                                inputId="vendorContractStartDate"
                                [showIcon]="true"
                                [maxDate]="startDateMax"
                                [showButtonBar]="true"
                                [yearNavigator]="true"
                                yearRange="1970:2050"
                                (onSelect)="setExpDateMin($event)"
                                (onClearClick)="clearDateConstraints()"
                                (onInput)="setEndDateOnInput($event)"
                                formControlName="contractStartDate"></p-calendar>
                </div>
            </div>
            <div class="col">
                <div class="p-fluid mb-3">
                    <label class="form-label">{{'businessContinuity.continuityStrategy.vendorModal.contractExpirationDate' | translate}}</label>
                    <p-calendar class="d-block"
                                inputId="vendorContractExpirationDate"
                                [showIcon]="true"
                                [minDate]="endDateMin"
                                [showButtonBar]="true"
                                [yearNavigator]="true"
                                yearRange="1970:2050"
                                (onSelect)="setStartDateMin($event)"
                                (onClearClick)="clearDateConstraints()"
                                (onInput)="setStartDateOnInput($event)"
                                formControlName="contractEndDate"></p-calendar>
                </div>
            </div>
        </div>

        <hr/>

        <div class="mb-3">
            <label class="form-label">{{'common.contacts' | translate}}</label>
            <div class="btn-new-contact text-end">
                <button type="button"
                        class="btn btn-outline-primary mt-3 mb-2"
                        (click)="addNewVendorContact()"
                        pTooltip="{{'common.add' | translate}}">
                    {{'recoveryProfiles.essentialContacts.newContact' | translate}}
                </button>
            </div>
            <div formArrayName="vendorContacts">
                <p-table [value]="vendorContactArray.controls">
                    <ng-template pTemplate="header">
                        <tr>
                            <th style="width: 75px;" class="text-center">{{'common.primary' | translate}}</th>
                            <th>{{'common.name' | translate}}</th>
                            <th>{{'common.title' | translate}}</th>
                            <th>{{'common.email' | translate}}</th>
                            <th>{{'common.phone' | translate}}</th>
                            <th></th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-contact let-i="rowIndex">
                        <tr [formGroupName]="i">
                            <td class="text-center">
                                <p-inputSwitch formControlName="isPrimary"
                                               (onChange)="toggleContactIsPrimary(contact, i);"></p-inputSwitch>
                            </td>
                            <td>
                                <input class="form-control"
                                       type="text"
                                       formControlName="name"
                                       [ngClass]="{'is-invalid': contact.get('name').invalid && submitted}"
                                       required/>
                            </td>
                            <td>
                                <input class="form-control"
                                       type="text"
                                       formControlName="title"/>
                            </td>
                            <td>
                                <input class="form-control"
                                       type="email"
                                       formControlName="email"
                                       [ngClass]="{'is-invalid': contact.get('email').invalid && submitted}"
                                       required/>
                            </td>
                            <td>
                                <input class="form-control"
                                       type="tel"
                                       formControlName="phone"
                                       [ngClass]="{'is-invalid': contact.get('phone').invalid && submitted}"
                                       required/>
                            </td>
                            <td class="text-center">
                                <button class="btn btn-danger btn-sm"
                                        (click)="deleteContact(i)"
                                        pTooltip="{{'common.remove' | translate}}">
                                    <i class="fas fa-trash"></i>
                                </button>
                            </td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage">
                        <tr>
                            <td class="text-center" colspan="6">{{'common.noRecords' | translate}}</td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </div>

        <hr/>

        <div class="mb-3">
            <label class="form-label">{{'businessContinuity.continuityStrategy.vendorModal.additionalNotes' | translate}}</label>
            <div class="input-group">
                    <textarea id="additionalNotesTextarea"
                              name="additionalNotes"
                              class="form-control"
                              formControlName="comments"
                              placeholder="{{'businessContinuity.continuityStrategy.vendorModal.additionalNotesPlaceholder' | translate}}"
                              rows="3"></textarea>
            </div>
        </div>

        <hr class="my-4"/>

        <div class="mb-3">
            <label class="form-label">
                {{'common.attachments' | translate}}
            </label>
            <label class="btn btn-outline-secondary ms-2 form-label" style="min-width: 4%; max-width: 7%"
                   pTooltip="{{'businessContinuity.continuityStrategy.uploadDocument' | translate}}">
                <i class="fas fa-paperclip"></i>
                <input type="file" ng2FileSelect [uploader]="uploader" (change)="fileSelected($event)" class="d-none"/>
            </label>
            <div>
                <div *ngIf="documents?.length === 0">
                    {{'businessContinuity.continuityStrategy.noAttachments' | translate}}
                </div>
                <div class="row align-items-center my-3" *ngFor="let document of documents; let index = index">
                    <div class="col-6 d-flex align-items-center">
                        <button id="{{index + 'deleteDocument'}}" type="button" class="btn btn-danger float-start"
                                (click)="onDeleteDocument(document)"
                                pTooltip="{{ 'itemDocument.removeDocument' | translate }}"
                                tooltipPosition="right-top">
                            <i class="fa fa-trash" aria-hidden="true"></i>
                        </button>
                        <button id="{{index + 'getDocument'}}" type="button" class="btn btn-primary float-start mx-1 me-3"
                                (click)="getDocument(document)"
                                pTooltip="{{ 'itemDocument.downloadDocument' | translate }}">
                            <i class="fa fa-download" aria-hidden="true"></i>
                        </button>
                        <i class="fa-2x {{ getDocumentIcon(document) }} me-3"></i>
                        <a id="getDocumentLink" (click)="getDocument(document)">
                            {{document.documentFileName}}
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>

<div class="modal-footer text-end">
    <button id="saveButton"
            type="button"
            class="btn btn-primary businessContinuityNextButton"
            (click)="save()">{{(isCreating ? 'common.create' : 'common.save') | translate}}</button>
</div>

<div class="confirmation-container"></div>
