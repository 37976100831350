import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { CustomFieldValue } from '@app/shared/custom-field-value.model';
import { customFieldDataTypeIds, customFieldDataTypes, debounceDuration } from '@app/app.constants';
import { Subject } from 'rxjs';
import { Utility } from '@app/providers/utility';
import { SpinnerService } from '@app/providers/spinner.service';
import { TranslateService } from '@ngx-translate/core';
import { BaseResponse } from '@app/shared/base-response.model';
import moment from 'moment';
import { CustomFieldOption } from '@app/shared/custom-field-option.model';
import { BusinessContinuityService } from '@app/providers/business-continuity.service';
import { debounceTime } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { PortalProcessStrategy } from '@app/shared/business-continuity/portal-process-strategy.model';
import { NgbDateStruct } from '@app/shared/bootstrap/ngbdatestruct.model';

@Component({
	selector: 'app-bc-custom-field-values',
	templateUrl: './bc-custom-field-values.component.html',
	styleUrls: ['./bc-custom-field-values.component.scss']
})
export class BcCustomFieldValuesComponent implements OnInit, OnChanges {
	@Input() portalProcessStrategy: PortalProcessStrategy;
	@Input() bypassRequiredField: boolean = false;
	@Input() canEdit: boolean = false;
	@Output() customFieldsLoaded: EventEmitter<number> = new EventEmitter<number>();
	@ViewChild('customFieldsForm') public customFieldsForm: NgForm;

	customFieldValues: Array<CustomFieldValue> = [];
	customDatePickers: any;
	checkboxes: any;
	customFieldDataTypes = customFieldDataTypes;
	onChangeTrigger = new Subject();

	constructor(private utility: Utility,
		private bcService: BusinessContinuityService,
		private spinnerService: SpinnerService,
		public translateService: TranslateService,
		private toastrService: ToastrService) {
	}

	get isValid(): boolean {
		return this.customFieldsForm == null || this.customFieldsForm.form.valid;
	}

	ngOnInit() {
		this.customDatePickers = {};
		this.checkboxes = {};
		this.onChangeTrigger.pipe(
			debounceTime(debounceDuration))
			.subscribe((customFieldValue: CustomFieldValue) => {
				this.saveCustomFields(customFieldValue).then((result: BaseResponse) => {
					this.toastrService.success(this.translateService.instant('businessContinuity.continuityStrategy.saveQuestionSuccessMsg'));
				}).catch((error: BaseResponse) => {
					this.toastrService.error(this.translateService.instant('businessContinuity.continuityStrategy.saveQuestionErrorMsg'));
				});
			});

		this.getCustomFields();
	}

	ngOnChanges(changes: SimpleChanges) {
		// when switching strategy, reinitialize this component
		this.getCustomFields();
	}

	getCustomFields() {
		this.bcService.getBCStrategyCustomFields(this.portalProcessStrategy.portalProcessStrategyId).then((res: any) => {
			if (res.customFieldValues) {
				this.customFieldValues = res.customFieldValues;
				this.customFieldValues.sort((a, b) => (a.portalCustomField.displaySequence > b.portalCustomField.displaySequence) ? 1 : ((b.portalCustomField.displaySequence > a.portalCustomField.displaySequence) ? -1 : 0));
				this.customFieldValues.forEach((customFieldValue: CustomFieldValue) => {
					switch (customFieldValue.portalCustomField.customFieldDataTypeId) {
						case customFieldDataTypes.DateTime:
							let customDatePicker: NgbDateStruct;
							if (customFieldValue.fieldValue) {
								const dateInMillis: number = parseInt(customFieldValue.fieldValue);
								if (dateInMillis) {
									customDatePicker = this.utility.toBootstrapDate(moment.utc(dateInMillis));
								}
							}
							this.customDatePickers[customFieldValue.customFieldValueId] = customDatePicker;
							break;
						case customFieldDataTypes.TrueFalse:
							if (customFieldValue.fieldValue) {
								this.checkboxes[customFieldValue.customFieldValueId] = customFieldValue.fieldValue.toLowerCase() === 'true' || customFieldValue.fieldValue === '1';
							}
							break;
						case customFieldDataTypes.SelectOne:
							const optionNameList: Array<string> = customFieldValue.portalCustomField.customFieldOptions.map(o => o.optionName);
							if (String.isNullOrEmpty(customFieldValue.parentId)) {
								const defaultOption: CustomFieldOption = Array.from(customFieldValue.portalCustomField.customFieldOptions).find(option => option.isDefault);
								if (defaultOption) {
									customFieldValue.fieldValue = defaultOption.optionName;
								}
							}
							if (customFieldValue.fieldValue && !Array.from(optionNameList).includes(customFieldValue.fieldValue)) {
								customFieldValue.portalCustomField.customFieldOptions.push(<CustomFieldOption>{ optionName: customFieldValue.fieldValue });
							}
							customFieldValue.portalCustomField.customFieldOptions.forEach(o => o.optionDisplayName = this.getCustomFieldOptionName(o));
							break;
						case customFieldDataTypes.SelectMany:
							const optionNames: Array<string> = customFieldValue.portalCustomField.customFieldOptions.map(o => o.optionName);
							if (customFieldValue.fieldValue !== '') {
								customFieldValue.fieldValues = customFieldValue.fieldValue.split('|');
								customFieldValue.fieldValues.forEach((v) => {
									if (v && !Array.from(optionNames).includes(v)) {
										customFieldValue.portalCustomField.customFieldOptions.push(<CustomFieldOption>{ optionName: v });
									}
								});
							}
							customFieldValue.portalCustomField.customFieldOptions.forEach(o => o.optionDisplayName = this.getCustomFieldOptionName(o));
							break;
					}
				});
			}
		}).catch((_) => {
		}).then((_) => {
			this.customFieldsLoaded.emit(this.customFieldValues.length);
		});
	}

	saveCustomFields(customFieldValue: CustomFieldValue): Promise<any> {
		if (String.isNullOrEmpty(customFieldValue.parentId)) {
			customFieldValue.parentId = this.portalProcessStrategy.portalProcessStrategyId;
		}
		switch (customFieldValue.portalCustomField.customFieldDataTypeId) {
			case customFieldDataTypeIds.Numeric:
				if (customFieldValue.fieldValue) {
					customFieldValue.fieldValue = '' + customFieldValue.fieldValue;
				}
				break;
			case customFieldDataTypeIds.DateTime:
				const dateStruct: NgbDateStruct = this.customDatePickers[customFieldValue.customFieldValueId];
				if (dateStruct) {
					customFieldValue.fieldValue = '' + this.utility.fromBootstrapDateToUTC(this.customDatePickers[customFieldValue.customFieldValueId]).valueOf();
				}
				else {
					customFieldValue.fieldValue = '';
				}
				break;
			case customFieldDataTypeIds.TrueFalse:
				customFieldValue.fieldValue = this.checkboxes[customFieldValue.customFieldValueId] ? 'true' : 'false';
				break;
			case customFieldDataTypes.SelectMany:
				customFieldValue.fieldValue = customFieldValue.fieldValues.length > 0 ? customFieldValue.fieldValues.filter(v => !String.isNullOrEmpty(v)).join('|') : null;
				break;
		}
		return this.bcService.saveBcStrategyCustomFields(this.portalProcessStrategy, [customFieldValue]);
	}

	getCustomFieldOptionName(option: CustomFieldOption): string {
		return (option.customFieldOptionId) ? option.optionName : option.optionName + ' (' + this.translateService.instant('customFields.deprecatedOption').toLowerCase() + ')';
	}

	onChanged(customFieldValue: CustomFieldValue) {
		this.onChangeTrigger.next(customFieldValue);
	}
}
