<div class="bcPlannerFooter">
    <div class="container">
        <div class="row">
            <div class="col-md-6">
                <button class="btn btn-outline-secondary"
                        (click)="back()"
                        *ngIf="!isIntroductionStep && showBackButton"
                        [disabled]="isThinking">
                    {{'common.back' | translate}}
                </button>
            </div>
            <div class="col-md-6 text-end">
                <ng-container [ngTemplateOutlet]="template"></ng-container>

                <ng-container *ngIf="isCancelSave; else normal">
                    <button class="btn btn-outline-secondary"
                            (click)="isCancelSave.cancel()"
                            [disabled]="isThinking">
                        {{'common.cancel' | translate}}
                    </button>
                    <button class="btn btn-primary"
                            *ngIf="showSaveButton"
                            (click)="isCancelSave.save()"
                            [disabled]="isThinking">
                        {{'common.save' | translate}}
                    </button>
                </ng-container>
                <ng-template #normal>
                <button class="btn btn-outline-secondary"
                        (click)="skip()"
                        *ngIf="!isIntroductionStep && !isTrackStep && !isBusinessContinuityAuditor && showSkipButton"
                        [disabled]="isThinking">
                    {{skipLabel | translate}}
                </button>
                <button class="btn btn-primary"
                        (click)="next()"
                        *ngIf="!isTrackStep"
                        [disabled]="isThinking">
                    {{nextLabel | translate}}
                </button>
                </ng-template>
            </div>
        </div>
    </div>
</div>
