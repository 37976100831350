import { ActivatedRoute } from '@angular/router';
import { PortalIndustry } from '@app/shared/business-continuity/portal-industry.model';
import { BusinessContinuityService } from '@app/providers/business-continuity.service';
import { AfterContentInit, Component, Inject } from '@angular/core';
import { RevisionItem } from '@app/shared/business-continuity/revision-item.model';
import { bcRevisionItemTypes, bcRevisionStateType } from '@app/app.constants';
import { PortalFunctionalArea } from '@app/shared/business-continuity/portal-functional-area.model';
import { PortalProcess } from '@app/shared/business-continuity/portal-process.model';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { PortalAnnex } from '@app/shared/business-continuity/annexes/bc-annex.model';
import { PlanTechnology } from '@app/shared/business-continuity/technologies/bc-plan-technology';

@Component({
	template: ''
})

export class BaseBusinessContinuityComponent implements AfterContentInit {
	siteId: string = null;
	portalFunctionalAreaId: string = null;
	portalProcessId: string = null;
	portalAnnexId: string = null;
	portalPlanTechnologyId: string = null;
	userProfileName: string = null;
	portalIndustry: PortalIndustry = null;
	portalPlanTypeId: string = null;
	impactTimeSpans: Array<any> = [];
	impactCategories: Array<any> = [];
	impactSeverities: Array<any> = [];
	public planTypeId: string = '';

	constructor(public route: ActivatedRoute,
		public bcService: BusinessContinuityService,
		@Inject('revisionItemTypeId') public revisionItemTypeId: any = bcRevisionItemTypes.none,
		public toastrService: ToastrService,
		public translateService: TranslateService) {
		this.impactTimeSpans = this.bcService.portalImpactTimeSpans;
		this.impactCategories = this.bcService.portalImpactCategories;
		this.impactSeverities = this.bcService.portalImpactSeverities;

		this.siteId = this.route.snapshot.params.siteId;
		this.portalFunctionalAreaId = this.route.snapshot.params.portalFunctionalAreaId;
		this.portalProcessId = this.route.snapshot.params.portalProcessId;
		this.portalAnnexId = this.route.snapshot.params.portalAnnexId;
		this.portalPlanTechnologyId = this.route.snapshot.params.portalPlanTechnologyId;

		this.userProfileName = this.route.snapshot.data.userProfileName;
		this.portalIndustry = this.route.snapshot.data.portalIndustry;
		this.portalPlanTypeId = this.route.snapshot.data.portalPlanTypeId || this.route.snapshot.params.portalPlanTypeId;
	}

	get isBusinessContinuityManager(): boolean {
		return this.bcService.isBusinessContinuityManager;
	}

	get isBusinessContinuityAuditor(): boolean {
		return this.bcService.isBusinessContinuityAuditor;
	}

	canManageDepartment(pfa: PortalFunctionalArea): boolean {
		return this.bcService.canManageDepartment(pfa);
	}

	canAccessDepartment(pfa: PortalFunctionalArea): boolean {
		return this.bcService.canAccessDepartment(pfa);
	}

	canManageProcess(p: PortalProcess): boolean {
		return this.bcService.canManageProcess(p);
	}

	canManageAnnex(a: PortalAnnex): boolean {
		return this.bcService.canManageAnnex(a);
	}

	canManageTechnology(t: PlanTechnology): boolean {
		return this.bcService.canManageTechnology(t);
	}

	isDepartmentLead(pfa: PortalFunctionalArea) {
		return this.bcService.isDepartmentLead(pfa);
	}

	isProcessSME(p: PortalProcess) {
		return this.bcService.isProcessSME(p);
	}

	ngAfterContentInit() {
		this.bcService.setNavigation({
			route: this.route,
			siteId: this.siteId,
			portalFunctionalAreaId: this.portalFunctionalAreaId,
			portalProcessId: this.portalProcessId,
			revisionItemTypeId: this.revisionItemTypeId,
			portalAnnexId: this.portalAnnexId,
			portalTechnologyId: this.portalPlanTechnologyId,
			portalPlanTypeId: this.portalPlanTypeId
		});
		if (!(this.revisionItemTypeId === null || this.revisionItemTypeId.trim() === '')) {
			if (!this.bcService.isBusinessContinuityAuditor) {
				this.setRevisionItem(bcRevisionStateType.inProcess);
			}
			else {
				if (this.portalFunctionalAreaId) {
					this.bcService.getPortalFunctionalAreasByUser(this.siteId).then((res: any) => {
						const pfa: PortalFunctionalArea = res.portalFunctionalAreas.find((p: PortalFunctionalArea) => p.portalFunctionalAreaId === this.portalFunctionalAreaId);
						if (pfa && this.canManageDepartment(pfa)) {
							this.setRevisionItem(bcRevisionStateType.inProcess);
						}
					});
				}
				else if (this.portalProcessId) {
					this.bcService.getPortalProcess(this.portalProcessId).then((res: any) => {
						if (res.portalProcess && this.canManageProcess(res.portalProcess)) {
							this.setRevisionItem(bcRevisionStateType.inProcess);
						}
					});
				}
			}
		}

		this.planTypeId = this.bcService.getPlanTypeIdByPortalPlanTypeId(this.portalPlanTypeId);
	}

	setComplete() {
		if (!this.bcService.isBusinessContinuityAuditor) {
			this.setRevisionItem(bcRevisionStateType.complete);
		}
		else {
			if (this.portalProcessId) {
				this.bcService.getPortalProcess(this.portalProcessId).then((res: any) => {
					if (res.portalProcess && this.canManageProcess(res.portalProcess)) {
						this.setRevisionItem(bcRevisionStateType.complete);
					}
				});
			}
			else if (this.portalFunctionalAreaId) {
				this.bcService.getPortalFunctionalAreasByUser(this.siteId).then((res: any) => {
					const pfa: PortalFunctionalArea = res.portalFunctionalAreas.find((p: PortalFunctionalArea) => p.portalFunctionalAreaId === this.portalFunctionalAreaId);
					if (pfa && this.canManageDepartment(pfa)) {
						this.setRevisionItem(bcRevisionStateType.complete);
					}
				});
			}
		}
	}

	setRevisionItem(bcRevisionStateType) {
		if (!(this.revisionItemTypeId === null || this.revisionItemTypeId.trim() === '')) {
			const ri = new RevisionItem(this.siteId, this.portalFunctionalAreaId, this.portalProcessId, this.portalAnnexId, this.portalPlanTechnologyId, this.revisionItemTypeId, bcRevisionStateType);
			this.bcService.setSiteRevisionItem(ri, this.portalPlanTypeId)
				.then((res: any) => {
					// do nothing for now
				})
				.catch((err: any) => {
					this.toastrService.error(this.translateService.instant('errorMessages.errorUpdatingPageStatus'));
				});
		}
	}

	getRTOName(impactTimespanId: string): string {
		return this.bcService.getRTOTimespanName(impactTimespanId);
	}

	getImpactSeverityName(impactSeverityId: string) {
		return this.bcService.getImpactSeverityName(impactSeverityId);
	}

	getImpactCategoryName(impactCategoryId: string): string {
		return this.bcService.getImpactCategoryName(impactCategoryId);
	}

	getImpactCategoryDescription(impactCategoryId: string): string {
		return this.bcService.getImpactCategoryDescription(impactCategoryId);
	}

	canNavigateDepartment(pfa: PortalFunctionalArea): boolean {
		return this.canManageDepartment(pfa) || this.canAccessDepartment(pfa);
	}
}
