import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConfirmationModalComponent } from '@app/components/modals/confirmation-modal/confirmation-modal.component';
import { BusinessContinuityService } from '@app/providers/business-continuity.service';
import { PortalSuccessionPerson } from '@app/shared/business-continuity/portal-orders-of-succession-person.model';
import { PortalOrdersOfSuccession } from '@app/shared/business-continuity/portal-orders-of-succession.model';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { MenuItem } from 'primeng/api';
import { BreadCrumb } from '@app/shared/breadcrumb.model';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
	selector: 'app-bc-orders-of-successions',
	templateUrl: './bc-orders-of-successions.component.html',
	styleUrls: ['./bc-orders-of-successions.component.scss']
})
export class BcOrdersOfSuccessionsComponent implements OnChanges {
	@Input() siteId: string;
	@Input() portalFunctionalAreaId: string;
	@Input() functionalAreaId: string;
	@Input() portalPlanTypeId: string;
	@Input() ordersOfSuccessions: PortalOrdersOfSuccession[] = [];
	@Input() isBusinessContinuityAuditor: boolean;
	@Input() canManageDepartment: boolean;
	@Output() updateOOSList: EventEmitter<null> = new EventEmitter<null>();
	options: MenuItem[][] = [];
	@Input() isIndustryDefault: boolean = false;
	departmentId: string;
	breadCrumbs: Array<BreadCrumb>;
	showDetails: boolean = false;
	currentOOSId: string;

	constructor(public translateService: TranslateService,
		private businessContinuityService: BusinessContinuityService,
		private toastrService: ToastrService,
		public route: ActivatedRoute,
		private dialogService: DialogService) {
		this.isIndustryDefault = this.route.snapshot.data?.isIndustryDefault === true;
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.ordersOfSuccessions) {
			this.getOptions();
		}
	}

	getOptions() {
		this.ordersOfSuccessions.map((o) => {
			this.options[o.portalOrderOfSuccessionId] = this.canManageDepartment
				? [
					{
						label: this.translateService.instant('common.edit'),
						command: () => this.navigateToEditOOS(o.portalOrderOfSuccessionId)
					},
					{
						label: this.translateService.instant('common.delete'),
						command: () => this.openDeleteModal(o.portalOrderOfSuccessionId)
					}
				]
				: [
					{
						label: this.translateService.instant('common.view'),
						command: () => this.navigateToEditOOS(o.portalOrderOfSuccessionId)
					}
				];
		});
	}

	navigateToEditOOS(portalOrderOfSuccessionId: string) {
		this.currentOOSId = portalOrderOfSuccessionId;
		this.showDetails = true;
	}

	navigateToCreateOOS() {
		this.showDetails = true;
	}

	handleHideDetails() {
		this.showDetails = false;
		this.currentOOSId = null;
	}

	openDeleteModal(portalOrderOfSuccessionId: string) {
		const modalRef: DynamicDialogRef = this.dialogService.open(ConfirmationModalComponent, {
			header: this.translateService.instant('businessContinuity.ordersOfSuccession.removeOrderOfSuccession'),
			data: {
				body: this.translateService.instant('businessContinuity.ordersOfSuccession.removeOrderOfSuccessionDescription'),
				confirmButtonText: this.translateService.instant('common.yes'),
				cancelButtonText: this.translateService.instant('common.cancel')
			}
		});

		modalRef.onClose.subscribe((res) => {
			if (res) {
				this.handleDeleteOOS(portalOrderOfSuccessionId);
			}
		});
	}

	handleDeleteOOS(portalOrderOfSuccessionId: string) {
		const deleteMethod = this.isIndustryDefault
			? this.businessContinuityService.deleteOrderOfSuccession(portalOrderOfSuccessionId)
			: this.businessContinuityService.deletePortalOrderOfSuccession(portalOrderOfSuccessionId, this.portalPlanTypeId, this.portalFunctionalAreaId);

		deleteMethod.then(() => {
			this.toastrService.success(this.translateService.instant('businessContinuity.ordersOfSuccession.successOnDelete'));
		})
			.catch(() => this.toastrService.error(this.translateService.instant('businessContinuity.ordersOfSuccession.failedToDelete')))
			.finally(() => this.getUpdatedOOSList());
	}

	getUpdatedOOSList() {
		this.updateOOSList.emit();
	}

	getChipInfo(person: PortalSuccessionPerson, primary?: boolean) {
		let chip;

		if (primary) {
			chip = `${person?.firstName} ${person?.lastName}`;
			if (chip.length > 41) {
				chip = chip.substring(0, 37) + '...';
			}
		}
		else {
			chip = `${person?.firstName} ${person?.lastName}`;
			if (chip.length > 41) {
				chip = chip.substring(0, 35) + '...';
			}
		}

		return chip;
	}
}
