import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { defaultImageAddress, dialogueWidths, specialCharacters } from '@app/app.constants';
import { PortalOrdersOfSuccession } from '@app/shared/business-continuity/portal-orders-of-succession.model';
import { User } from '@app/shared/user.model';
import { MenuItem } from 'primeng/api';
import { BcSelectUserModalComponent } from '../../modals/bc-select-user/bc-select-user-modal.component';
import { BusinessContinuityService } from '@app/providers/business-continuity.service';
import { PortalSuccessionPerson } from '@app/shared/business-continuity/portal-orders-of-succession-person.model';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { SpinnerService } from '@app/providers/spinner.service';
import { BreadCrumb } from '@app/shared/breadcrumb.model';
import { OrderOfSuccession } from '@app/shared/business-continuity/plan-builder/order-of-succession';
import { FormService } from '@app/providers/form/form.service';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
	selector: 'app-bc-orders-of-succession',
	templateUrl: './bc-orders-of-succession.component.html',
	styleUrls: ['./bc-orders-of-succession.component.scss']
})
export class BcOrdersOfSuccessionComponent implements OnInit {
	primaryOptions: MenuItem[] = [];
	alternateOptions: MenuItem[][] = [];
	orderOfSuccession: PortalOrdersOfSuccession;
	@Input() orderId: string;
	@Input() portalFunctionalAreaId: string;
	@Input() portalPlanTypeId: string;
	@Input() siteId: string;
	@Input() canManageDepartment: boolean;
	primary: PortalSuccessionPerson = null;
	alternates: User[] = [];
	mappedAlternates: PortalSuccessionPerson[] = [];
	alternatesIds: string[] = [];
	primaryId: string[] = [];
	defaultImage = defaultImageAddress.profile;
	public specialCharacters = specialCharacters;
	@Input() isIndustryDefault: boolean = false;
	@Input() functionalAreaId: string;
	@Output() hideDetails: EventEmitter<null> = new EventEmitter<null>();
	@Output() oosUpdated: EventEmitter<null> = new EventEmitter<null>();
	@Input() showTitle: boolean = false;
	departmentId: string;
	breadCrumbs: Array<BreadCrumb>;
	String = String;

	constructor(private bcService: BusinessContinuityService,
		private toastrService: ToastrService,
		public translateService: TranslateService,
		private spinnerService: SpinnerService,
		private formService: FormService,
		public elementRef: ElementRef,
		private dialogService: DialogService) {
	}

	ngOnInit(): void {
		this.spinnerService.start();
		this.primaryOptions = [
			{
				label: this.translateService.instant('common.delete'),
				command: () => this.removeUser()
			}
		];

		if (this.orderId) {
			const getMethod = this.isIndustryDefault
				? this.bcService.getOrderOfSuccession(this.orderId)
				: this.bcService.getPortalOrderOfSuccession(this.orderId, this.portalPlanTypeId, this.portalFunctionalAreaId);
			const promises = [getMethod].filter(p => p);

			Promise.all(promises).then(([res, industry, functionalArea]: any) => {
				if (this.isIndustryDefault) {
					this.orderOfSuccession = this.mapToPortalOOS(res.orderOfSuccession);
				}
				else {
					this.orderOfSuccession = res.portalOrderOfSuccession;
					this.alternatesIds = this.orderOfSuccession.portalSuccessionPersonList.map(p => p.userId);
					this.primary = this.orderOfSuccession.primaryPerson ? this.orderOfSuccession.primaryPerson : null;
					this.mappedAlternates = this.orderOfSuccession.portalSuccessionPersonList ? this.orderOfSuccession.portalSuccessionPersonList : null;
					this.primaryId = this.primary ? [this.primary.userId] : [];
					if (this.mappedAlternates) {
						this.mapToUserAlternates();
						this.setAlternateOptions();
					}
				}
				this.spinnerService.stop();
			});
		}
		else {
			this.orderOfSuccession = new PortalOrdersOfSuccession();
			this.orderOfSuccession.positionTitle = '';
			this.orderOfSuccession.portalFunctionalAreaId = this.portalFunctionalAreaId;
			this.orderOfSuccession.portalPlanTypeId = this.portalPlanTypeId;
			this.orderOfSuccession.portalSuccessionPersonList = [];
			this.spinnerService.stop();
		}
	}

	openUsersSelectModal(isPrimary?: boolean) {
		const ngbModalRef: DynamicDialogRef = this.dialogService.open(BcSelectUserModalComponent, {
			width: dialogueWidths.xlg,
			styleClass: 'bc-select-user-modal',
			data: {
				baseResourcePath: isPrimary
					? 'businessContinuity.ordersOfSuccession.selectPrimaryUser'
					: 'businessContinuity.ordersOfSuccession.selectAlternateUser',
				includesMessageOnly: true,
				hasMultipleSelections: !isPrimary,
				maxSelectCount: 3,
				filterOutIds: [...this.primaryId, ...this.alternatesIds],
				filterUsersListById: true
			}
		});

		ngbModalRef.onClose.subscribe((incomingUsers: Array<User>) => {
			this.addUsers(incomingUsers, isPrimary);
		});
	}

	addUsers(incomingUsers: Array<User>, isPrimary?: boolean) {
		if (isPrimary) {
			const newPrimary = incomingUsers[0];
			const mappedPrimary: PortalSuccessionPerson = new PortalSuccessionPerson();
			mappedPrimary.userId = newPrimary.userId;
			mappedPrimary.firstName = newPrimary.firstName;
			mappedPrimary.lastName = newPrimary.lastName;
			mappedPrimary.userProfileImage = newPrimary.profileURL;
			mappedPrimary.primaryLocationName = newPrimary.locationEntityName;
			mappedPrimary.title = newPrimary.title;
			mappedPrimary.successionOrder = 0;
			mappedPrimary.portalSuccessionPersonId = newPrimary.userId;

			this.primary = mappedPrimary;
			this.primaryId = [this.primary.userId];
		}
		else {
			this.alternates = [...this.alternates, ...incomingUsers];
			this.mapAlternates();
			this.setAlternateOptions();
		}
	}

	navigateToOOSList() {
		this.hideDetails.emit();
	}

	onSaveButtonClick() {
		this.formService.isInvalid(this.elementRef);

		if (!this.isValid()) {
			this.toastrService.error(this.translateService.instant('errorMessages.msgValidateRequired'));
			return;
		}

		if (this.primary) {
			this.orderOfSuccession.primaryPerson = new PortalSuccessionPerson();
			this.orderOfSuccession.primaryPerson.userId = this.primary.userId;
			this.orderOfSuccession.primaryPerson.firstName = this.primary.firstName;
			this.orderOfSuccession.primaryPerson.lastName = this.primary.lastName;
			this.orderOfSuccession.primaryPerson.successionOrder = 0;
			this.orderOfSuccession.primaryPerson.title = this.primary.title;
			this.orderOfSuccession.primaryPerson.portalSuccessionPersonId = this.primary.userId;

			if (this.mappedAlternates) {
				this.orderOfSuccession.portalSuccessionPersonList = this.mappedAlternates;
			}
		}
		else {
			this.orderOfSuccession.primaryPerson = null;
		}

		const saveMethod = this.isIndustryDefault
			? this.bcService.saveOrderOfSuccession(this.mapToOOS(this.orderOfSuccession))
			: this.bcService.savePortalOrderOfSuccession(this.orderOfSuccession);

		saveMethod.then((_) => {
			this.toastrService.success(this.translateService.instant('businessContinuity.ordersOfSuccession.orderSavedSuccessfully'));
			this.navigateToOOSList();
			this.oosUpdated.next();
		}).catch((_) => {
			this.toastrService.error(this.translateService.instant('businessContinuity.ordersOfSuccession.orderSavedUnsuccessfully'));
		});
	}

	isValid(): boolean {
		const titleValidation = !String.isNullOrEmpty(this.orderOfSuccession?.positionTitle);

		return !this.isIndustryDefault ? this.primary
		  && this.alternates.length > 0 && titleValidation : titleValidation;
	}

	mapAlternates() {
		this.mappedAlternates = this.alternates?.map((a, index) => {
			const sp = new PortalSuccessionPerson();
			sp.userId = a.userId;
			sp.firstName = a.firstName;
			sp.lastName = a.lastName;
			sp.portalSuccessionPersonId = a.userId;
			sp.userProfileImage = a.profileURL;
			sp.title = a.title;
			sp.successionOrder = index + 1;
			return sp;
		});
		this.alternatesIds = this.mappedAlternates.map(a => a.userId);
	}

	mapToUserAlternates() {
		this.alternates = this.mappedAlternates?.map((sp: PortalSuccessionPerson, i: number) => {
			const a = new User();
			a.userId = sp.userId;
			a.firstName = sp.firstName;
			a.lastName = sp.lastName;
			a.profileURL = sp.userProfileImage;
			a.title = sp.title;
			return a;
		});
	}

	setAlternateOptions() {
		this.mappedAlternates.map((a) => {
			this.alternateOptions[a.userId] = [
				{
					label: this.translateService.instant('common.delete'),
					command: () => this.removeUser(a.userId)
				}
			];
		});
	}

	removeUser(alternateId?: string) {
		if (alternateId) {
			this.alternates = this.alternates.filter(u => u.userId !== alternateId);
			this.mappedAlternates = this.mappedAlternates.filter(u => u.userId !== alternateId);
			this.alternatesIds = this.alternatesIds.filter(id => id !== alternateId);
			if (this.mappedAlternates.length > 0) {
				this.setAlternateOptions();
			}
		}
		else {
			this.primary = null;
			this.primaryId = [];
		}
	}

	mapToPortalOOS(OOS: OrderOfSuccession[]) {
		const portalOOS = new PortalOrdersOfSuccession();
		for (const [key, value] of Object.entries(OOS)) {
			if (key == 'functionalAreaId' || key == 'orderOfSuccessionId') {
				// cuts the portal of the property and make the first letter upper case
				portalOOS['portal' + key.charAt(0).toUpperCase() + key.slice((1))] = value;
			}
			else {
				portalOOS[key] = value;
			}
		}
		return portalOOS;
	}

	mapToOOS(OOS: PortalOrdersOfSuccession) {
		const defaultOOS = new OrderOfSuccession();
		for (const [key, value] of Object.entries(OOS)) {
			if (key.includes('portal')) {
				// cuts the portal of the property and make the first letter lower case
				defaultOOS[key.slice(6).charAt(0).toLowerCase() + key.slice(7)] = value;
			}
			else {
				defaultOOS[key] = value;
			}
		}
		defaultOOS.functionalAreaId = this.functionalAreaId;
		return defaultOOS;
	}
}
