import { PortalSuccessionPerson } from '@app/shared/business-continuity/portal-orders-of-succession-person.model';

export class PortalOrdersOfSuccession {
	dateTimeLastModified: Date;
	portalFunctionalAreaId: string;
	portalOrderOfSuccessionId: string;
	portalId: string;
	portalPlanTypeId: string;
	positionTitleAsBase64: string = '';
	positionTitle: string;
	primaryPerson?: PortalSuccessionPerson;
	portalSuccessionPersonList?: PortalSuccessionPerson[];
}
