import { Component, OnInit, ViewChild } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { TechnologyType } from '@app/shared/business-continuity/technology-type.model';
import { TranslateService } from '@ngx-translate/core';
import { Table } from 'primeng/table';
import { FormService } from '@app/providers/form/form.service';
import { Utility } from '@app/providers/utility';
import { ToastrService } from 'ngx-toastr';
import { BusinessContinuityService } from '@app/providers/business-continuity.service';
import { ConfirmationService } from 'primeng/api';
import { guid } from '@app/app.constants';

@Component({
	selector: 'app-technology-types-modal',
	templateUrl: './technology-types-modal.component.html',
	styleUrls: ['./technology-types-modal.component.scss']
})
export class TechnologyTypesModalComponent implements OnInit {
	technologyTypes: any[];
	technologyTypesBackUp: any[];
	loading: boolean = true;
	@ViewChild('table', { static: true }) private table: Table;
	canEdit: boolean = true;
	empty = guid.empty;
	constructor(public dynamicDialogRef: DynamicDialogRef,
		public dynamicDialogConfig: DynamicDialogConfig,
		private confirmationService: ConfirmationService,
		private translateService: TranslateService,
		public formService: FormService,
		public toastrService: ToastrService,
		private utilityS: Utility,
		private bcService: BusinessContinuityService) {
	}

	ngOnInit(): void {
		this.bcService.getTechnologyTypes().then((res: any) => {
			this.technologyTypes = res.technologyTypes;
			this.technologyTypesBackUp = structuredClone(this.technologyTypes);
			this.loading = false;
		});
	}

	save(technologyType: TechnologyType) {
		if (this.utilityS.isNullOrEmpty(technologyType.technologyTypeName)) {
			this.toastrService.error(this.translateService.instant('errorMessages.msgValidateRequired'));
			return this.table.initRowEdit(technologyType);
		}
		this.bcService.saveTechnologyType(technologyType).then(() => {
			this.toastrService.success(this.translateService.instant('businessContinuity.continuityStrategy.technologyModal.technologyTypeSavedSuccessfully'));
			if (technologyType.isNew) {
				this.technologyTypesBackUp.push(technologyType);
			}
			else {
				this.technologyTypesBackUp.find(tech => tech.technologyTypeId == technologyType.technologyTypeId).name = technologyType.technologyTypeName;
			}
			technologyType.isNew = false;
		}).catch(() => {
			this.toastrService.error(this.translateService.instant('businessContinuity.continuityStrategy.technologyModal.technologyTypeSavedUnSuccessfully'));
		});
	}

	cancelEdit(technologyType: TechnologyType) {
		if (technologyType.isNew) {
			this.technologyTypes = this.technologyTypes.filter((tec: TechnologyType) => tec.technologyTypeId !== technologyType.technologyTypeId);
			return;
		}
		const originalName = this.technologyTypesBackUp.find(tech => tech.technologyTypeId == technologyType.technologyTypeId).technologyTypeName;
		technologyType.technologyTypeName = originalName;
	}

	delete(technologyType: TechnologyType) {
		this.confirmationService.confirm({
			message: this.translateService.instant('businessContinuity.continuityStrategy.technologyModal.technologyTypeDeleteBody'),
			header: this.translateService.instant('businessContinuity.continuityStrategy.technologyModal.technologyTypeDeleteHeader'),
			acceptLabel: this.translateService.instant('common.yes'),
			rejectLabel: this.translateService.instant('common.cancel'),
			accept: () => {
				this.bcService.deleteTechnologyType(technologyType.technologyTypeId).then(() => {
					this.toastrService.success(this.translateService.instant('businessContinuity.continuityStrategy.technologyModal.technologyTypeDeletedSuccessfully'));
					this.technologyTypes = this.technologyTypes.filter((tec: TechnologyType) => tec.technologyTypeId !== technologyType.technologyTypeId);
					this.technologyTypesBackUp = structuredClone(this.technologyTypes);
				}).catch(() => {
					this.toastrService.error(this.translateService.instant('businessContinuity.continuityStrategy.technologyModal.technologyTypeDeletedUnSuccessfully'));
				});
			},
			rejectIcon: 'null',
			acceptIcon: 'null'
		});
	}

	new() {
		const newType = new TechnologyType();
		newType.technologyTypeId = this.utilityS.getGuid();
		newType.isNew = true;
		newType.technologiesCount = 0;
		newType.portalId = this.utilityS.getCurrentPortalId();
		this.technologyTypes.push(newType);
		this.table.initRowEdit(newType);
	}

	close() {
		this.dynamicDialogRef.close();
	}
}
