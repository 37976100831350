import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DialogService } from 'primeng/dynamicdialog';
import { SpinnerService } from '@app/providers/spinner.service';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ModalTextEditorComponent } from '@app/components/exercise-manager/scenario-manager/modal-text-editor/modal-text-editor.component';
import { User } from '@app/shared/user.model';
import { BaseBusinessContinuityComponent } from '@app/components/base-form/base-business-continuity.component';
import { bcRevisionItemTypes, dialogueWidths } from '@app/app.constants';
import { BusinessContinuityService } from '@app/providers/business-continuity.service';
import { PortalFunctionalArea } from '@app/shared/business-continuity/portal-functional-area.model';
import { PortalDelegatedAgent, PortalDelegationOfAuthority } from '@app/shared/business-continuity/portal-delegation-of-authority';
import { UserService } from '@app/providers/user.service';
import { DelegationOfAuthority } from '@app/shared/business-continuity/delegation-of-authority';
import { BreadCrumb } from '@app/shared/breadcrumb.model';
import { FormService } from '@app/providers/form/form.service';

@Component({
	selector: 'app-bc-authority-delegation',
	templateUrl: './bc-authority-delegation.component.html',
	styleUrls: ['./bc-authority-delegation.component.scss']
})
export class BcAuthorityDelegationComponent extends BaseBusinessContinuityComponent implements OnInit {
	actingUsers: any[] = [];
	delegatedUsers: any[] = [];
	delegation: PortalDelegationOfAuthority = new PortalDelegationOfAuthority();
	public loading: boolean = true;
	@Input() portalDelegationId: string;
	portalFunctionalArea: PortalFunctionalArea;
	portalPlanTypeId: string;
	@Input() isIndustryDefault: boolean;
	functionalAreaId: string;
	@Input() industryId: string;
	departmentId: string;
	backRoute: Array<string>;
	breadCrumbs: Array<BreadCrumb>;
	@Input() canManage: boolean;
	@Output() back: EventEmitter<boolean> = new EventEmitter<boolean>();
	String = String;

	constructor(public translateService: TranslateService,
		private dialogService: DialogService,
		private spinner: SpinnerService,
		public bcService: BusinessContinuityService,
		public toastrService: ToastrService,
		public route: ActivatedRoute,
		private userService: UserService,
		public elementRef: ElementRef,
		private formService: FormService) {
		super(route, bcService, bcRevisionItemTypes.none, toastrService, translateService);

		this.functionalAreaId = route.snapshot.params?.functionalAreaId;
		this.departmentId = route.snapshot.params.functionalAreaId;
		this.portalPlanTypeId = route.snapshot.data.portalPlanTypeId;

		if (this.isIndustryDefault) {
			this.backRoute = ['business-continuity/admin/industry/', this.industryId, 'department', this.functionalAreaId, 'authority-delegations'];
		}
		else {
			this.backRoute = ['business-continuity/planner', this.portalPlanTypeId, this.siteId, this.portalFunctionalAreaId, 'authority-delegations'];
		}

		this.portalDelegationId = this.route.snapshot.params.portalDelegationId;
	}

	ngOnInit(): void {
		this.initializeData();
	}

	openEditor(field) {
		const fieldTranslate = this.translateService.instant('businessContinuity.authorityDelegation.' + field);
		const ref = this.dialogService.open(ModalTextEditorComponent, {
			data: {
				value: this.delegation[field],
				isScenario: false,
				customHeader: this.translateService.instant('businessContinuity.authorityDelegation.modalMsg', { field: fieldTranslate })
			},
			header: fieldTranslate,
			width: dialogueWidths.md
		});
		ref.onClose.subscribe((el) => {
			if (el) {
				this.delegation[field] = el;
			}
		});
	}

	addActive(users: User[]) {
		this.actingUsers = users;
	}

	addDelegated(users: User[]) {
		this.delegatedUsers = users;
	}

	mapUserToDelegatePerson(users: User[], isActing = false) {
		return users.map((user) => {
			const agent = new PortalDelegatedAgent();
			agent.portalDelegatedAgentId
                = agent.userId = user.userId;
			agent.firstName = user.firstName;
			agent.lastName = user.lastName;
			agent.portalDelegationOfAuthorityId = this.delegation.portalDelegationOfAuthorityId;
			agent.isActingAgent = isActing;
			return agent;
		});
	}

	saveDelegation() {
		if (this.formService.isInvalid(this.elementRef)) {
			return;
		}

		this.spinner.start('delegation-spinner');
		const saveMethod = this.isIndustryDefault
			? this.bcService.saveDelegationOfAuthority(this.mapToDOA(this.delegation))
			: this.bcService.savePortalDelegationOfAuthority(this.mapDelegation());
		saveMethod.then((res) => {
			this.toastrService.success(this.translateService.instant('businessContinuity.authorityDelegation.saveSuccess'));
			this.back.emit(true);
		}).catch(() => {
			this.toastrService.error(this.translateService.instant('businessContinuity.authorityDelegation.saveError'));
		}).finally(() => {
			this.spinner.stop('delegation-spinner');
		});
	}

	getSelectedAgents() {
		const promises = [];
		let users = [];
		this.spinner.start('delegation-spinner');
		this.delegation.portalDelegatedAgents.forEach((agent) => {
			promises.push(this.userService.getUserProfile(agent.userId));
		});
		Promise.all(promises).then((p) => {
			users = p.map((user: any) => user.profileFull.profile);
			this.actingUsers = users.filter(u => this.delegation.portalDelegatedAgents.filter(agent => agent.isActingAgent).map(a => a.userId).includes(u.userId));
			this.delegatedUsers = users.filter(u => this.delegation.portalDelegatedAgents.filter(agent => !agent.isActingAgent).map(a => a.userId).includes(u.userId));
		}).finally(() => this.spinner.stop('delegation-spinner'));
	}

	mapDelegation() {
		const actionUsers = this.mapUserToDelegatePerson(this.actingUsers, true);
		const delegatedUsers = this.mapUserToDelegatePerson(this.delegatedUsers);
		this.delegation.portalDelegatedAgents = [...actionUsers, ...delegatedUsers];
		this.delegation.portalFunctionalAreaId = this.portalFunctionalAreaId;
		this.delegation.portalPlanTypeId = this.portalPlanTypeId;
		return this.delegation;
	}

	goBack() {
		this.back.emit(false);
	}

	filterOutUsers() {
		return [...this.actingUsers.map(u => u.userId), ...this.delegatedUsers.map(u => u.userId)];
	}

	mapToPortalDOA(DOA: DelegationOfAuthority) {
		const portalDOA = new PortalDelegationOfAuthority();
		for (const [key, value] of Object.entries(DOA)) {
			if (key.includes('delegation') && key !== 'delegationOfAuthorityStatusTypeId') {
				// cuts the portal of the property and make the first letter upper case
				portalDOA['portal' + key.charAt(0).toUpperCase() + key.slice((1))] = value;
			}
			else {
				portalDOA[key] = value;
			}
		}
		return portalDOA;
	}

	mapToDOA(DOA: PortalDelegationOfAuthority) {
		const defaultDOA = new DelegationOfAuthority();
		for (const [key, value] of Object.entries(DOA)) {
			if (key.includes('portal')) {
				// cuts the portal of the property and make the first letter lower case
				defaultDOA[key.slice(6).charAt(0).toLowerCase() + key.slice(7)] = value;
			}
			else {
				defaultDOA[key] = value;
			}
		}
		defaultDOA.functionalAreaId = this.functionalAreaId;
		return defaultDOA;
	}

	getIndustryInfo() {
		this.spinner.start('delegation-spinner');
		const promises = [this.bcService.getIndustry(this.industryId),
			this.bcService.getFunctionalArea(this.functionalAreaId)];
		Promise.all(promises).then(([industry, functionalArea]: any) => {
			this.breadCrumbs = [
				{
					name: this.translateService.instant('layout.admin'),
					state: ''
				},
				{
					name: this.translateService.instant('businessContinuity.admin.template.industries'),
					state: '/business-continuity/admin/industries'
				},
				{
					name: industry.industry.industryName,
					state: `/business-continuity/admin/industry/${industry.industry.industryId}`
				},
				{
					name: functionalArea.functionalArea.functionalAreaName,
					state: ''
				},
				{
					name: this.translateService.instant('businessContinuity.authorityDelegation.headerList'),
					state: `/business-continuity/admin/industry/${industry.industry.industryId}/department/${functionalArea.functionalArea.functionalAreaId}/authority-delegations`
				},
				{
					name: this.portalDelegationId ? this.delegation.portalDelegationOfAuthorityName : this.translateService.instant('businessContinuity.authorityDelegation.headerDetails'),
					state: ''
				}
			];
		}).finally(() => this.spinner.stop('delegation-spinner'));
	}

	initializeData() {
		let getMethod = null;
		if (this.portalDelegationId) {
			getMethod = this.isIndustryDefault
				? this.bcService.getDelegationOfAuthority(this.portalDelegationId)
				: this.bcService.getPortalDelegationOfAuthority(this.portalDelegationId, this.portalPlanTypeId, this.portalFunctionalAreaId);
			this.spinner.start('delegation-spinner');
		}
		const promises = [this.bcService.getPortalFunctionalArea(this.portalFunctionalAreaId),
			getMethod, this.isIndustryDefault && this.bcService.getIndustry(this.industryId),
			this.isIndustryDefault && this.bcService.getFunctionalArea(this.departmentId)].filter(p => p);

		Promise.all(promises).then(([p, delegation]: any) => {
			this.portalFunctionalArea = p.portalFunctionalArea;
			if (this.portalDelegationId && delegation) {
				if (this.isIndustryDefault) {
					this.delegation = this.mapToPortalDOA(delegation.delegationOfAuthority);
				}
				else {
					this.delegation = delegation.portalDelegationOfAuthority;
					this.getSelectedAgents();
				}
			}
			if (this.isIndustryDefault) {
				this.getIndustryInfo();
			}
		}).finally(() => this.spinner.stop('delegation-spinner'));
	}
}
