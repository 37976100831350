import { Component, ElementRef, OnInit } from '@angular/core';
import { Utility } from '@app/providers/utility';
import { ModalReturnType } from '@app/app.enum';
import { UntypedFormBuilder, FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Technology } from '@app/shared/business-continuity/technology.model';
import { guid, timeDurationUnit } from '@app/app.constants';
import { TranslateService } from '@ngx-translate/core';
import { distinctUntilChanged } from 'rxjs/operators';
import { TechnologyType } from '@app/shared/business-continuity/technology-type.model';
import { BusinessContinuityService } from '@app/providers/business-continuity.service';
import { SpinnerService } from '@app/providers/spinner.service';
import { FormService } from '@app/providers/form/form.service';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
	selector: 'app-bc-technology-modal',
	templateUrl: './bc-technology-modal.component.html',
	styleUrls: ['./bc-technology-modal.component.scss']
})
export class BcTechnologyModalComponent implements OnInit {
	technologyForm: UntypedFormGroup;
	technology: Technology;
	selectRPOTime: string;
	submitted: boolean = false;
	String = String;
	technologyTypes: any[] = [];
	rpoTypes = [
		{
			label: this.translateService.instant('businessContinuity.continuityStrategy.technologyModal.selectRPOTime'),
			value: null
		},
		{
			label: this.translateService.instant('businessContinuity.continuityStrategy.technologyModal.seconds'),
			value: timeDurationUnit.second
		},
		{
			label: this.translateService.instant('businessContinuity.continuityStrategy.technologyModal.minutes'),
			value: timeDurationUnit.minute
		},
		{
			label: this.translateService.instant('businessContinuity.continuityStrategy.technologyModal.hours'),
			value: timeDurationUnit.hour
		},
		{
			label: this.translateService.instant('businessContinuity.continuityStrategy.technologyModal.days'),
			value: timeDurationUnit.day
		}];

	constructor(private utility: Utility,
		public translateService: TranslateService,
		private fb: UntypedFormBuilder,
		private bcService: BusinessContinuityService,
		private spinnerS: SpinnerService,
		public elementRef: ElementRef,
		private formService: FormService,
		public dynamicDialogRef: DynamicDialogRef,
		public dynamicDialogConfig: DynamicDialogConfig) {
		this.technologyForm = this.fb.group({
			technologyName: ['', [Validators.required, this.utility.noWhitespaceValidator]],
			rpoTime: [''],
			rpoTimeDurationUnitId: [''],
			technologyTypeId: [guid.empty],
			tier: ['']
		});

		this.dynamicDialogConfig.header = this.translateService.instant('businessContinuity.continuityStrategy.technologyModal.title');
		this.technology = this.dynamicDialogConfig.data.technology;
	}

	get rpoTimeValid(): boolean {
		return this.technologyForm.get('rpoTime').valid;
	}

	ngOnInit(): void {
		this.spinnerS.start('technologyModal');
		this.bcService.getTechnologyTypes().then((res: any) => {
			this.technologyTypes = res.technologyTypes;
			this.technologyTypes.unshift({ technologyTypeId: guid.empty, technologyTypeName: '  ' });
		}).finally(() => {
			this.spinnerS.stop('technologyModal');
		});
		this.selectRPOTime = this.translateService.instant('businessContinuity.continuityStrategy.technologyModal.selectRPOTime');

		if (this.technology.technologyId) {
			this.technologyForm.get('technologyName').setValue(this.technology.technologyName);
			this.technologyForm.get('technologyTypeId').setValue(this.technology?.technologyType?.technologyTypeId);
			this.technologyForm.get('tier').setValue(this.technology.tier);
			this.technologyForm.get('rpoTime').setValue(this.technology.rpoTime);
			this.technologyForm.get('rpoTimeDurationUnitId').setValue(this.technology.rpoTimeDurationUnitId);
		}

		this.technologyForm
			.valueChanges
			.pipe(distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)))
			.subscribe((_) => {
				const rpoTime = this.technologyForm.get('rpoTime');
				const rpoTimeDurationUnit = this.technologyForm.get('rpoTimeDurationUnitId');

				if (rpoTime.value === 0) {
					rpoTime.setValue(null);
				}

				if (rpoTimeDurationUnit.value) {
					rpoTime.setValidators([Validators.required, Validators.min(1)]);
				}

				if (rpoTime.value) {
					const absoluteValue = Math.abs(rpoTime.value);
					rpoTime.setValue(absoluteValue);
					rpoTime.setValidators([Validators.required, Validators.min(1)]);
					rpoTimeDurationUnit.setValidators([Validators.required]);
				}

				if (!rpoTime.value && !rpoTimeDurationUnit.value) {
					rpoTime.setValidators([]);
					rpoTimeDurationUnit.setValidators([]);
					rpoTimeDurationUnit.setValue(null);
				}

				rpoTime.updateValueAndValidity();
				rpoTimeDurationUnit.updateValueAndValidity();
			});
	}

	dismiss() {
		this.dynamicDialogRef.close(ModalReturnType.cancel);
	}

	save() {
		this.submitted = true;
		if (this.formService.isInvalid(this.elementRef)) {
			return;
		}
		let technologyType;
		if (this.technologyForm.get('technologyTypeId').value == guid.empty) {
			technologyType = null;
		}
		else {
			technologyType = this.technologyTypes.find((tec: TechnologyType) => tec.technologyTypeId == this.technologyForm.get('technologyTypeId').value);
		}
		this.technology.technologyName = this.technologyForm.get('technologyName').value;
		this.technology.technologyType = technologyType;
		this.technology.tier = this.technologyForm.get('tier').value;
		this.technology.rpoTime = this.technologyForm.get('rpoTime').value;
		this.technology.rpoTimeDurationUnitId = this.technologyForm.get('rpoTimeDurationUnitId').value;

		if (!this.technology.rpoTime || !this.technology.rpoTimeDurationUnitId) {
			this.technology.rpoTime = null;
			this.technology.rpoTimeDurationUnitId = null;
		}
		this.technology.technologyId = this.technology.technologyId || this.utility.getGuid();

		this.dynamicDialogRef.close(ModalReturnType.save);
	}
}
