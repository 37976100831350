import { Audit } from '@app/shared/audit.model';
import { PortalProcess } from '@app/shared/business-continuity/portal-process.model';
import { PlanTechnology } from '@app/shared/business-continuity/technologies/bc-plan-technology';

export class VendorBase extends Audit {
	vendorId: string;
	vendorName: string;
}
export class Vendor extends VendorBase {
	vendorNameAsBase64: string = '';
	contactName: string;
	contactNameAsBase64: string = '';
	contactPhone: string;
	contactPhoneAsBase64: string = '';
	comments: string;
	commentsAsBase64: string = '';
	portalId?: string;
	vendorStatusTypeId: string;
	assignedPortalProcesses: Array<PortalProcess> = [];
	assignedPlanTypes: Array<any> = [];
	assignedTechnologies: Array<any> = [];
	vendorContacts: Array<VendorContact> = [];
	vendorAddress: VendorAddress = new VendorAddress();
	contractStartDate: Date;
	contractEndDate: Date;
}

export class VendorContact {
	vendorContactId: string = '';
	vendorId: string = '';
	name: string = '';
	nameAsBase64: string = '';
	email: string = '';
	emailAsBase64: string = '';
	title: string = '';
	titleAsBase64: string = '';
	isPrimary: boolean = false;
	phone: string = '';
	phoneAsBase64: string = '';
}

export class VendorAddress {
	vendorAddressId: string = '';
	vendorId: string = '';
	address1: string = '';
	address2: string = '';
	city: string = '';
	state: string = '';
	zip: string = '';
	phone: string = '';
	url: string = '';
	address1AsBase64: string = '';
	address2AsBase64: string = '';
	cityAsBase64: string = '';
	stateAsBase64: string = '';
	zipAsBase64: string = '';
	phoneAsBase64: string = '';
	urlAsBase64: string = '';
	countryTypeId: string = '2d76605b-86b5-40ff-9a71-c4893e5916fb'; // US
	isPrimary: boolean = false;
}

export class DRVendor extends Vendor {
	contacts: VendorContact[] = [];
	technologies: PlanTechnology[] = [];
}
