import { Component, OnInit } from '@angular/core';
import { entityTypeIds, planTargetEntityType, planTemplateTargetTypeIds, planTemplateTypeIds, specialCharacters } from '@app/app.constants';
import { BusinessContinuityService } from '@app/providers/business-continuity.service';
import { Utility } from '@app/providers/utility';
import { PlanTemplateAssignToTargetsModel } from '@app/shared/business-continuity/plan-builder/plan-template-assign.model';
import { PortalFunctionalArea } from '@app/shared/business-continuity/portal-functional-area.model';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { PlanTemplate } from '@app/shared/business-continuity/plan-builder/plan-template.model';
import { EntityService } from '@app/providers/entity.service';
import { Entity } from '@app/shared/manage/entity.model';
import { SpinnerService } from '@app/providers/spinner.service';
import { ModelPlanTemplateTarget } from '@app/shared/business-continuity/plan-builder/plan-template-target.model';
import { ConfirmationService } from 'primeng/api';
import { Technology, TechnologyRequestFilter } from '@app/shared/business-continuity/technology.model';
import { PlanTechnology } from '@app/shared/business-continuity/technologies/bc-plan-technology';
import { Plan } from '@app/shared/plan.model';
import { ActivatedRoute } from '@angular/router';

@Component({
	selector: 'app-assign-templates-modal',
	templateUrl: './assign-templates-modal.component.html',
	styleUrls: ['./assign-templates-modal.component.scss']
})
export class AssignTemplatesModalComponent implements OnInit {
	planTemplate: PlanTemplate;
	siteId: string;
	planTypeName: string = '';
	loaded: boolean = false;
	selectedTargets: ModelPlanTemplateTarget[] = [];
	availableTargets: ModelPlanTemplateTarget[] = [];
	baseDepartments: PortalFunctionalArea[] = [];
	baseTechnologies: PlanTechnology[] = [];
	setDefault: boolean = false;
	readonlyDefault: boolean = false;
	targetTypeIds = planTemplateTargetTypeIds;
	public specialCharacters = specialCharacters;
	titlesByEntityType: { department: string[], location: string[], company: string[], technology: string[] };
	public isCompany: boolean = false;
	public technologiesFilter: TechnologyRequestFilter = {
		pageNumber: 0,
		pageSize: 0,
		searchPhrase: '',
		searchPhraseAsBase64: '',
		sortColumn: '',
		sortOrder: 0,
		technologyTypes: [],
		tiers: []
	};

	constructor(public dynamicDialogRef: DynamicDialogRef,
		public dynamicDialogConfig: DynamicDialogConfig,
		public utility: Utility,
		public bcService: BusinessContinuityService,
		public toastService: ToastrService,
		public translateService: TranslateService,
		public entityService: EntityService,
		public spinner: SpinnerService,
		public route: ActivatedRoute,
		private confirmationService: ConfirmationService
	) {
		this.planTemplate = this.dynamicDialogConfig?.data?.planTemplate;
		this.setDefault = this.dynamicDialogConfig?.data?.planTemplate.isDefault;
		this.readonlyDefault = this.dynamicDialogConfig?.data?.planTemplate.isDefault;
		this.planTypeName = this.translateService.instant(this.dynamicDialogConfig?.data.planTypeName);
	}

	ngOnInit(): void {
		this.titlesByEntityType = {
			department: [
				this.translateService.instant('businessContinuity.planTemplates.selectedDepartments'),
				this.translateService.instant('businessContinuity.planTemplates.availableDepartments'),
				this.translateService.instant('common.departments')
			],
			location: [
				this.translateService.instant('businessContinuity.planTemplates.selectedLocations'),
				this.translateService.instant('businessContinuity.planTemplates.availableLocations'),
				this.translateService.instant('common.locations')
			],
			company: [
				this.translateService.instant('businessContinuity.planTemplates.selectedCompanies'),
				this.translateService.instant('businessContinuity.planTemplates.availableCompanies'),
				this.translateService.instant('common.companies')
			],
			technology: [
				this.translateService.instant('businessContinuity.planTemplates.selectedTechnologies'),
				this.translateService.instant('businessContinuity.planTemplates.availableTechnologies'),
				this.translateService.instant('common.technologies')
			]

		};
		this.getAssignedEntities();
	}

	getAssignedEntities() {
		if (this.planTemplate.planTemplateTargetTypeId === this.targetTypeIds.company
		  || this.planTemplate.planTemplateTargetTypeId === this.targetTypeIds.continuityOfGovernment
		  || this.planTemplate.planTemplateTargetTypeId === this.targetTypeIds.emergencyOperations
		  || this.planTemplate.planTemplateTargetTypeId === this.targetTypeIds.companyITDR) {
			this.isCompany = true;
			this.selectedTargets = [];
			this.loaded = true;
			return;
		}

		this.spinner.start('templateTargetsModal');

		this.bcService.getAssignedTemplateTargets(this.planTemplate.planTemplateId)
			.then((res: any) => {
				if (res) {
					this.selectedTargets = res;
				}

				if (this.planTemplate.planTemplateTargetTypeId === this.targetTypeIds.department
				  || this.planTemplate.planTemplateTargetTypeId === this.targetTypeIds.continuityOfOperationPlanning) {
					this.getDepartments(this.selectedTargets);
				}
				else if (this.planTemplate.planTemplateTargetTypeId === this.targetTypeIds.technology
				  || this.planTemplate.planTemplateTargetTypeId === this.targetTypeIds.companyITDR) {
					this.getTechnologies(this.selectedTargets);
				}
				else {
					this.getEntities(this.planTemplate.planTemplateTargetTypeId, this.selectedTargets);
				}
			});
	}

	getDepartments(excludedItems: ModelPlanTemplateTarget[] = []) {
		this.bcService.getPortalFunctionalAreas(this.siteId, null, true).then((res: any) => {
			this.baseDepartments = res.portalFunctionalAreas.map((pfa: PortalFunctionalArea) => {
				pfa.alias = pfa.portalFunctionalAreaName || pfa.functionalArea.functionalAreaName;
				if (pfa.leader) {
					pfa.leader.locationEntityId = pfa.leader.entities[0].entityId;
					pfa.leader.locationEntityName = pfa.leader.entities[0].entityName;
				}
				return pfa;
			});

			if (excludedItems.length > 0) {
				const filteredDepartments = this.baseDepartments.filter(d =>
					!excludedItems.some(e =>
						(e.targetId === d.portalFunctionalAreaId)));

				this.mapDepartmentsToTemplateTargets(filteredDepartments);
			}
			else {
				this.mapDepartmentsToTemplateTargets(this.baseDepartments);
			}
		});
	}

	getTechnologies(excludedItems: ModelPlanTemplateTarget[] = []) {
		const portalPlanTypeId = this.utility.getPortalPlanTypeId(planTemplateTypeIds.disasterRecovery);
		this.bcService.getPortalPlanTechnologies(portalPlanTypeId, false, this.technologiesFilter).then((res) => {
			this.baseTechnologies = res.technologies;
			if (excludedItems.length > 0) {
				const filteredTechnologies = this.baseTechnologies.filter(d =>
					!excludedItems.some(e =>
						(e.targetId === d.portalPlanTechnologyId)));

				this.mapTechnologiesToTemplateTargets(filteredTechnologies);
			}
			else {
				this.mapTechnologiesToTemplateTargets(this.baseTechnologies);
			}
		});
	}

	getEntities(planTargetTypeId: string, excludedItems: ModelPlanTemplateTarget[] = []) {
		let entityTypeId = null;
		switch (planTargetTypeId.toLowerCase()) {
			case planTemplateTargetTypeIds.company:
			case planTemplateTargetTypeIds.emergencyOperations:
			case planTemplateTargetTypeIds.continuityOfGovernment:
			case planTemplateTargetTypeIds.companyITDR:
				entityTypeId = entityTypeIds.portal;
				break;
			case planTemplateTargetTypeIds.location:
				entityTypeId = entityTypeIds.location;
				break;
			default: // should not happen
				this.toastService.error('Invalid type');
				return;
		}

		if (excludedItems.length > 0) {
			const excludedIds = excludedItems.map(i => i.targetId);
			this.entityService.getFilteredEntities(entityTypeId, null, null, null, excludedIds)
				.then((res: any) => {
					this.mapEntitiesToTemplateTargets(res.entities);
				});
		}
		else {
			this.entityService.getFilteredEntities(entityTypeId)
				.then((res: any) => {
					this.mapEntitiesToTemplateTargets(res.entities);
				});
		}
	}

	getEntityName(targetTypeId: string) {
		return this.translateService.instant('common.' + planTargetEntityType[targetTypeId]);
	}

	getTranslation() {
		if (this.planTemplate.planTemplateTargetTypeId === this.targetTypeIds.technology) {
			const term = this.translateService.instant('common.technologies');
			return this.translateService.instant('businessContinuity.planTemplates.assignDefaultTemplatesDescription.2',
				{ entityType: term });
		}
		return this.translateService.instant('businessContinuity.planTemplates.assignDefaultTemplatesDescription.1',
			{ entityType: this.getEntityName(this.planTemplate.planTemplateTargetTypeId) });
	}

	mapDepartmentsToTemplateTargets(departmentsToMap: PortalFunctionalArea[]) {
		this.availableTargets = Array.from(departmentsToMap, (e) => {
			const mappedDepartment = new ModelPlanTemplateTarget();
			mappedDepartment.targetId = e.portalFunctionalAreaId;
			mappedDepartment.targetNameAsBase64 = e.portalFunctionalAreaNameAsBase64;
			mappedDepartment.targetName = e.portalFunctionalAreaName;
			mappedDepartment.templateTargetUserName = e.leader.userName;
			mappedDepartment.templateTargetFirstName = e.leader.firstName;
			mappedDepartment.templateTargetLastName = e.leader.lastName;
			return mappedDepartment;
		});
		this.loaded = true;
		this.spinner.stop('templateTargetsModal');
	};

	mapTechnologiesToTemplateTargets(technologiesToMap: PlanTechnology[]) {
		this.availableTargets = Array.from(technologiesToMap, (e) => {
			const mappedTechnology = new ModelPlanTemplateTarget();
			mappedTechnology.targetId = e.portalPlanTechnologyId;
			mappedTechnology.targetNameAsBase64 = e.technologyNameAsBase64;
			mappedTechnology.targetName = e.technologyName;
			mappedTechnology.templateTargetUserName = e.owner?.firstName;
			mappedTechnology.templateTargetLastName = e.owner?.lastName;
			return mappedTechnology;
		});
		this.loaded = true;
		this.spinner.stop('templateTargetsModal');
	};

	mapEntitiesToTemplateTargets(entitiesToMap: Entity[]) {
		this.availableTargets = Array.from(entitiesToMap, (e) => {
			const mappedEntity = new ModelPlanTemplateTarget();
			mappedEntity.targetId = e.entityId;
			mappedEntity.targetNameAsBase64 = e.entityNameAsBase64;
			mappedEntity.targetName = e.entityName;
			mappedEntity.templateTargetUserName = e.entityManagerUser?.userName;
			mappedEntity.templateTargetFirstName = e.entityManagerUser?.firstName;
			mappedEntity.templateTargetLastName = e.entityManagerUser?.lastName;
			return mappedEntity;
		});
		this.spinner.stop('templateTargetsModal');
		this.loaded = true;
	};

	getTitle(isForSelected?: boolean) {
		let title: string;
		if (isForSelected) {
			title = this.titlesByEntityType[planTargetEntityType[this.planTemplate.planTemplateTargetTypeId]][0];
		}
		else {
			title = this.titlesByEntityType[planTargetEntityType[this.planTemplate.planTemplateTargetTypeId]][1];
		}
		return title;
	}

	handleTargetSelect(target: ModelPlanTemplateTarget) {
		const index = this.selectedTargets.findIndex(item => target.targetId === item.targetId);
		if (index === -1) {
			this.selectedTargets.push(target);
			const availableItemIndex = this.availableTargets.findIndex(item => target.targetId === item.targetId);
			this.availableTargets.splice(availableItemIndex, 1);
		}
		else {
			this.selectedTargets.splice(index, 1);
			this.availableTargets.push(target);
		}
	}

	cancel() {
		this.dynamicDialogRef.close(false);
	}

	saveAssignedTemplateDepartments() {
		const targetsToAssign: PlanTemplateAssignToTargetsModel = new PlanTemplateAssignToTargetsModel();
		targetsToAssign.planTemplateId = this.planTemplate.planTemplateId;
		targetsToAssign.planTemplateTargetTypeId = this.planTemplate.planTemplateTargetTypeId;
		let targetIds: string[];

		if (this.setDefault) {
			targetIds = this.availableTargets.map(d => d.targetId);
			targetsToAssign.targetIds = targetIds;
			targetsToAssign.makeDefault = true;

			this.confirmationService.confirm({
				header: this.translateService.instant('businessContinuity.planTemplates.setTemplateAsDefault'),
				message: this.translateService.instant('businessContinuity.planTemplates.setTemplateDefaultConfirmation', {
					planName: this.translateService.instant(this.planTypeName)
				}),
				accept: () => {
					this.saveAssignedEntities(targetsToAssign, this.planTemplate.planTemplateTargetTypeId, this.setDefault);
				},
				rejectIcon: 'null',
				acceptIcon: 'null'
			});
		}
		else {
			targetIds = this.selectedTargets.map(d => d.targetId);
			targetsToAssign.targetIds = targetIds;
			targetsToAssign.makeDefault = false;
			this.saveAssignedEntities(targetsToAssign, this.planTemplate.planTemplateTargetTypeId);
		}
	}

	saveAssignedEntities(targetsToAssign: PlanTemplateAssignToTargetsModel, targetId: string, isDefault?: boolean) {
		const targetType = this.titlesByEntityType[planTargetEntityType[targetId]][2];
		if (isDefault) {
			this.bcService.assignPlanTemplateToTargets(targetsToAssign)
				.then(() => {
					this.toastService.success(this.translateService.instant('businessContinuity.planTemplates.targetsAssignedSuccessfully', { targetType }));
					this.dynamicDialogRef.close(true);
				})
				.catch(() => this.toastService.error(this.translateService.instant('businessContinuity.planTemplates.targetsAssignedUnsuccessfully', { targetType })));
		}
		else {
			this.bcService.assignPlanTemplateToTargets(targetsToAssign)
				.then(() => {
					this.toastService.success(this.translateService.instant('businessContinuity.planTemplates.targetsAssignedSuccessfully', { targetType }));
					this.dynamicDialogRef.close(true);
				})
				.catch(() => this.toastService.error(this.translateService.instant('businessContinuity.planTemplates.targetsAssignedUnsuccessfully', { targetType })));
		}
	}
}
