import { AbstractControl, ValidationErrors } from '@angular/forms';

export function ValidateUrl(control: AbstractControl): ValidationErrors {
	if (!control.value) {
		return null;
	}

	const regex = new RegExp('https?:\\/\\/(www\\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\\.[a-z]{2,4}\\b([-a-zA-Z0-9@:%_\+.~#?&/=]*)');
	if (!regex.test(control.value)) {
		return { invalidUrl: true };
	}

	return null;
}
