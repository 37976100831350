import { bcRevisionItemTypes, permissionFeatures, revisionScopes } from '@app/app.constants';
import * as _ from 'lodash';
import { PermissionService } from '@app/providers/permission.service';

export class BcPlannerStepsByPlanType {
	groups: BcPlannerGroupByPlanType[] = [];

	constructor(permissionService: PermissionService,
		portalPlanTypeId?: string,
		siteId?: string,
		planTypeId?: string,
		portalFunctionalAreaId?: string,
		portalAnnexId?: string,
		portalPlanTechnologyId?: string,
		revisionScope?: string,
		revisionCategory?: string,
		isTeamLead?: boolean,
		isSme?: boolean,
		isAnnexLead?: boolean,
		isTeamAlternate?: boolean,
		isTechnologyOwner?: boolean) {
		const routeMap: Array<{
			revisionItemTypeId: string
			route: string[]
			track?: boolean
			label: string
			revisionScope?: string
			revisionCategory?: string
			permissions?: string[]
			permissionsAlternative?: { allowTeamLead?: boolean, allowSme?: boolean, allowAnnexLead?: boolean }
			requiresAnnexId?: boolean
		}> = [
			// DOA
			{
				// departmentDOA
				revisionItemTypeId: bcRevisionItemTypes.departmentDOA,
				route: ['/business-continuity/planner', portalPlanTypeId, siteId, portalFunctionalAreaId, 'authority-delegations'],
				track: true,
				label: 'businessContinuity.authorityDelegation.header',
				permissions: [
					permissionFeatures.agilityPlanner.actions.businessContinuityManagement,
					permissionFeatures.agilityPlanner.actions.businessContinuityAudit
				],
				permissionsAlternative: {
					allowTeamLead: true,
					allowSme: false
				}
			},
			{
				// organizationDOA
				revisionItemTypeId: bcRevisionItemTypes.organizationDOA,
				route: ['/business-continuity/planner', portalPlanTypeId, siteId, 'authority-delegations'],
				track: true,
				label: 'businessContinuity.authorityDelegation.header',
				permissions: [
					permissionFeatures.agilityPlanner.actions.businessContinuityManagement,
					permissionFeatures.agilityPlanner.actions.businessContinuityAudit
				],
				permissionsAlternative: {
					allowAnnexLead: true
				}
			},
			// END DOA

			// OOS
			{
				// departmentOOS
				revisionItemTypeId: bcRevisionItemTypes.departmentOOS,
				route: ['/business-continuity/planner', portalPlanTypeId, siteId, portalFunctionalAreaId, 'orders-of-succession'],
				track: true,
				label: 'businessContinuity.ordersOfSuccession.title',
				permissions: [
					permissionFeatures.agilityPlanner.actions.businessContinuityManagement,
					permissionFeatures.agilityPlanner.actions.businessContinuityAudit
				],
				permissionsAlternative: {
					allowTeamLead: true,
					allowSme: false
				}
			},
			{
				// organizationOOS
				revisionItemTypeId: bcRevisionItemTypes.organizationOOS,
				route: ['/business-continuity/planner', portalPlanTypeId, siteId, 'orders-of-succession'],
				track: true,
				label: 'businessContinuity.ordersOfSuccession.title',
				permissions: [
					permissionFeatures.agilityPlanner.actions.businessContinuityManagement,
					permissionFeatures.agilityPlanner.actions.businessContinuityAudit
				],
				permissionsAlternative: {
					allowAnnexLead: true
				}
			},
			// END OOS

			// TEAMS
			{
				// departmentTeams
				revisionItemTypeId: bcRevisionItemTypes.departmentTeams,
				route: ['/business-continuity/planner', portalPlanTypeId, siteId, portalFunctionalAreaId, 'response-teams'],
				track: true,
				label: 'common.responseTeams',
				permissions: [
					permissionFeatures.agilityPlanner.actions.businessContinuityManagement,
					permissionFeatures.agilityPlanner.actions.businessContinuityAudit
				],
				permissionsAlternative: {
					allowTeamLead: true,
					allowSme: false
				}
			},
			{
				// organizationTeams
				revisionItemTypeId: bcRevisionItemTypes.organizationTeams,
				route: ['/business-continuity/planner', portalPlanTypeId, siteId, 'response-teams'],
				track: true,
				label: 'common.responseTeams',
				permissions: [
					permissionFeatures.agilityPlanner.actions.businessContinuityManagement,
					permissionFeatures.agilityPlanner.actions.businessContinuityAudit
				],
				permissionsAlternative: {
					allowAnnexLead: true
				}
			},
			// END TEAMS

			// ANNEXES
			{
				revisionItemTypeId: bcRevisionItemTypes.sendAnnexEmail,
				route: ['/business-continuity/planner', portalPlanTypeId, siteId, 'annexes', 'introductory-email'],
				track: true,
				label: 'publicAlertSubscription.sendEmail',
				permissions: [
					permissionFeatures.agilityPlanner.actions.businessContinuityManagement,
					permissionFeatures.agilityPlanner.actions.businessContinuityAudit
				],
				permissionsAlternative: {
					allowAnnexLead: true
				}
			},
			{
				// supersession
				revisionItemTypeId: bcRevisionItemTypes.supersession,
				route: ['/business-continuity/planner', portalPlanTypeId, siteId, 'supersession'],
				track: true,
				label: 'businessContinuity.supersession.supersession',
				permissions: [
					permissionFeatures.agilityPlanner.actions.businessContinuityManagement,
					permissionFeatures.agilityPlanner.actions.businessContinuityAudit
				],
				permissionsAlternative: {
					allowAnnexLead: true
				}
			},
			{
				// generalQuestions
				revisionItemTypeId: bcRevisionItemTypes.generalQuestions,
				route: ['/business-continuity/planner', portalPlanTypeId, siteId, 'general-questions'],
				track: true,
				label: 'businessContinuity.generalQuestions.generalQuestions',
				permissions: [
					permissionFeatures.agilityPlanner.actions.businessContinuityManagement,
					permissionFeatures.agilityPlanner.actions.businessContinuityAudit
				],
				permissionsAlternative: {
					allowAnnexLead: true
				}
			},
			{
				// manageAnnex
				revisionItemTypeId: bcRevisionItemTypes.buildAnnex,
				route: ['/business-continuity/planner', portalPlanTypeId, siteId, 'annexes'],
				track: true,
				label: 'common.buildManage',
				permissions: [
					permissionFeatures.agilityPlanner.actions.businessContinuityManagement,
					permissionFeatures.agilityPlanner.actions.businessContinuityAudit
				],
				permissionsAlternative: {
					allowAnnexLead: true
				}
			},
			{
				// sendAnnexEmail
				revisionItemTypeId: bcRevisionItemTypes.sendAnnexEmail,
				route: ['/business-continuity/planner', portalPlanTypeId, siteId, 'annexes', 'introductory-email'],
				track: true,
				label: 'publicAlertSubscription.sendEmail',
				permissions: [
					permissionFeatures.agilityPlanner.actions.businessContinuityManagement,
					permissionFeatures.agilityPlanner.actions.businessContinuityAudit
				],
				permissionsAlternative: {
					allowAnnexLead: true
				}
			},
			{
				// trackAnnexes
				revisionItemTypeId: bcRevisionItemTypes.trackAnnexes,
				route: ['/business-continuity/planner', portalPlanTypeId, siteId, 'annexes', 'track'],
				track: false,
				label: 'common.track'
			},
			{
				// annexSections
				revisionItemTypeId: bcRevisionItemTypes.annexSections,
				route: ['/business-continuity/planner', portalPlanTypeId, siteId, 'annex', portalAnnexId, 'sections'],
				track: true,
				label: 'common.sections',
				permissions: [
					permissionFeatures.agilityPlanner.actions.businessContinuityManagement,
					permissionFeatures.agilityPlanner.actions.businessContinuityAudit
				],
				permissionsAlternative: {
					allowAnnexLead: true
				}
			},
			{
				// annexAgencies
				revisionItemTypeId: bcRevisionItemTypes.annexAgencies,
				route: ['/business-continuity/planner', portalPlanTypeId, siteId, 'annex', portalAnnexId, 'tasked-agencies'],
				track: true,
				label: 'common.taskedAgencies',
				permissions: [
					permissionFeatures.agilityPlanner.actions.businessContinuityManagement,
					permissionFeatures.agilityPlanner.actions.businessContinuityAudit
				],
				permissionsAlternative: {
					allowAnnexLead: true
				}
			},
			{
				// annexActionList
				revisionItemTypeId: bcRevisionItemTypes.annexActionList,
				route: ['/business-continuity/planner', portalPlanTypeId, siteId, 'annex', portalAnnexId, 'responsibilities'],
				track: true,
				label: 'businessContinuity.teams.responsibilities',
				permissions: [
					permissionFeatures.agilityPlanner.actions.businessContinuityManagement,
					permissionFeatures.agilityPlanner.actions.businessContinuityAudit
				],
				permissionsAlternative: {
					allowAnnexLead: true
				}
			},
			// END ANNEXES

			// DEPARTMENTS
			{
				// manageDepartments
				revisionItemTypeId: bcRevisionItemTypes.buildYourTeam,
				route: ['/business-continuity/planner', portalPlanTypeId, 'build-your-team', siteId, 'select-department'],
				track: true,
				label: 'common.buildManage',
				permissions: [
					permissionFeatures.agilityPlanner.actions.businessContinuityManagement,
					permissionFeatures.agilityPlanner.actions.businessContinuityAudit
				]
			},
			{
				// sendTeamLeadEmail
				revisionItemTypeId: bcRevisionItemTypes.sendTeamLeadEmail,
				route: ['/business-continuity/planner', portalPlanTypeId, 'build-your-team', siteId, 'introductory-email'],
				track: true,
				label: 'publicAlertSubscription.sendEmail',
				permissions: [
					permissionFeatures.agilityPlanner.actions.businessContinuityManagement,
					permissionFeatures.agilityPlanner.actions.businessContinuityAudit
				]
			},

			{
				// trackDepartments
				revisionItemTypeId: bcRevisionItemTypes.trackDepartments,
				route: ['/business-continuity/planner', portalPlanTypeId, siteId, 'track', 'departments'],
				track: false,
				label: 'common.track',
				permissions: [
					permissionFeatures.agilityPlanner.actions.businessContinuityManagement,
					permissionFeatures.agilityPlanner.actions.businessContinuityAudit
				]
			},
			{
				// departmentDescription
				revisionItemTypeId: bcRevisionItemTypes.departmentDescription,
				route: ['/business-continuity/planner', portalPlanTypeId, siteId, portalFunctionalAreaId, 'department-description'],
				track: true,
				label: 'businessContinuity.bcPlanner.departmentDescriptionLabel',
				permissions: [
					permissionFeatures.agilityPlanner.actions.businessContinuityManagement,
					permissionFeatures.agilityPlanner.actions.businessContinuityAudit
				],
				permissionsAlternative: {
					allowTeamLead: true,
					allowSme: false
				}
			},
			{
				// departmentAlternate
				revisionItemTypeId: bcRevisionItemTypes.departmentAlternateLeader,
				route: ['/business-continuity/planner', portalPlanTypeId, siteId, portalFunctionalAreaId, 'department-alternate'],
				track: true,
				label: 'businessContinuity.bcPlanner.departmentAlternateLabel',
				permissions: [
					permissionFeatures.agilityPlanner.actions.businessContinuityManagement,
					permissionFeatures.agilityPlanner.actions.businessContinuityAudit
				],
				permissionsAlternative: {
					allowTeamLead: true,
					allowSme: false
				}
			},
			// END DEPARTMENTS

			// PROCESSES
			{
				// manageProcess
				// processes build & manage
				revisionItemTypeId: bcRevisionItemTypes.validateProcess,
				route: ['/business-continuity/planner', portalPlanTypeId, 'validate-process', siteId, 'select-process', portalFunctionalAreaId],
				track: true,
				label: 'common.buildManage',
				permissions: [
					permissionFeatures.agilityPlanner.actions.businessContinuityManagement,
					permissionFeatures.agilityPlanner.actions.businessContinuityAudit
				],
				permissionsAlternative: {
					allowTeamLead: true,
					allowSme: false
				}
			},
			{
				// sendAssignmentEmail
				revisionItemTypeId: bcRevisionItemTypes.sendAssignmentEmail,
				route: ['/business-continuity/planner', portalPlanTypeId, 'validate-process', siteId, 'introductory-email', portalFunctionalAreaId],
				track: true,
				label: 'publicAlertSubscription.sendEmail',
				permissions: [
					permissionFeatures.agilityPlanner.actions.businessContinuityManagement,
					permissionFeatures.agilityPlanner.actions.businessContinuityAudit
				],
				permissionsAlternative: {
					allowTeamLead: true,
					allowSme: false
				}
			},
			{
				// trackProcesses
				revisionItemTypeId: bcRevisionItemTypes.trackProcesses,
				route: ['/business-continuity/planner', portalPlanTypeId, siteId, portalFunctionalAreaId, 'track', 'processes'],
				track: false,
				label: 'common.track',
				permissionsAlternative: {
					allowTeamLead: true,
					allowSme: true
				}
			},
			// END PROCESSES

			// TECHNOLOGIES
			{
				// organization Technology teams
				revisionItemTypeId: bcRevisionItemTypes.organizationTeams,
				revisionScope: revisionScopes.organization.key,
				route: ['/business-continuity/planner', portalPlanTypeId, siteId, 'recovery-teams'],
				track: true,
				label: 'common.recoveryTeams',
				permissions: [
					permissionFeatures.agilityPlanner.actions.businessContinuityManagement,
					permissionFeatures.agilityPlanner.actions.businessContinuityAudit
				]
			},
			{
				// buildTechnology
				revisionItemTypeId: bcRevisionItemTypes.buildTechnology,
				route: ['/business-continuity/planner', portalPlanTypeId, siteId, 'technologies'],
				track: true,
				label: 'common.buildManage',
				permissions: [
					permissionFeatures.agilityPlanner.actions.businessContinuityManagement,
					permissionFeatures.agilityPlanner.actions.businessContinuityAudit
				]
			},
			{
				// technologyVendors
				revisionItemTypeId: bcRevisionItemTypes.sendTechnologyEmail,
				route: ['/business-continuity/planner', portalPlanTypeId, siteId, 'technologies', 'introductory-email'],
				track: true,
				label: 'publicAlertSubscription.sendEmail'
			},
			{
				// trackTechnologies
				revisionItemTypeId: bcRevisionItemTypes.trackTechnologies,
				route: ['/business-continuity/planner', portalPlanTypeId, siteId, 'technologies', 'track'],
				track: false,
				label: 'common.track'
			},
			{
				// technologyVendors
				revisionItemTypeId: bcRevisionItemTypes.technologyVendors,
				route: ['/business-continuity/planner', portalPlanTypeId, siteId, 'technology', portalPlanTechnologyId, 'vendors'],
				track: true,
				label: 'businessContinuity.technology.technologyVendors'
			},
			{
				// technologyDependencies
				revisionItemTypeId: bcRevisionItemTypes.technologyDependencies,
				route: ['/business-continuity/planner', portalPlanTypeId, siteId, 'technology', portalPlanTechnologyId, 'upstream-dependencies'],
				track: true,
				label: 'businessContinuity.technology.technologyDependencies'
			},
			{
				// technology Details and Attachments
				revisionItemTypeId: bcRevisionItemTypes.technologyApplicationDetails,
				route: ['/business-continuity/planner', portalPlanTypeId, siteId, 'technology', portalPlanTechnologyId, 'details-and-attachments'],
				track: true,
				label: 'businessContinuity.technology.technologyApplicationDetails'
			},
			{
				// technologyTasks
				revisionItemTypeId: bcRevisionItemTypes.technologyTasks,
				route: ['/business-continuity/planner', portalPlanTypeId, siteId, 'technology', portalPlanTechnologyId, 'tasks-sets'],
				track: true,
				label: 'businessContinuity.technology.technologyTasks'
			},
			{
				// technologyRecoveryTime
				revisionItemTypeId: bcRevisionItemTypes.technologyRecoveryTime,
				route: ['/business-continuity/planner', portalPlanTypeId, siteId, 'technology', portalPlanTechnologyId, 'recovery-time'],
				track: true,
				label: 'businessContinuity.technology.technologyRecoveryTime'
			}
			// END TECHNOLOGIES
		];
		// get the revisionItemTypes from storage, but filter it down to just this portalPlanTypeId (revisionScope)
		const revisionItemTypesFromStorage: RevisionItemType[] = JSON.parse(localStorage?.getItem('preparisPortal.revisionItemTypes')).filter((ritfs: RevisionItemType) => {
			return ritfs.portalPlanTypeId == portalPlanTypeId
			  && ritfs.revisionScope === revisionScope;
		}) as any;

		// get all the revisionCategories pertaining to active flow so can group
		const revisionCategoriesFromStorage: string[] = _.uniq(revisionItemTypesFromStorage.map((rit: RevisionItemType) => rit.revisionCategory));
		const newIntroductionGroup: BcPlannerGroupByPlanType = new BcPlannerGroupByPlanType();

		// push introduction page as 1st step
		// depending on revisionScope
		switch (revisionScope) {
			case (revisionScopes.organization.key):
				// NOTE: annexLead gets "special" permission
				if (permissionService.hasPermission(permissionFeatures.agilityPlanner.key, [
					permissionFeatures.agilityPlanner.actions.businessContinuityManagement,
					permissionFeatures.agilityPlanner.actions.businessContinuityAudit
				]) || isAnnexLead) {
					// organization introduction
					newIntroductionGroup.revisionCategory = revisionScopes.organization.categories.introduction;
					const step: RevisionItemType = new RevisionItemType();
					step.route = ['/business-continuity/planner', portalPlanTypeId, 'introduction'];
					step.track = false;
					step.label = 'common.introduction';
					newIntroductionGroup.steps = [step];

					this.groups.push(newIntroductionGroup);
				}
				break;

			case (revisionScopes.department.key):
				// department introduction
				newIntroductionGroup.revisionCategory = revisionScopes.department.categories.introduction;
				const departmentStep: RevisionItemType = new RevisionItemType();
				departmentStep.route = ['/business-continuity/planner', portalPlanTypeId, siteId, portalFunctionalAreaId, 'introduction'];
				departmentStep.track = false;
				departmentStep.label = 'common.introduction';
				newIntroductionGroup.steps = [departmentStep];

				this.groups.push(newIntroductionGroup);
				break;

			case (revisionScopes.annex.key):
				newIntroductionGroup.revisionCategory = revisionScopes.annex.categories.introduction;
				const annexStep: RevisionItemType = new RevisionItemType();
				annexStep.route = ['/business-continuity/planner', portalPlanTypeId, siteId, 'annex', portalAnnexId, 'introduction'];
				annexStep.track = false;
				annexStep.requiresAnnexId = true;
				annexStep.label = 'common.introduction';
				newIntroductionGroup.steps = [annexStep];

				this.groups.push(newIntroductionGroup);

				break;

			case (revisionScopes.technology.key):
				newIntroductionGroup.revisionCategory = revisionScopes.technology.categories.introduction;
				const technologyStep: RevisionItemType = new RevisionItemType();
				technologyStep.route = ['/business-continuity/planner', portalPlanTypeId, siteId, 'technology', portalPlanTechnologyId, 'introduction'];
				technologyStep.track = false;
				technologyStep.label = 'common.introduction';
				newIntroductionGroup.steps = [technologyStep];

				this.groups.push(newIntroductionGroup);

				break;
		}

		revisionCategoriesFromStorage.forEach((rc: string) => {
			const newGroup: BcPlannerGroupByPlanType = new BcPlannerGroupByPlanType();
			newGroup.revisionCategory = rc;
			const revisionItemTypesByRevisionCategory: RevisionItemType[] = revisionItemTypesFromStorage.filter((ritfs: RevisionItemType) => ritfs.revisionCategory === rc);
			// convert the category name to translations
			switch (rc) {
				case 'General Information':
					newGroup.label = 'common.general';
					break;
				case 'Key Personnel':
					newGroup.label = 'common.keyPersonnel';
					break;
				case 'Manage Departments':
				case 'Manage Department':
					newGroup.label = 'common.departments';
					break;
				case 'Manage Annexes':
				case 'Annexes':
					newGroup.label = 'businessContinuity.annex.annexes';
					break;
				case 'Department Information':
				case 'Department Description':
					newGroup.label = 'businessContinuity.bcPlanner.departmentInformation';
					break;
				case 'Validate Process':
				case 'Validate Processes':
				case 'Process Analysis':
					newGroup.label = 'common.processes';
					break;
				case 'Configure Annex':
					newGroup.label = 'businessContinuity.annex.configureAnnex.label';
					break;
				case 'Manage Technologies':
					newGroup.label = 'businessContinuity.technology.manageTechnologies';
					break;
				case 'Configure Technology':
					newGroup.label = 'businessContinuity.technology.configureTechnology';
					break;
				case 'Technologies':
					newGroup.label = 'Technologies';
					break;
			}

			// map the routes
			revisionItemTypesByRevisionCategory?.forEach((revisionItemType: RevisionItemType) => {
				const route: any = routeMap.find((route: any) => {
					return route.revisionItemTypeId === revisionItemType.revisionItemTypeId;
				});

				if (route) {
					// configure the route information
					revisionItemType.route = route.route;
					revisionItemType.track = route.track;
					revisionItemType.label = route.label;
					revisionItemType.requiresAnnexId = route.requiresAnnexId;
					revisionItemType.permissions = route.permissions;
					const hasPermission: boolean = permissionService.hasPermission(permissionFeatures.agilityPlanner.key, route?.permissions || null);

					if (hasPermission
					  || (route.permissionsAlternative?.allowTeamLead && isTeamLead)
					  || (route.permissionsAlternative?.allowTeamLead && isTeamAlternate)
					  || (route.permissionsAlternative?.allowSme && isSme)
					  || (route.permissionsAlternative?.allowAnnexLead && isAnnexLead)
					  || isTechnologyOwner) {
						newGroup.steps.push(revisionItemType);
					}
				}
			});

			this.groups.push(newGroup);
		});
	}
}

export class BcPlannerGroupByPlanType {
	revisionCategory: string = '';
	steps: RevisionItemType[] = [];
	label: string = '';
}

export class RevisionItemType {
	order: number = 0;
	route: string[] = [];
	planTypeId: string;
	portalPlanTypeId: string;
	siteId: string = '';
	portalFunctionalAreaId: string;
	portalId: string;
	revisionScopeAsBase64: string;
	revisionScope: string;
	revisionCategoryAsBase64: string;
	revisionCategory: string;
	revisionItemTypeNameAsBase64: string;
	revisionItemTypeName: string;
	revisionItemTypeId: string;
	featureId: string;
	featureNameAsBase64: string;
	featureName: string;
	requiresAnnexId?: boolean;
	track?: boolean = true;
	label: string = '';
	permissions: string[] = [];
}
