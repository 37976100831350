import { BaseResponse } from '@app/shared/base-response.model';
import { PlanTechnology } from '@app/shared/business-continuity/technologies/bc-plan-technology';

export class PortalTeam extends BaseResponse {
	portalTeamId: string;
	portalTeamName: string;
	portalTeamDescription: string;
	portalTeamNameAsBase64 = '';
	portalTeamDescriptionAsBase64 = '';
	portalId: string;
	portalFunctionalAreaId: string;
	portalPlanTypeId: string;
	teamStatusTypeId: string;
	isLinked: boolean;
	canUnlink: boolean;
	isDefault: boolean;
	positions: Array<PortalTeamPosition> = [];
	assignToAllDepartments: boolean = false;
	linkedDepartments: Array<string>;

	constructor() {
		super();
	}
}

export class PortalTeamPosition extends BaseResponse {
	portalTeamPositionId: string;
	portalTeamId: string;
	position: string;
	positionAsBase64 = '';
	responsibilities: string;
	responsibilitiesAsBase64 = '';
	userFirstName: string;
	userFirstNameAsBase64 = '';
	userLastName: string;
	userLastNameAsBase64 = '';
	userTitle: string;
	userTitleAsBase64 = '';
	userId: string;
	userPrimaryLocationId: string;
	userPrimaryLocationName: string;
	userPrimaryLocationNameAsBase64: string;

	constructor() {
		super();
	}
}

export class ModelGetPortalTeamsResponse {
	teams: PortalTeam[];
}

export class DRTeam extends PortalTeam {
	technologies: PlanTechnology[];
}
