import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { User } from '@app/shared/user.model';
import { TranslateService } from '@ngx-translate/core';
import { FilterService } from '@app/providers/filter.service';
import { BusinessContinuityService } from '@app/providers/business-continuity.service';
import { Utility } from '@app/providers/utility';
import { SpinnerService } from '@app/providers/spinner.service';
import { debounceTime } from 'rxjs/operators';
import { debounceDuration, specialCharacters } from '@app/app.constants';
import { Entity } from '@app/shared/incidents/entity.model';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { PortalTeamPosition } from '@app/shared/business-continuity/portal-team.model';
import { UserService } from '@app/providers/user.service';

@Component({
	selector: 'app-bc-add-position-modal',
	templateUrl: './bc-add-position-modal.component.html',
	styleUrls: ['./bc-add-position-modal.component.scss']
})
export class BCAddPositionModalComponent implements OnInit {
	loading: boolean = false;
	isAdvancedFilter: boolean = false;
	searchPhrase: string = '';
	filterDebounce: Subject<void> = new Subject<void>();
	userList: Array<User> = [];
	locationOptions: any[];
	roleBundleOptions: any[];
	groupOptions: any[];
	deptOptions: any[];
	locationIds: Array<string>;
	roleBundleIds: Array<string>;
	groupIds: Array<string>;
	departmentIds: Array<string>;
	selectedUsers: Array<User>;
	name: string = null;
	maxCount: number = 100;
	limitedResult: boolean = false;
	currentUsers: Array<User>;
	baseResourcePath: string = '';
	headerLabel: string = '';
	searchUserLabel: string = '';
	currentUserLabel: string = '';
	resultTitle: string = '';
	assignButton: string = '';
	includesMessageOnly: boolean;
	hasMultipleSelections: boolean;
	forMessage: boolean = false;
	singleSelectionPreview: boolean = false;
	filterOutIds: string[] = [];
	filterUsersListById: boolean = false;
	maxSelectCount: number;
	baseSelectedCount: number;
	position: PortalTeamPosition;
	isIndustryDefault: boolean = false;

	public specialCharacters = specialCharacters;

	constructor(
		public dynamicDialogRef: DynamicDialogRef, public dynamicDialogConfig: DynamicDialogConfig,
		public translateService: TranslateService,
		public filterService: FilterService,
		public bcService: BusinessContinuityService,
		public utility: Utility,
		public spinnerService: SpinnerService,
		public userService: UserService) {
		this.baseResourcePath = this.dynamicDialogConfig?.data?.baseResourcePath;
		this.isIndustryDefault = this.dynamicDialogConfig?.data?.isIndustryDefault;
		this.hasMultipleSelections = this.dynamicDialogConfig?.data?.hasMultipleSelections;
		this.singleSelectionPreview = this.dynamicDialogConfig?.data?.singleSelectionPreview;
		this.position = this.dynamicDialogConfig?.data?.position ?? new PortalTeamPosition();
		if (this.dynamicDialogConfig?.data?.position?.userId) {
			this.currentUsers = [<User>{ userId: this.position?.userId }];
		}

		this.filterDebounce.pipe(
			debounceTime(debounceDuration))
			.subscribe(e => this.load());
	}

	ngOnInit(): void {
		this.searchUserLabel = this.baseResourcePath + '.searchUserLabel';
		this.currentUserLabel = this.baseResourcePath + '.currentUserLabel';
		this.resultTitle = this.baseResourcePath + '.resultLabel';
		const assignButtonTranslatedString = this.translateService.instant(this.baseResourcePath + '.assignButton');
		this.assignButton = assignButtonTranslatedString === (this.baseResourcePath + '.assignButton') ? this.translateService.instant('common.save') : assignButtonTranslatedString;

		this.filterService.initializeMetadata().then((_) => {
			this.locationOptions = this.filterService.getLocations().map((loc: Entity) => {
				return {
					value: loc.entityId,
					label: loc.entityName
				};
			});
			this.groupOptions = this.filterService.getGroups().map((grp: Entity) => {
				return {
					value: grp.entityId,
					label: grp.entityName
				};
			});
			this.deptOptions = this.filterService.getDepartments().map((dept: Entity) => {
				return {
					value: dept.entityId,
					label: dept.entityName
				};
			});
			this.roleBundleOptions = this.filterService.getRoles(false).map((roleBundle: any) => {
				return {
					value: roleBundle.id,
					label: roleBundle.name
				};
			});
		});
		this.selectedUsers = this.currentUsers || [];
		if (this.maxSelectCount) {
			this.baseSelectedCount = this.maxSelectCount;
		}
		this.load();
	}

	onFilterChange() {
		this.filterDebounce.next();
	}

	load() {
		this.spinnerService.start();
		this.loading = true;
		this.userList = [];
		this.bcService.searchUsersForTeamLead(
			this.searchPhrase,
			this.maxCount,
			this.locationIds,
			this.groupIds,
			this.departmentIds,
			this.roleBundleIds,
			this.currentUsers?.map(u => u.userId)).then((res: any) => {
			if (res) {
				this.limitedResult = res.countOfAvailableItems > res.userItems.length;
				this.userList = res.userItems.map((user: User) => {
					user.roleBundleName = user.roles[0].roleBundleName;
					return user;
				});
				if (this.position?.userId) {
					this.currentUsers = [<User>{ userId: this.position?.userId }];
				}
				else {
					this.currentUsers = [];
				}
				if (this.filterOutIds !== null && this.filterOutIds?.length > 0) {
					if (this.filterUsersListById) {
						this.userList = this.userList.filter(user => !this.filterOutIds.includes(user.userId));
					}
					else {
						this.currentUsers = this.userList.filter(user => this.filterOutIds.includes(user.userId));
					}
				}
				if (this.currentUsers?.length) {
					this.selectedUsers = this.userList.filter((user: User) => this.currentUsers.findIndex(u => u.userId === user.userId) > -1);
				}
			}
			else {
				this.limitedResult = false;
			}
			this.loading = false;
		}).catch((_) => {
		}).then(() => {
			this.spinnerService.stop();
		});
	}

	select() {
		this.position.userId = this.selectedUsers[0]?.userId;
		this.position.userFirstName = this.selectedUsers[0]?.firstName;
		this.position.userLastName = this.selectedUsers[0]?.lastName;
		this.dynamicDialogRef.close(this.position);
	}

	dismiss() {
		this.dynamicDialogRef.close();
	}

	onToggleUser(user: User) {
		const ix = this.selectedUsers.findIndex(u => u.userId === user.userId);
		if (ix >= 0) {
			if (this.singleSelectionPreview) {
				this.currentUsers.splice(ix, 1);
			}
			this.selectedUsers.splice(ix, 1);
		}
		else {
			if (this.hasMultipleSelections) {
				this.selectedUsers.push(user);
			}
			else {
				if (this.singleSelectionPreview) {
					this.currentUsers = [user];
				}
				this.selectedUsers = [user];
			}
		}
	}

	isSelected(user: User) {
		return user?.userId !== undefined
			? this.selectedUsers.findIndex(u => u?.userId === user.userId) >= 0
			: this.selectedUsers.findIndex(u => u?.businessEmail === user?.businessEmail);
	}
}
