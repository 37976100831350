<div class="modal-body">
    <div class="mb-3">
        <label class="form-label">{{'common.name' | translate}}</label>
        <div class="input-group">
            <input id="equipmentTypeNameInput" class="form-control"
                   type="text"
                   appAutofocus
                   maxLength="100"
                   [(ngModel)]="equipmentType.equipmentTypeName"
                   placeholder="{{'businessContinuity.continuityStrategy.equipmentTypeModal.typeNamePlaceholder' | translate}}"
                   [ngClass]="{'is-invalid': String.isNullOrEmpty(equipmentType.equipmentTypeName) && submitted}"
                   required/>
        </div>
    </div>
</div>
<div class="modal-footer text-end">
    <button id="saveButton" type="button" class="btn btn-primary businessContinuityNextButton"
            (click)="save()">{{(String.isNullOrEmpty(equipmentType.equipmentTypeId) ? 'common.create' : 'common.save') | translate}}</button>
</div>
