import { Component, Input } from '@angular/core';
import { FileUploader } from 'ng2-file-upload';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { Utility } from '@app/providers/utility';
import { maxUploadFileSizeInBytes, maxUploadFileSizeInMegaBytes } from '@app/app.constants';
import { saveAs } from 'file-saver';
import { BusinessContinuityService } from '@app/providers/business-continuity.service';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
	templateUrl: './bc-bulk-upload-technology-modal.component.html'
})
export class BcBulkUploadTechnologyModalComponent {
	@Input() name: string = '';
	@Input() pluralName: string = '';
	savingInProgress: boolean = false;
	selectedFile: File;
	reader: FileReader = new FileReader();
	fileWarning: string;
	downloadingAddFile: boolean = false;
	downloadingExportFile: boolean = false;
	uploader: FileUploader = new FileUploader({ url: null });
	hasBaseDropZoneOver: boolean = false;
	errorMessages: Array<string> = [];
	public entityTypeId: string = '';

	constructor(private bcService: BusinessContinuityService,
		private toastrService: ToastrService,
		public translateService: TranslateService,
		private utility: Utility,
		public dynamicDialogRef: DynamicDialogRef,
		public dynamicDialogConfig: DynamicDialogConfig) {
		this.name = this.dynamicDialogConfig.data.name;
		this.pluralName = this.dynamicDialogConfig.data.pluralName;
		this.entityTypeId = this.dynamicDialogConfig.data.entityTypeId;
		this.dynamicDialogConfig.header = this.translateService.instant('manage.bulkUpload.lblBulkUpload');
	}

	fileOver(e: boolean): void {
		this.hasBaseDropZoneOver = e;
	}

	fileDropped(e: any): void {
		this.processFile(e[0]);
	}

	fileSelected(e: any): void {
		this.processFile(e.target.files[0]);
	}

	processFile(file) {
		this.removeSelectedFile();
		this.errorMessages = [];
		this.fileWarning = '';

		if (!this.validateFileType(file)) {
			this.errorMessages.push(
				this.translateService.instant('users.bulkUpload.errorInvalidBulkFileType')
			);
			return;
		}

		if (!this.validateFileSize(file)) {
			this.errorMessages.push(
				this.translateService.instant('common.msgFileExceedMaxSize', { size: maxUploadFileSizeInMegaBytes })
			);
			return;
		}

		if (file.name.toLowerCase().includes('test')) {
			this.fileWarning = this.translateService.instant('users.bulkUpload.msgTestBulkUploadFile');
		}

		this.selectedFile = file;
		this.reader.readAsDataURL(file);
	}

	validateFileType(file): boolean {
		// validate file extension to be csv
		const extension = this.utility.getFileExtension(file.name);
		return extension.toLowerCase() === '.csv';
	}

	validateFileSize(file): boolean {
		return file.size <= maxUploadFileSizeInBytes;
	}

	removeSelectedFile() {
		this.selectedFile = null;
	}

	downloadAddFile() {
		this.downloadingAddFile = true;
		this.bcService.exportHeadersForTechnologyCsvFile().then((response: Blob) => {
			saveAs(response, this.translateService.instant('manage.bulkUpload.fileName', { entityTypeName: this.name }));
			this.dynamicDialogRef.close('cancel');
			this.toastrService.success(this.translateService.instant('common.msgrExportSuccessful', { targetType: this.name }));
		}).catch((_) => {
			this.toastrService.error(this.translateService.instant('common.errorExportFailed', { targetType: this.name }));
		}).then((_) => {
			this.downloadingAddFile = false;
		});
	}

	downloadExportFile() {
		this.downloadingExportFile = true;
		this.bcService.exportTechnologyCsvFile().then((response: any) => {
			saveAs(response, this.translateService.instant('manage.bulkUpload.fileName', { entityTypeName: this.name }));
			this.dynamicDialogRef.close('cancel');
			this.toastrService.success(this.translateService.instant('common.msgrExportSuccessful', { targetType: this.name }));
		}).catch((_) => {
			this.toastrService.error(this.translateService.instant('common.errorExportFailed', { targetType: this.name }));
		}).then((_) => {
			this.downloadingExportFile = false;
		});
	}

	uploadFile() {
		this.errorMessages = [];

		if (!this.selectedFile) {
			this.toastrService.error(this.translateService.instant('users.bulkUpload.lblNoFileSelected'));
			return;
		}

		this.savingInProgress = true;
		const dataUrl = this.reader.result as string;
		this.bcService.uploadBulkTechnologyFile(dataUrl.substring(dataUrl.indexOf(',') + 1), this.selectedFile.name).then((result: any) => {
			if (result.bulkErrorList && result.bulkErrorList.length > 0) {
				result.bulkErrorList.forEach((errorItem) => {
					this.errorMessages.push(errorItem);
				});
			}
			else {
				this.toastrService.success(this.translateService.instant('manage.bulkUpload.msgUploadUsersSuccess'));

				this.dynamicDialogRef.close();
			}
		}).catch((error) => {
			if (error.bulkErrorList) {
				error.bulkErrorList.forEach((errorItem) => {
					this.errorMessages.push(errorItem);
				});
			}
			else {
				this.toastrService.error(error.errorMessage);
			}
		}).then((_) => {
			this.savingInProgress = false;
		});
	}

	cancel() {
		this.dynamicDialogRef.close('cancel');
	}
}
