import { BaseResponse } from '@app/shared/base-response.model';

export class ModelBCTeam extends BaseResponse {
	teamId: string = '';
	teamName: string = '';
	teamNameAsBase64: string = '';
	teamDescription: string = '';
	teamDescriptionAsBase64: string = '';
	functionalAreaId?: string = '';
	teamStatusTypeId: string = '';
	positions: Array<ModelBCTeamPosition> = [];

	constructor() {
		super();
	}
}

export class ModelBCTeamPosition extends BaseResponse {
	teamPositionId: string = '';
	teamId: string = '';
	position: string = '';
	positionAsBase64: string = '';
	responsibilities: string = '';
	responsibilitiesAsBase64: string = '';
}
