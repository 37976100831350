import { AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { ConfirmationService, MenuItem } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BusinessContinuityService } from '@app/providers/business-continuity.service';
import { SpinnerService } from '@app/providers/spinner.service';
import { ToastrService } from 'ngx-toastr';
import { bcRevisionItemTypes, featureTypes, permissionFeatures } from '@app/app.constants';
import { BreadCrumb } from '@app/shared/breadcrumb.model';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { PortalTeam, PortalTeamPosition } from '@app/shared/business-continuity/portal-team.model';
import { AssignTeamModalComponent } from '@app/components/business-continuity/bc-teams/modals/assign-team-modal/assign-team-modal.component';
import { PermissionService } from '@app/providers/permission.service';

@Component({
	selector: 'app-bc-teams',
	templateUrl: './bc-teams.component.html',
	styleUrls: ['./bc-teams.component.scss']
})
export class BcTeamsComponent implements OnInit, OnDestroy, OnChanges {
	@Input() teams: PortalTeam[] = [];
	@Input() organizationTeams: PortalTeam[] = [];
	@Input() isIndustryDefault: boolean;
	@Input() canManageDepartment: boolean;
	@Input() isBusinessContinuityManager: boolean;
	@Input() functionalAreaId: string;
	@Input() industryId: string;
	@Input() teamId: string;
	@Input() departmentId: string;
	@Input() PlanTypeId: string;
	@Input() portalFunctionalAreaId: string;
	@Input() siteId: string;
	@Input() portalPlanTypeId: string;
	@Output() deleteTeam: EventEmitter<PortalTeam> = new EventEmitter<PortalTeam>();
	@Output() reload: EventEmitter<boolean> = new EventEmitter<boolean>();
	breadCrumbs: Array<BreadCrumb>;
	isEditing: boolean = false;
	backRoute: string;
	backTitle: string;
	titleVerbiage: string;
	strategy: any = null;
	public revisionItemTypeId: string;
	public revisionScope: string = '';
	public menuOptions: Map<string, MenuItem[]> = new Map<string, MenuItem[]>();
	permissionFeatures = permissionFeatures;

	constructor(public route: ActivatedRoute,
		public bcService: BusinessContinuityService,
		private spinnerService: SpinnerService,
		private router: Router,
		public translateService: TranslateService,
		private confirmationService: ConfirmationService,
		public toastrService: ToastrService,
		private permission: PermissionService,
		private dialogService: DialogService) {
		this.isIndustryDefault = route.snapshot.data?.isIndustryDefault === true;
		this.industryId = this.route.snapshot.params.industryId;
		this.departmentId = this.route.snapshot.params.functionalAreaId;
		this.functionalAreaId = this.route.snapshot.params.functionalAreaId;
		this.revisionScope = this.route?.snapshot?.data?.revisionScope;

		if (this.isIndustryDefault) {
			this.titleVerbiage = this.translateService.instant('businessContinuity.teams.titleVerbiage3');
			this.backRoute = `/business-continuity/admin/industry/${this.industryId}`;
			this.backTitle = this.translateService.instant('common.departments');
		}
		else if (!this.portalFunctionalAreaId) {
			this.titleVerbiage = this.translateService.instant('businessContinuity.teams.titleVerbiage');
			this.backRoute = `/business-continuity/planner/summary/${this.siteId}`;
			this.backTitle = this.translateService.instant('businessContinuity.titleOverview');
		}
		else {
			this.titleVerbiage = this.translateService.instant('businessContinuity.teams.titleVerbiage2');
			this.backRoute = '';
			this.backTitle = this.translateService.instant('layout.lblHome');
		}
		this.breadCrumbs = [
			{
				name: this.backTitle,
				state: this.backRoute
			},
			{
				name: this.translateService.instant('businessContinuity.teams.title'),
				state: ''
			}
		];

		if (!this.isIndustryDefault) {
			this.revisionItemTypeId = this.portalFunctionalAreaId ? bcRevisionItemTypes.departmentTeams : bcRevisionItemTypes.organizationTeams;
		}
	}

	get canAssignToDepartment(): boolean {
		return this.isBusinessContinuityManager
		  && this.permission.hasFeature(featureTypes.assignTeamToDepartments, this.portalPlanTypeId)
		  && this.portalFunctionalAreaId == null
		  && !this.isIndustryDefault;
	}

	ngOnInit(): void {
		this.strategy = this.router.routeReuseStrategy.shouldReuseRoute;
		this.router.routeReuseStrategy.shouldReuseRoute = () => {
			return false;
		};
	}

	ngOnChanges() {
		this.buildTeamsOptions();
	}

	ngOnDestroy() {
		if (this.strategy) {
			this.router.routeReuseStrategy.shouldReuseRoute = this.strategy;
		}
	}

	buildTeamsOptions() {
		if (this.teams.length > 0) {
			this.teams.forEach((team) => {
				const teamOptions = this.getMenuButtons(team);
				this.menuOptions.set(team.portalTeamId, teamOptions);
			});
		}
	}

	getMenuButtons(team: PortalTeam): MenuItem[] {
		const buttons = [
			{
				label: !team.isLinked && this.canManageDepartment ? this.translateService.instant('common.edit') : this.translateService.instant('common.view'),
				command: () => {
					this.edit(team.portalTeamId);
				}
			}].filter(button => button);
		if (this.canAssignToDepartment) {
			buttons.push({
				label: this.translateService.instant('common.assign'),
				command: () => {
					this.assign(team);
				}
			});
		}
		if (!team.isLinked && this.canManageDepartment) {
			buttons.push({
				label: this.translateService.instant('common.delete'),
				command: () => {
					this.deleteOrRemoveTeam(team);
				}
			});
		}
		return buttons;
	}

	edit(teamId: string) {
		this.isEditing = true;
		this.teamId = teamId;
	}

	getChipInfo(position: PortalTeamPosition): string {
		return `${position?.userFirstName} ${position?.userLastName}`;
	}

	create() {
		this.isEditing = true;
		this.teamId = null;
	}

	deleteOrRemoveTeam(team) {
		this.deleteTeam.emit(team);
	}

	assign(team: PortalTeam) {
		if (this.canAssignToDepartment) {
			const ref: DynamicDialogRef = this.dialogService.open(AssignTeamModalComponent, {
				header: this.translateService.instant('businessContinuity.teams.associateModal.title'),
				width: '70%',
				data: {
					teamId: team.portalTeamId,
					siteId: this.siteId,
					portalPlanTypeId: this.portalPlanTypeId
				}
			});

			ref.onClose.subscribe((res) => {
				if (res) {
				}
			});
		}
	}

	childResponse(reload: boolean) {
		this.isEditing = false;
		this.teamId = null;
		return reload ? this.reload.emit(true) : '';
	}
}
