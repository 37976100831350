import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { OverlayPanel } from 'primeng/overlaypanel';

@Component({
	selector: 'app-bc-plans-assigned-overlay-panel',
	templateUrl: './bc-plans-assigned-overlay-panel.component.html',
	styleUrls: ['./bc-plans-assigned-overlay-panel.component.scss']
})
export class BcPlansAssignedOverlayPanelComponent {
	@Input() assignedPortalPlans: Array<any> = new Array<any>();
	@ViewChild('op') overlayPanel: OverlayPanel;

	constructor() {
	}

	show(event: any) {
		if (!this.assignedPortalPlans || this.assignedPortalPlans.length === 0) {
			return;
		}

		if (this.overlayPanel.target == null) {
			this.overlayPanel.show(event);
		}
	}

	hide() {
		if (this.overlayPanel.target) {
			this.overlayPanel.hide();
		}
	}
}
